export interface MatterExpensesModel {
  id?: number;
  amount: number;
  matterId: number;
  narration: string;
  title: string;
  dateRecorded: string | number;
  quantity?: number;
}

export const ExpenseSample: MatterExpensesModel[] = [
  {
    id: 3,
    amount: 200000,
    matterId: 2,
    narration:
      "Firm's statutory registration fee. Paid before the start fo a matter.",
    title: "Matter Registrations",
    dateRecorded: 1569180859345
  }
];
