import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { PermissionControlModel, PermissionEditModel } from "../../../core/models/edit-model/permission-edit-model";
import { GenericListViewModel } from "../../../core/models/generic-model.ts/generic-list";
import { PermissionActionParseModel } from "../../../core/models/view-model/permission-action";
import { GenericService } from "../../../core/services/generic.service";

@Component({
  selector: "app-permission-control",
  templateUrl: "./permission-control.component.html",
  styleUrls: ["./permission-control.component.scss"]
})
export class PermissionControlComponent implements OnInit, OnChanges {
  @Input() permissions$: Observable<PermissionEditModel[]> = of([]);

  @Input() existingPermissions: PermissionControlModel[] = [];
  @Output() savedPermission = new EventEmitter();

  @Input() btnSpinner$: Observable<boolean> = of(false);

  permissionForm: FormGroup;

  @Input() isPermissionControlLocked: boolean;

  platformMatterPermissions: GenericListViewModel[] = [];

  btnSpinner: boolean;

  constructor(
    private fb: FormBuilder,
    private genericService: GenericService
  ) {
    this.permissionForm = this.fb.group({
      permissions: new FormArray([])
    });
  }

  get permissions(): FormArray {
    return <FormArray>this.permissionForm.get("permissions");
  }

  ngOnInit() {
    this.loadMatterPermissions();
  }

  // patch permission control

  loadMatterPermissions() {
    this.genericService.getMatterPermissions().subscribe((res) => {
      console.log(res);
      this.platformMatterPermissions = res;
      this.platformMatterPermissions.forEach((p) => {
        this.permissions.push(this.attachPermissionSelector());
      });
    });
  }

  ngOnChanges(changes) {
    console.log(this.isPermissionControlLocked);

    if (this.isPermissionControlLocked) {
      // enable and make default before patching
      this.permissions.controls.forEach((fg) => {
        fg.enable();
      });
    } else {
      this.permissions.controls.forEach((fg) => {
        fg.disable();
      });
    }

    if (!isNullOrUndefined(this.existingPermissions)) {
      if (this.existingPermissions.length > 0) {
        this.platformMatterPermissions.forEach((p) => {
          // find everyPermission in existing permission
          let cacheOfPermissionAction: number[] = [];
          this.existingPermissions.forEach((ep) => {
            if (ep.permissionEntityId === p.id) {
              cacheOfPermissionAction.push(ep.actionId);
            }
          });

          // patch

          const tempIndex = this.platformMatterPermissions.indexOf(p);

          this.patchPermissionSelector(
            cacheOfPermissionAction,
            tempIndex
          );
        });
      }
    }
  }

  patchPermissionSelector(permissionActions: number[], index: number) {
    this.permissions.controls[index].patchValue({
      fullAccess: false,
      canView: false,
      canCreate: false,
      canEdit: false,
      canDelete: false
    });

    const isFullPermission = permissionActions.find((p) => p === 4);

    if (!isNullOrUndefined(isFullPermission)) {
      this.permissions.controls[index].patchValue({
        fullAccess: true,
        canView: true,
        canCreate: true,
        canEdit: true,
        canDelete: true
      });
    } else {
      // view
      if (permissionActions.includes(1)) {
        this.permissions.controls[index].patchValue({
          canView: true
        });
      }

      // create
      if (permissionActions.includes(2)) {
        this.permissions.controls[index].patchValue({
          canCreate: true
        });
      }

      // edit
      if (permissionActions.includes(3)) {
        this.permissions.controls[index].patchValue({
          canEdit: true
        });
      }
    }
  }

  attachPermissionSelector(): FormGroup {
    return new FormGroup({
      fullAccess: new FormControl(
        {value: false, disabled: false}
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      ),
      canView: new FormControl(
        {value: false, disabled: false}
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      ),
      canCreate: new FormControl(
        {value: false, disabled: false}
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      ),
      canEdit: new FormControl(
        {value: false, disabled: false}
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      ),
      canDelete: new FormControl(
        {value: false, disabled: false}
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      )
    });
  }

  /**
   * can delete and full access
   * equals canCreate + canView + canEdit + canDelete
   */
  fullAccess(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: isChecked,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: isChecked,
      canDelete: isChecked
    });
  }

  /**
   * can create
   * equals canCreate + canView
   */
  canCreate(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: false,
      canDelete: false
    });
  }

  /**
   *can Edit
   *equals canCreate + canView + canEdit
   */
  canEdit(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: isChecked,
      canEdit: isChecked,
      canDelete: false
    });
  }

  canView(permission: FormGroup, isChecked: boolean) {
    permission.patchValue({
      fullAccess: false,
      canView: isChecked,
      canCreate: false,
      canEdit: false,
      canDelete: false
    });
  }

  checkAccess(permission: FormGroup) {
    const access = permission.value;
    let arr = [];
    let allAccess = [];
    // convert object to array
    for (const [key, value] of Object.entries(access)) {
      if (value === true) {
        arr.push(value);
      }
      allAccess.push(value);
    }
    // for (let k in access) {
    //   console.log(access[k])
    //   arr.push({ [k]: access. });
    // }

    if (arr.length < 1) {
      return "No Access";
    } else if (arr.length < allAccess.length) {
      return "Limited Access";
    } else if (arr.length === allAccess.length) {
      return "Full Access";
    }
  }

  savePermission() {
    // this.btnSpinner$ = of(true);

    let permissionArray: PermissionEditModel[] = [];

    const permissions: any[] = this.permissions.value;
    for (let j = 0; j < permissions.length; j++) {
      const action: PermissionActionParseModel = permissions[j];

      const permission = new PermissionEditModel();
      permission.permissionEntityId = this.platformMatterPermissions[
        j
        ].id;
      permission.permissionsActionId = [];
      if (action.canView) {
        permission.permissionsActionId.push(1);
      }
      if (action.canCreate) {
        permission.permissionsActionId.push(2);
      }
      if (action.canEdit) {
        permission.permissionsActionId.push(3);
      }
      if (action.canDelete) {
        permission.permissionsActionId.push(4);
      }

      permissionArray.push(permission);
    }

    this.permissions$ = of(permissionArray);

    console.log(this.permissions$);

    this.savedPermission.emit(permissionArray);
  }
}
