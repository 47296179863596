import { FileUploadModel } from "../generic-model.ts/file-upload";
import { MatterTeamMember } from "./team-list";

export interface MatterTaskModel {
  id?: number;
  name: string;
  description?: string;
  startDate: any;
  dueDate: any;
  tagId: number;
  reminders: Reminder[];
  isReoccurringTask: boolean;
  reoccurringInterval: number;
  taskUsers?: MatterTeamMember[];
  files?: FileUploadModel[];
  matterId?: number;
  cycleCount?: number;
  cycleDone?: number;
  isCompleted?: boolean;
  resourceId?: number;
  comments?: CommentsModel[];
}

export interface CommentsModel {
  commentText: string;
  commentTime: number;
  id?: number;
  taskId?: number;
  userId?: number;
  commentUser?: CommentUser;
}

export interface CommentUser {
  lastName: string;
  firstName: string;
  id: number;
}

export interface Reminder {
  taskId?: number;
  reminderTime: any;
  message?: string;
}

export const TasksSample: MatterTaskModel[] = [
  {
    id: 3,
    comments: [
      {
        commentUser: {
          firstName: "Isaac",
          lastName: "Bakare",
          id: 2
        },
        commentText: "This is the comment",
        commentTime: 1202932324343,
        id: 2
      }
    ],
    description: "Reprehenderit moles",
    isCompleted: true,
    dueDate: 1559180819345,
    isReoccurringTask: true,
    name: "the other Esse ut culpa lorem ",
    reminders: [
      {
        reminderTime: 192332883111,
        message: "Days before due#2"
      }
    ],
    reoccurringInterval: 3,
    startDate: 192332883111,
    tagId: 2,
    taskUsers: [
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 2,
        otherName: "b",
        firmRole: "Project Supervisor",
        firstName: "Funsho",
        lastName: "Ola"
      }
    ],
    files: [
      {
        fileName: "hi.gif",
        fileBinary:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
      }
    ]
  },
  {
    id: 2,
    comments: [
      {
        commentUser: {
          firstName: "Isaac",
          lastName: "Bakare",
          id: 2
        },
        commentText: "This is the comment",
        commentTime: 1202932324343,
        id: 2
      }
    ],
    description: "Reprehenderit moles",
    dueDate: 1529080809345,
    isCompleted: false,
    isReoccurringTask: true,
    name: "Esse ut culpa lorem ",
    reminders: [
      {
        reminderTime: 192332883111,
        message: "Days before due#1"
      }
    ],
    reoccurringInterval: 3,
    startDate: 192332883111,
    tagId: 2,
    taskUsers: [
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 2,
        otherName: "beforeAll",
        firmRole: "Project Supervisor",
        firstName: "Funsho",
        lastName: "Ola"
      },
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 3,
        otherName: "b",
        firmRole: "Owner",
        firstName: "Balogun",
        lastName: "Ade"
      }
    ],
    files: [
      {
        fileName: "hi.gif",
        fileBinary:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
      }
    ]
  },
  {
    id: 1,
    comments: [
      {
        commentUser: {
          firstName: "Isaac",
          lastName: "Bakare",
          id: 2
        },
        commentText: "This is the comment",
        commentTime: 1202932324343,
        id: 2
      }
    ],
    description: "Another Reprehenderit moles",
    dueDate: 1569180859345,
    isReoccurringTask: true,
    isCompleted: false,
    name: "Another Esse ut culpa lorem ",
    reminders: [
      {
        reminderTime: 192332883111,
        message: "Days before due#1"
      }
    ],
    reoccurringInterval: 2,
    startDate: 192332883111,
    tagId: 1,
    taskUsers: [
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 2,
        otherName: "j",
        firmRole: "Project Supervisor",
        firstName: "Funsho",
        lastName: "Ola"
      },
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 3,
        otherName: "j",
        firmRole: "Owner",
        firstName: "Balogun",
        lastName: "Ade"
      },
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 3,
        otherName: "j",
        firmRole: "Team LEad",
        firstName: "Olabalogun",
        lastName: "Adeniyi"
      },
      {
        id: 2,
        firmUserId: 2,
        firmRoleId: 2,
        teamId: 1,
        userId: 3,
        otherName: "j",
        firmRole: "Ass. Team LEad",
        firstName: "Olutayo",
        lastName: "Adeniyi"
      }
    ],
    files: [
      {
        fileName: "hi.gif",
        fileBinary:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
      }
    ]
  }
];
