import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import * as TaskActions from "../../../core/store/actions/task.action";
import * as fromTask from "../../../core/store/reducers/task.reducer";

@Component({
  selector: "app-upcoming-task",
  templateUrl: "./upcoming-task.component.html",
  styleUrls: ["./upcoming-task.component.scss"]
})
export class UpcomingTaskComponent implements OnInit, OnChanges {
  @Input() tasks: UserTask[];
  _tasks: UserTask[] = [];
  currentTask: any;
  showCurrentTask: boolean;

  constructor(private taskStore: Store<fromTask.State>) {
  }

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.tasks)) {
      this._tasks = this.tasks;
    }
  }

  ngOnInit() {
  }

  closeViewTask(e) {
    if (e === "true") {
      this.taskStore.dispatch(new TaskActions.ViewTask(false));
    }
  }

  // setCurrentTask(task){
  //   this.currentTask = task;
  // }
}
