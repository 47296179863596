import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";
import * as fromStore from "../../../core/store";
import * as LoginActions from "../../../core/store/actions/login.actions";

@Injectable()
// , CanActivateChild
// ,CanLoad
// tslint:disable-next-line:one-line
export class AuthAdminGuard implements CanActivate {
  user = new UserDetail();
  userEmail: string;
  userRole: string;
  firstName: Observable<string>;
  lastName: Observable<string>;

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private currentUserService: CurrentUserService
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      console.log(data);
      this.user = data;
    });
    this.store.pipe(select(fromStore.getUserRole)).subscribe((data) => {
      this.userRole = data;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    console.log(url);
    return this.checkIsAdmin(url);
  }

  // checkLogin(url: string): boolean {
  //   if (this.authService.isLoggedIn) { return true; }

  //   // Store the attempted URL for redirecting
  //   this.authService.redirectUrl = url;

  //   // Navigate to the login page with extras
  //   this.router.navigate(['/login']);
  //   return false;
  // }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   return this.canActivate(route, state);
  // }

  // canActivateChild(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): boolean {
  //   return this.canActivate(route, state);
  // }

  // canLoad(route: Route): boolean {
  //   let url = `/${route.path}`;

  //   return this.checkLogin(url);
  // }

  checkIsAdmin(url: string): boolean {
    console.log(this.user);
    console.log(this.userRole);
    // tslint:disable-next-line:triple-equals
    if (this.user.isAdmin) {
      console.log("logged in as admin");
      return true;
    }
    // Navigate to the login page with extras
    this.router.navigate(["/user-dashboard"]);
    return false;
  }
}
