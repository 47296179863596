import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { GenericListViewModel } from "../../../core/models/generic-model.ts/generic-list";
import { MatterCategorySubTypeParseModel } from "../../../core/models/parse-model/matter-category-sub-type";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterConfigService } from "../../../core/services/matter-config.service";
import { MatterService } from "../../../core/services/matter.service";
import * as fromStore from "../../../core/store";

@Component({
  selector: "app-new-sub-type",
  templateUrl: "./new-sub-type.component.html",
  styleUrls: ["./new-sub-type.component.scss"]
})
export class NewSubTypeComponent implements OnInit {
  errorMessage: string;
  public successText: string;
  public errorText: string;
  public showSuccessMessage: boolean;
  public showErrorMessage: boolean;
  public btnSpinner: boolean;
  newCategorySubTypeForm: FormGroup;
  userData = new UserDetail();
  states: any[] = [];
  currentCategoryId: number;
  currentCategoryTypeId: number;

  briefCategories: GenericListViewModel[] = [];
  briefCategoryTypes: GenericListViewModel[] = [];
  briefCategorySubTypes: GenericListViewModel[] = [];
  @Input() categoryTypeId: number;
  @Output() successErrorDisplay = new EventEmitter();

  // @Input() successText: string;
  // @Input() errorText: string;
  // @Input() showSuccessMessage: boolean;
  // @Input() showErrorMessage: boolean;

  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private matterConfigService: MatterConfigService,
    private matterService: MatterService
  ) {
  }

  get title() {
    return this.newCategorySubTypeForm.get("title");
  }

  get categoryID() {
    return this.newCategorySubTypeForm.get("categoryID");
  }

  get SubcategoryID() {
    return this.newCategorySubTypeForm.get("SubcategoryID");
  }

  ngOnInit() {
    this.showErrorMessage = false;
    this.btnSpinner = false;
    this.addCategorySubTypeForm();

    this.getCategories();
  }

  getCategories() {
    this.matterConfigService.getBriefCategories().subscribe((data) => {
      this.briefCategories = data;
      console.log(this.briefCategories);
    });
  }

  getCategoryTypes(event) {
    console.log("here");
    console.log(event);

    if (!isNullOrUndefined(event.target.value) && +event.target.value > 0) {
      this.matterConfigService
        .getBriefCategoryTypes(+event.target.value)
        .subscribe((data) => {
          console.log(data);
          this.briefCategoryTypes = data;
          console.log(this.briefCategoryTypes);
          // if ((!isNullOrUndefined(this.newMatter.contactId)) && (this.newMatter.contactId > 0)) {

          //  console.log(this.newMatter.contactId);
          //  this.newMatterForm.patchValue({
          //    contactId: this.newMatter.contactId
          //  });
          // }
        });
    }
  }

  getCategorySubTypes(event) {
    console.log(event);
    if (!isNullOrUndefined(event.target.value) && +event.target.value > 0) {
      this.matterConfigService
        .getBriefCategorySubTypes(+event.target.value)
        .subscribe((data) => {
          console.log(data);
          this.briefCategorySubTypes = data;
          // if ((!isNullOrUndefined(this.newMatter.contactId)) && (this.newMatter.contactId > 0)) {

          //  console.log(this.newMatter.contactId);
          // }
        });
    }
  }

  addCategorySubTypeForm() {
    this.newCategorySubTypeForm = this.fb.group({
      title: ["", Validators.required],
      categoryID: ["", Validators.required],
      SubcategoryID: ["", Validators.required]
    });
  }

  savenewSubType() {
    if (!this.newCategorySubTypeForm.invalid) {
      this.btnSpinner = true;
      const formValue = this.newCategorySubTypeForm.value;
      console.log("form will send", formValue);
      const categorySubType = new MatterCategorySubTypeParseModel();
      categorySubType.name = formValue.title;
      categorySubType.firmId = this.userData.firmId;
      categorySubType.description = "";
      categorySubType.matterCategoryTypeId = this.currentCategoryTypeId;

      const addCat = {
        // categoryId: this.categoryID.value,
        matterCategoryTypeId: Number(this.SubcategoryID.value),
        name: this.title.value,
        description: ""
      };
      const showSuccessMessage = true;
      const successText = "\"" + this.title.value + " \" Has been added";
      const showErrorMessage = true;
      const errorText =
        "an error occurred while trying to create practise";

      this.matterService.addPractise(addCat).subscribe(
        (data) => {
          this.successErrorDisplay.emit({
            showSuccessMessage,
            successText
          });
          this.newCategorySubTypeForm.reset();
          this.btnSpinner = false;
          this.closeModal.nativeElement.click();
        },
        (error) => {
          this.successErrorDisplay.emit({
            showErrorMessage,
            errorText
          });
          this.btnSpinner = false;
          console.log(error);
          this.closeModal.nativeElement.click();
        }
      );

      // this.matterConfigService
      //   .postBriefCategorySubType(categorySubType)
      //   .toPromise()
      //   .then(res => {
      //     this.closeModal.nativeElement.click();
      //     // send notification to the global notification. use ngrx and pass text.
      //   })
      //   .catch(err => {
      //     this.errorText = err.message;
      //     this.showErrorMessage = true;
      //     this.messageBox.nativeElement.focus();
      //     this.btnSpinner = false;
      //   });
    }
  }
}
