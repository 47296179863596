import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import { CurrentUserService } from "../../services/factories/current-user.service";
import { FirmService } from "../../services/firm.service";
import { UserManagementService } from "../../services/user-management.service";
import * as authAction from "../actions/login.actions";

@Injectable()
export class LoginEffects {
  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.Login>(authAction.LoginActionTypes.LOGIN),
    mergeMap((action) =>
      this.authService.signIn(action.payload).pipe(
        // If successful, dispatch success action with result
        map(
          (data) =>
            new authAction.LoginSuccess({
              authToken: data.body.authToken,
              userProfile: data.body.userProfile,
              accountPermissions: data.body.accountPermissions
            })
        ),
        // If request fails, dispatch failed action
        catchError((err) => of(new authAction.LoginFail(err)))
      )
    )
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.Logout>(authAction.LoginActionTypes.LOGOUT),
    mergeMap((action) =>
      this.authService.signOut(action.payload).pipe(
        // If successful, dispatch success action with result
        map((data) => new authAction.LogoutSuccess()),
        // If request fails, dispatch failed action
        catchError((err) => of(new authAction.LogoutFail(err)))
      )
    )
  );

  @Effect({dispatch: false})
  logoutSuccess$ = this.actions$.pipe(
    ofType(authAction.LoginActionTypes.LOGOUT_SUCCESS),
    tap(() => {
      console.log("here");
      this.currentUserService.logout();
      this.router.navigate(["/login"]);
    })
  );

  @Effect({dispatch: false})
  logoutFiail$ = this.actions$.pipe(
    ofType(authAction.LoginActionTypes.LOGOUT_FAIL),
    tap(() => {
      this.currentUserService.logout();
      this.router.navigate(["/login"]);
    })
  );

  /* Pass { dispatch: false } to the decorator to prevent dispatching.
  Sometimes you don't want effects to dispatch an action, for example when you only want to log or navigate.
  But when an effect does not dispatch another action, the browser will crash because the effect is both 'subscribing' to and 'dispatching'
  the exact same action, causing an infinite loop. To prevent this, add { dispatch: false } to the decorator. */
  @Effect({dispatch: false})
  loginSuccess$ = this.actions$.pipe(
    ofType(authAction.LoginActionTypes.LOGIN_SUCCESS),
    tap(() => {
      this.router.navigate(["/firm"]);
    })
  );

  @Effect({dispatch: false})
  loginDone$ = this.actions$.pipe(
    ofType<authAction.LoginDone>(authAction.LoginActionTypes.LOGIN_DONE),
    tap((action) => console.log(action))
  );

  // online user detail
  // //////////////////////////////////////
  // /////////////////////////////////////////
  // //////////////////////////////////////////

  @Effect()
  loadOnlineUserDetail$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.LoadOnlineUserDetail>(
      authAction.LoginActionTypes.LOAD_ONLINE_USER_DETAIL
    ),
    mergeMap((action) =>
      this.userService
        .getUserProfile(action.payload.firmId, action.payload.userId)
        .pipe(
          // If successful, dispatch success action with result
          map(
            (data) =>
              new authAction.LoadOnlineUserDetailSuccess(data)
          ),
          // If request fails, dispatch failed action
          catchError(() =>
            of({
              type:
              authAction.LoginActionTypes
                .LOAD_ONLINE_USER_DETAIL_FAIL
            })
          )
        )
    )
  );

  @Effect()
  loadProfilePicture$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.LoadProfilePicture>(
      authAction.LoginActionTypes.LOAD_PROFILE_PICTURE
    ),
    mergeMap((action) =>
      this.userService.getProfilePicture(action.payload).pipe(
        // If successful, dispatch success action with result
        map((data) => new authAction.LoadProfilePictureSuccess(data)),
        // If request fails, dispatch failed action
        catchError(() =>
          of({
            type:
            authAction.LoginActionTypes
              .LOAD_PROFILE_PICTURE_FAIL
          })
        )
      )
    )
  );

  @Effect()
  updateProfilePicture$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.UpdateProfilePicture>(
      authAction.LoginActionTypes.UPDATE_PROFILE_PICTURE
    ),
    mergeMap((action) =>
      this.userService.updateProfilePicture(action.payload).pipe(
        // If successful, dispatch success action with result
        map((data) => new authAction.UpdateProfilePictureSuccess(data)),
        // If request fails, dispatch failed action
        catchError(() =>
          of({
            type:
            authAction.LoginActionTypes
              .UPDATE_PROFILE_PICTURE_FAIL
          })
        )
      )
    )
  );

  @Effect({dispatch: false})
  loadingFailed$ = this.actions$.pipe(
    ofType(authAction.LoginActionTypes.LOAD_ONLINE_USER_DETAIL_FAIL),
    tap(() => {
      // this.router.navigate(['/logout']);
    })
  );

  // local storage
  // //////////////////////////
  // ///////////////////////////

  @Effect()
  loadLocalUserDetail$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.LoadLocalUserDetail>(
      authAction.LoginActionTypes.LOAD_LOCAL_USER_DETAIL
    ),
    mergeMap((action) =>
      this.currentUserService.getuserProfile().pipe(
        // If successful, dispatch success action with result
        map((data) => new authAction.LoadLocalUserDetailSuccess(data)),
        // If request fails, dispatch failed action
        catchError(() =>
          of({
            type:
            authAction.LoginActionTypes
              .LOAD_LOCAL_USER_DETAIL_FAIL
          })
        )
      )
    )
  );

  @Effect({dispatch: false})
  loadLocalUserDetailFailed$ = this.actions$.pipe(
    ofType(authAction.LoginActionTypes.LOAD_LOCAL_USER_DETAIL_FAIL),
    tap(() => {
      this.router.navigate(["/logout"]);
    })
  );

  @Effect()
  loadFirmDetail$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.Load>(authAction.LoginActionTypes.LOAD),
    mergeMap((action) =>
      this.userService.getFirmDetails(action.payload).pipe(
        map((data) => new authAction.LoadSuccess(data)),
        catchError(() =>
          of({type: authAction.LoginActionTypes.LOAD_FAIL})
        )
      )
    )
  );

  @Effect()
  updateFirm$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.Update>(authAction.LoginActionTypes.UPDATE_FIRM),
    mergeMap((action) =>
      this.userService.editFirmDetails(action.payload).pipe(
        map((data) => new authAction.UpdateSuccess(data)),
        catchError(() =>
          of({type: authAction.LoginActionTypes.UPDATE_FIRM_FAIL})
        )
      )
    )
  );

  @Effect()
  loadFirmLogo$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.LoadFirmLogo>(
      authAction.LoginActionTypes.LOAD_FIRM_LOGO
    ),
    mergeMap((action) =>
      this.firmService.loadFirmLogo().pipe(
        map((data) => new authAction.LoadFirmLogoSuccess(data)),
        catchError(() =>
          of({
            type: authAction.LoginActionTypes.LOAD_FIRM_LOGO_FAIL
          })
        )
      )
    )
  );

  @Effect()
  updateFirmLogo$: Observable<Action> = this.actions$.pipe(
    ofType<authAction.UpdateFirmLogo>(
      authAction.LoginActionTypes.UPDATE_FIRM_LOGO
    ),
    mergeMap((action) =>
      this.firmService
        .updateLogo(action.payload.firmId, action.payload.fileModel)
        .pipe(
          map((data) => new authAction.UpdateFirmLogoSuccess(data)),
          catchError(() =>
            of({
              type: authAction.LoginActionTypes.UPDATE_FIRM_FAIL
            })
          )
        )
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private userService: UserManagementService,
    private firmService: FirmService,
    private router: Router
  ) {
  }
}
