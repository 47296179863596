import { IPayment } from "../interfaces/payment";

export class PaymentListViewModel implements IPayment {
  id: number;
  datePaid: string;
  amount: number;
  dateCreated: string;
  note: string;
  message: any;
}
