import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { isNullOrUndefined } from "util";
import { FileUploadModel } from "../../../core/models/generic-model.ts/file-upload";
import { FileUploadStructure } from "../../../core/models/shared-model/file-upload";
import { FileUploadService } from "../../../core/services/file-upload.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent implements OnInit {
  uploadResponse = {status: "", message: "", filePath: ""};
  error: string;
  btnSpinner: boolean;
  uploadForm: FormGroup;
  files: FileUploadStructure[] = [];
  fileNames: string[] = [];
  @ViewChild("fileUploadClose") fileUploadClose: ElementRef;
  @Output() uploadResponseEmitter = new EventEmitter<any>();
  // entityId
  // Application = 1,
  // Firm = 2,
  // Judgement = 3,
  // Matter = 4,
  // Proceeding = 5,
  // Task = 6
  @Input() entityId: number;
  @Input() resourceId: number;
  @Input() resourceDirectoryId: number;
  // @Input() resourceDirectoryTypeId: number;

  @Output() uploaderObject = new EventEmitter<any>();

  errorText: string;
  showError: boolean;
  // fileProgressArray: number[];
  currentDocument: FileUploadModel[];
  fileProgressArray: number[];
  totalFileSize: number;
  resourceDirectory: number;
  resourceDirectoryType: number;

  constructor(
    private _fileUploadService: FileUploadService,
    private fb: FormBuilder
  ) {
  }

  get attachments(): FormArray {
    return <FormArray>this.uploadForm.get("attachments");
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.totalFileSize = 0;
    this.fileProgressArray = [];
    this.currentDocument = [];
    this.errorText = "";

    this.uploadForm = this.fb.group({
      attachments: new FormArray([])
    });
  }

  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.currentDocument.splice(index, 1);
    this.attachments.controls.splice(index, 1);
  }

  attachAnUploadSelector(): FormGroup {
    return new FormGroup({
      fileCaption: new FormControl("")
    });
  }

  // #region File attachment
  /**
   * when file is changed
   * @param event;
   */
  onFileChange(event) {
    this.set_upReader(event.target.files, 0);
    console.log(this.currentDocument);
  }

  set_upReader(files, i) {
    // check if it has file
    if (files && files[i]) {
      const file = files[i];
      const fileName = file.name;
      const fileSize = file.size;
      const fileType: string = file.type;
      console.log(file);
      console.log(file.size);
      if (file.size + this.totalFileSize > 25597152) {
        console.log("file size error");
        this.errorText =
          "Files attached and" +
          file.name +
          "'s size are greater than 25MB is not allowed" +
          "Error! ";
      } else if (this.currentDocument.length > 4) {
        this.errorText =
          "File attached " +
          file.name +
          " and others sum size greater than 25MB and is not allowed" +
          "Error! ";
      } else if (
        fileType.toString().toLowerCase().includes("image") ||
        fileType.toString().toLowerCase().includes("document") ||
        fileType.toString().toLowerCase().includes("pdf") ||
        fileType.toString().toLowerCase().includes("excel") ||
        fileType.toString().toLowerCase().includes("sheet") ||
        fileType.toString().toLowerCase().includes("msword")
      ) {
        console.log(file);
        const fileType = file.type;
        const previewReader = new FileReader();

        this.attachments.push(this.attachAnUploadSelector());
        //
        previewReader.onprogress = (e) => {
          this.fileProgressArray[i] = (e.loaded / e.total) * 100;
        };

        previewReader.readAsDataURL(file);
        previewReader.onload = (e: any) => {
          this.readerLoaded(
            e,
            files,
            i,
            fileName,
            fileSize,
            fileType
          );
        };
      } else {
        console.log(fileType, "not allowed");
        this.errorText =
          "File attached " +
          file.name +
          "'s type is not allowed" +
          "Error! ";

        // /
      }
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {
    // called once readAsDataURL is completed
    console.log(e);
    // this is correct , ignore errors
    const FILE = new FileReader();

    const upload = new FileUploadModel();

    upload.fileBinary = (<string>e.target.result).split(",")[1];

    if (fileType.toString().toLowerCase().includes("msword")) {
      upload.fileExtension = "doc";
    }

    upload.fileExtension = fileType;
    upload.fileType = fileType;
    upload.fileName = fileName;
    upload.fileSize = fileSize;

    this.totalFileSize += upload.fileSize;

    this.currentDocument.push(upload);
    console.log(this.currentDocument);
    // call service

    // If there's a file left to load
    if (i < files.length - 1) {
      // Load the next file
      this.set_upReader(files, i + 1);
    }
  }

  // #endregion

  onSubmit() {
    let files: any[] = [];
    this.btnSpinner = true;
    console.log(this.currentDocument);
    this.currentDocument.forEach((item) => {
      let formData: any = {};
      console.log(item);
      formData.fileModel = item;
      console.log(formData.fileModel);
      if (!isNullOrUndefined(this.resourceDirectoryId)) {
        formData.resourceDirectoryId = this.resourceDirectoryId;
      } else {
        formData.resourceDirectoryId = 0;
      }

      if (!isNullOrUndefined(this.resourceId)) {
        formData.resourceId = this.resourceId;
      }

      if (!isNullOrUndefined(this.entityId)) {
        formData.fileType = this.entityId;
      }

      files.push(formData);
    });

    console.log(files);

    this._fileUploadService.uploadNonAsync(files).subscribe(
      (data) => {
        this.uploadResponse = data;
        console.log(data);
        this.uploadResponseEmitter.emit(data);
        this.fileProgressArray = [];
        this.currentDocument = [];
        this.uploadForm.reset();
        this.attachments.controls = [];
      },
      (err) => {
        this.errorText = err.message;
        console.log("the error is", err);
        this.btnSpinner = false;
      },
      () => {
        this.fileUploadClose.nativeElement.click();
        this.btnSpinner = false;
      }
    );
  }
}
