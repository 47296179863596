import { Action } from "@ngrx/store";
import { EditRoleModel } from "../../models/edit-model/edit-role";
import { FirmRole } from "../../models/view-model/role-list";

export enum FirmRoleActionTypes {
  setCurrentRole = "[roles] Set Current Role",
  SET_CURRENT_ROLE_SUCCESS = "[roles] SET_CURRENT_ROLE_SUCCESS",
  SET_CURRENT_ROLE_FAIL = "[roles] SET_CURRENT_ROLE_FAIL",
  LoadRoles = "[roles] Load Roles",
  LoadRolesSuccess = "[roles] Load Roles Success",
  LoadRolesFail = "[roles] Load Roles Fail",
  UpdateRoles = "[role] Update roles",
  UpdateRolesSuccess = "[role] Update roles Success",
  UpdateRolesFail = "[role] Update roles Fail",
}

export class SetCurrentRole implements Action {
  readonly type = FirmRoleActionTypes.setCurrentRole;

  constructor(public payload: { firmId: number; roleId: number }) {
  }
}

export class SetCurrentRoleSuccess implements Action {
  readonly type = FirmRoleActionTypes.SET_CURRENT_ROLE_SUCCESS;

  constructor(public payload: FirmRole) {
  }
}

export class SetCurrentRoleFail implements Action {
  readonly type = FirmRoleActionTypes.SET_CURRENT_ROLE_FAIL;

  constructor(public payload: string) {
  }
}

export class LoadRoles implements Action {
  readonly type = FirmRoleActionTypes.LoadRoles;

  constructor(public payload: number) {
  }
}

export class LoadRolesSuccess implements Action {
  readonly type = FirmRoleActionTypes.LoadRolesSuccess;

  constructor(public payload: FirmRole[]) {
  }
}

export class LoadRolesFail implements Action {
  readonly type = FirmRoleActionTypes.LoadRolesFail;

  constructor(public payload: string) {
  }
}

export class UpdateRole implements Action {
  readonly type = FirmRoleActionTypes.UpdateRoles;

  constructor(public payload: EditRoleModel) {
  }
}

export class UpdateRolesSuccess implements Action {
  readonly type = FirmRoleActionTypes.UpdateRolesSuccess;

  constructor(public payload: EditRoleModel) {
  }
}

export class UpdateRolesFail implements Action {
  readonly type = FirmRoleActionTypes.UpdateRolesFail;

  constructor(public payload: string) {
  }
}

export type FirmRoleActions =
  | SetCurrentRole
  | LoadRoles
  | LoadRolesSuccess
  | LoadRolesFail
  | UpdateRole
  | UpdateRolesSuccess
  | UpdateRolesFail
  | SetCurrentRoleSuccess
  | SetCurrentRoleFail;
