import { FileViewModel } from "./file";

export class FirmDetailViewModel {
  id: number;
  name: string;
  contactEmail?: string;
  website?: string;
  phoneNumber?: string;
  address?: string;
  countryId?: number;
  stateId?: number;
  imageType?: string;
  state?: string;
  country?: string;
  creator?: number;
  logo: FileViewModel;
}
