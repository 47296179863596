import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from "@angular/router";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

@Injectable({providedIn: "root"})
export class LicenseGuard implements CanActivate, CanLoad {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;

    return this.checkLicense(url);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLicense(url);
  }

  checkLicense(url: string): boolean {
    console.log(this.currentUserService.isLoggedIn());
    // if (this.currentUserService.isLicensed()) {

    //         return true;

    // }

    // this.currentUserService.addRedirectUrl(url);

    // this.router.navigate(["/pricing"]);
    return true;
  }
}
