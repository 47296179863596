import { CalendarEvent, CalendarEventAction } from "angular-calendar";
import { EventAction, EventColor } from "calendar-utils";

export class CalendarViewModel implements CalendarEvent {
  // merged the interface and the properties of what is coming from the api
  resourceId?: number;
  // get bar():boolean {
  //     return this._bar;
  // }
  // set bar(theBar:boolean) {
  //     this._bar = theBar;
  // }
  // set start(start: Date) {
  //     this._start = new Date(this.date);
  // }
  isTask?: boolean;
  isEndorsement?: boolean;
  isApplication?: boolean;
  isRemider?: boolean;
  matterId?: number;
  // title: string;
  matterName?: string;
  description?: string;

  id?: string | number;
  start: Date;
  date?: string;
  //  get start(): Date {
  //     // this._start = new Date(this.date);
  //     return this._start;
  // }
  end?: Date;
  desc?: Date;
  title: string;
  // get title(): string {
  //     if (this.isRemider || this.isTask) {
  //        return this.title;
  //     } else {
  //        return  this.matterName;
  //     }
  // }
  color?: EventColor;

  // get color(): EventColor {
  //     if (this.isRemider) {
  //         return eventType.reminder;
  //     } else if (this.isEndorsement) {
  //         return eventType.endorsement;
  //     } else if (this.isApplication) {
  //         return eventType.application;
  //     } else if (this.isTask) {
  //        return eventType.task;
  //     }
  // }

  actions?: EventAction[];
  allDay?: boolean;
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  // meta?: MetaType;
}

export const eventType: any = {
  endorsement: {
    primary: "#D21F3C",
    secondary: "#D21F3C"
  },
  reminder: {
    primary: "#ff9000",
    secondary: "#ff9000"
  },
  application: {
    primary: "#ff9000",
    secondary: "#ff9000"
  },
  task: {
    primary: "#1C59A0",
    secondary: "#1C59A0"
  }
};

export const actions: CalendarEventAction[] = [
  {
    label: "<i class=\"fa fa-fw fa-pencil\"></i>",
    onClick: ({event}: { event: CalendarEvent }): void => {
      this.handleEvent("Edited", event);
    }
  },
  {
    label: "<i class=\"fa fa-fw fa-times\"></i>",
    onClick: ({event}: { event: CalendarEvent }): void => {
      this.events = <CalendarEvent[]>(
        this.events.filter((iEvent) => iEvent !== event)
      );
      this.handleEvent("Deleted", event);
    }
  }
];

export class EventHelper {
  shaper(e: CalendarViewModel) {
    e.start = new Date(Number.parseInt(e.date, 10));

    if (e.isRemider || e.isTask) {
      e.title = e.title;
    } else {
      e.title = e.matterName;
    }

    if (e.isRemider) {
      e.color = eventType.reminder;
    } else if (e.isEndorsement) {
      e.color = eventType.endorsement;
    } else if (e.isApplication) {
      e.color = eventType.application;
    } else if (e.isTask) {
      e.color = eventType.task;
    }

    e.actions = actions;

    return e;
  }
}
