export const config = {
  "Countries": [
    {
      "Id": 1,
      "Name": "Nigeria",
      "Code": "NIG",
      "States": [
        {
          "Id": 1,
          "Name": "Abuja",
          "Code": "ABJ"
        },
        {
          "Id": 2,
          "Name": "Lagos",
          "Code": "LAG"
        }
      ]
    }
  ],
  "States": [
    {
      "Id": 1,
      "Name": "Abuja",
      "Code": "ABJ"
    },
    {
      "Id": 2,
      "Name": "Lagos",
      "Code": "LAG"
    }
  ],
  "firmUserTitle": [
    {
      "id": 1,
      "name": "Mr"
    },
    {
      "id": 2,
      "name": "Chamber/Firm"
    },
    {
      "id": 3,
      "name": "Mrs"
    },
    {
      "id": 4,
      "name": "Miss"
    },
    {
      "id": 5,
      "name": "SAN"
    },
    {
      "id": 6,
      "name": "BARR"
    },
    {
      "id": 7,
      "name": "Prof"
    },
    {
      "id": 8,
      "name": "JUSTICE"
    },
    {
      "id": 9,
      "name": "Mag"
    },
    {
      "id": 10,
      "name": "Senator"
    },
    {
      "id": 11,
      "name": ".."
    },
    {
      "id": 12,
      "name": "Dr"
    },
    {
      "id": 13,
      "name": "Chief"
    },
    {
      "id": 14,
      "name": "Sir"
    },
    {
      "id": 15,
      "name": "Otunba"
    },
    {
      "id": 16,
      "name": "Esq"
    },
    {
      "id": 17,
      "name": "Ven"
    },
    {
      "id": 18,
      "name": "Hon"
    },
    {
      "id": 19,
      "name": "ENTERPRISE"
    },
    {
      "id": 20,
      "name": "High court"
    },
    {
      "id": 21,
      "name": "Institutions"
    },
    {
      "id": 22,
      "name": "Lady"
    },
    {
      "id": 23,
      "name": "MOJ"
    },
    {
      "id": 24,
      "name": "OGSJ"
    },
    {
      "id": 25,
      "name": "NBA BRANCH"
    },
    {
      "id": 26,
      "name": "Brand Ambassador"
    }
  ],
  "matterContactStatus": [
    {
      "id": 6,
      "name": "Amis Curie",
      "isParty": false
    },
    {
      "id": 2,
      "name": "Claimant",
      "isParty": true
    },
    {
      "id": 4,
      "name": "Respondent",
      "isParty": true
    },
    {
      "id": 7,
      "name": "Opposing Party Counsel",
      "isParty": false
    },
    {
      "id": 5,
      "name": "Witness",
      "isParty": false
    }
  ],
  "contentiousJudgement": [
    {
      "name": "Contentious",
      "id": 1
    },
    {
      "name": "Non-Contentious",
      "id": 2
    }
  ],
  "dayArray": [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ],
  "monthArray": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "November",
    "December"
  ],
  "colors": [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF"
  ],
  "titles": [
    {
      "name": "Barrister",
      "id": 1
    },
    {
      "name": "Mr",
      "id": 2
    },
    {
      "name": "Mrs",
      "id": 3
    },
    {
      "name": "Doctor",
      "id": 4
    },
    {
      "name": "Prof",
      "id": 5
    },
    {
      "name": "Honorable",
      "id": 6
    },
    {
      "name": "Pastor",
      "id": 7
    },
    {
      "name": "Justice",
      "id": 8
    }

  ],
  "archivedStatus": [
    {
      "status": "Archived(old cases, completed already)",
      "value": true
    },
    {
      "status": "Ongoing",
      "value": false
    }
  ],
  "relationship": [
    {
      "name": "A client",
      "id": 3,
      "isClient": true
    },
    {
      "name": "A lead",
      "id": 2,
      "isClient": false
    },
    {
      "name": "Others",
      "id": 1,
      "isClient": false
    }
  ],
  "priorities": [
    {
      "name": "Critical",
      "id": 1
    },
    {
      "name": "High Priority",
      "id": 2
    },
    {
      "name": "Normal",
      "id": 3
    }
  ],
  "courts": [
    {
      "name": "Federal High Court",
      "id": 1
    },
    {
      "name": "State High Court",
      "id": 2
    },
    {
      "name": "Court Of Appeal",
      "id": 3
    },
    {
      "name": "Supreme Court",
      "id": 4
    }
  ],
  "applications": [
    {
      "name": "Injuction Application",
      "id": 1
    },
    {
      "name": "Interim Application",
      "id": 2
    }
  ],
  "judgements": [
    {
      "name": "Ruling",
      "id": 1
    },
    {
      "name": "Struck Out",
      "id": 2
    }
  ],
  "applicationStatus": [
    {
      "name": "Pending",
      "id": 1
    },
    {
      "name": "Rejected",
      "id": 2
    }
  ],
  "monthLicenseCost": [
    {
      "name": "Monthly Plan",
      "id": 1
    },
    {
      "name": "Annual Plan",
      "id": 2
    }
  ],
  "periodDiscount": [
    {
      "name": "Monthly",
      "id": 1,
      "discountPercentage": 0,
      "monthCount": 1
    },
    {
      "name": "Annual",
      "id": 2,
      "discountPercentage": 23,
      "monthCount": 12
    }
  ],
  "monthlyValue": 5000,
  "serverEndpoint1": "https://api-cms.lawpavilion.com/api/v1/",
  "serverEndpoint": "https://api-courtmanager.lawpavilion.com/api/v1/",
  "serverEndpoint2": "http://localhost:20532/api/v1/",
  "firmTaskType": [
    {
      "name": "Research",
      "id": 1
    },
    {
      "name": "Case Plan",
      "id": 2
    }
  ],
  "EmailconfigType": [
    {
      "name": "gmail",
      "id": 1
    },
    {
      "name": "microsoft",
      "id": 2
    },
    {
      "name": "others",
      "id": 3
    }
  ],
  "matterStatuses": [
    {
      "name": "Archived(old cases, completed already)",
      "id": 1
    },
    {
      "name": "Archived(old cases, completed already)",
      "id": 1
    },
    {
      "name": "Archived(old cases, completed already)",
      "id": 1
    },
    {
      "name": "Archived(old cases, completed already)",
      "id": 1
    }
  ]
}
