import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";

@Injectable({
  providedIn: "root"
})
export class PricingService {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  public getMonthlyValue(): Observable<number> {
    const url =
      this.config.getConfig().serverEndpoint + "app/setting/monthly_cost";
    // return this.httpClient.get<MonthLicenseCost>(url);
    const monthlyValue: number = this.config.getConfig().monthlyValue;
    return of(monthlyValue);
  }
}
