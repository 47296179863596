import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { TagId } from "../../../core/models/parse-model/new-endorsement";
import { CalendarViewModel } from "../../../core/models/view-model/calendar-event";

import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterService } from "../../../core/services/matter.service";
import * as CalendarActions from "../../../core/store/actions/calendar-lander.actions";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromStore from "../../../core/store/reducers/calendar-lander.reducer";
import * as fromMatters from "../../../core/store/reducers/matters.reducer";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-new-endorsement-modal",
  templateUrl: "./new-endorsement-modal.component.html",
  styleUrls: ["./new-endorsement-modal.component.scss"]
})
export class NewEndorsementModalComponent implements OnInit {
  tagIdArr: TagId[] = this.config.getConfig().priorities;
  newEndorsement: FormGroup;
  currentMatterId: number;
  @Input() disableSelectOnEndorsementSelect: boolean;
  btnSpinner: boolean;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  @Output() getSavedEndorsement = new EventEmitter();
  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;
  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;
  courtAppearnceToggle: boolean = false;
  courtAppearnceReturn: boolean = false;

  constructor(
    private calendarStore: Store<fromStore.CalendarState>,
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: MatterService,
    private store: Store<fromMatters.State>
  ) {
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    console.log(this.activatedRoute);
    console.log(this.activatedRoute.snapshot, "matter id no");
    this.newEndorsement = fb.group({
      hearingDate: ["", Validators.required],
      nextHearingDate: this.fb.array([], Validators.required),
      nextAction: ["", Validators.required],
      nextStage: ["", Validators.required],
      summary: ["", Validators.required],
      matterId: [
        {
          value: ""
          // ,disabled: true
        },
        [Validators.min(1)]
      ],
      note: [""],
      tagId: [""]
    });

    this.store
      .pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        console.log(matter);
        if (!isNullOrUndefined(matter)) {
          this.currentMatterId = matter.id;
          this.matterId.patchValue(this.currentMatterId);
        }
      });
    console.log(this.tagId, "this is tag id");
  }

  get hearingDate() {
    return this.newEndorsement.get("hearingDate");
  }

  get nextHearingDate(): FormArray {
    return <FormArray>this.newEndorsement.get("nextHearingDate");
  }

  get nextAction() {
    return this.newEndorsement.get("nextAction");
  }

  get nextStage() {
    return this.newEndorsement.get("nextStage");
  }

  get summary() {
    return this.newEndorsement.get("summary");
  }

  get matterId() {
    return this.newEndorsement.get("matterId");
  }

  get tagId() {
    return this.newEndorsement.get("tagId");
  }

  get note() {
    return this.newEndorsement.get("note");
  }

  ngDoCheck() {
    if (this.courtAppearnceToggle === true) {
      if (
        this.nextStage.valid &&
        this.tagId.valid &&
        this.nextAction.valid &&
        this.nextHearingDate.valid
      ) {
        this.courtAppearnceReturn = true;
      } else {
        this.courtAppearnceReturn = false;
      }
    }

    if (this.courtAppearnceToggle === false) {
      if (this.hearingDate.valid && this.summary.valid) {
        this.courtAppearnceReturn = true;
      } else {
        this.courtAppearnceReturn = false;
      }
    }
  }

  courtAppearanceChange() {
    this.courtAppearnceToggle = !this.courtAppearnceToggle;
  }

  ngOnInit() {
    this.newEndorsement.valueChanges.subscribe((data) => {
    });

    this.showResourceNotSelectedError = false;

    this.disableSelectOnEndorsementSelect = true;

    this.addNextHearingDate();
  }

  addNextHearingDate() {
    this.nextHearingDate.push(this.createNextHearing());
  }

  createNextHearing() {
    return this.fb.group({
      date: [{value: ""}, Validators.required]
      // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
    });
  }

  removenextHearingDate(index): void {
    this.nextHearingDate.removeAt(index);
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }

  closeError() {
    this.showErrorMessage = false;
  }

  getSelectedOption(event) {
    if (!isNullOrUndefined(this.currentMatterId)) {
      this.matterId.patchValue(this.currentMatterId);
    }

    this.currentMatterId = event;

    console.log(this.currentMatterId);
  }

  addNewEndorsement(newEndorse) {
    console.log(this.currentMatterId);

    if (isNullOrUndefined(this.currentMatterId)) {
      this.showResourceNotSelectedError = true;
      return;
    } else if (this.currentMatterId < 1) {
      this.showResourceNotSelectedError = true;
      return;
    }

    this.btnSpinner = true;

    newEndorse.matterId = Number(this.matterId.value);

    let dateArray = [];
    newEndorse.nextHearingDate.forEach((element) => {
      element = "" + new Date(element.date).getTime();

      dateArray.push(element);
    });

    newEndorse.nextHearingDate = dateArray;

    console.log(dateArray);

    console.log(newEndorse.nextHearingDate);

    newEndorse.hearingDate =
      "" + new Date(newEndorse.hearingDate).getTime();

    if (newEndorse.tagId === "") {
      newEndorse.tagId = 3;
    } else {
      newEndorse.tagId = Number(newEndorse.tagId);
    }

    this.service.addNewEndorsement(newEndorse).subscribe(
      (data) => {
        console.log("successfully added a new endorsement", data);
        this.successText = "endorsement was created successfully";

        // foreach date pass event to calendar

        newEndorse.nextHearingDate.forEach((element) => {
          const SaveEndorsement = new CalendarViewModel();
          SaveEndorsement.title = "Court Appearance";
          SaveEndorsement.isEndorsement = true;
          //  SaveEndorsement.matterId = this.matterIdNo;
          SaveEndorsement.resourceId = data;

          SaveEndorsement.date = element;

          this.calendarStore.dispatch(
            new CalendarActions.AddCalendarEvent(SaveEndorsement)
          );
        });

        // end of attaching dates to calendar

        this.btnSpinner = false;

        this.closeEndorsementModal.nativeElement.click();

        this.getSavedEndorsement.emit(newEndorse);
      },
      (error) => {
        console.log(error);
        this.showErrorMessage = true;
        this.messageBox.nativeElement.focus();
        this.errorText = "creating endorsement failed";
        this.btnSpinner = false;
      }
    );

    console.log(newEndorse);
  }
}
