import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileViewModel } from "../../../core/models/view-model/document-list";

@Component({
  selector: "app-view-file",
  templateUrl: "./view-file.component.html",
  styleUrls: ["./view-file.component.scss"]
})
export class ViewFileComponent implements OnInit {
  @Input() file: any;
  @Output() closeFile = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.file = new FileViewModel();
  }

  closeDialog() {
    this.closeFile.emit("true");
  }
}
