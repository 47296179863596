import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AuthService } from "../../core/services/auth.service";
import { LoginEffects } from "../../core/store/effects/login.effects";
import { loginReducer } from "../../core/store/reducers/login.reducer";
import { SharedModule } from "../shared/shared.module";

import { AuthRoutingModule } from "./auth-routing.module";
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component";
import { FirmRegistrationComponent } from "./firm-registration/firm-registration.component";
import { ForceLogoutComponent } from "./force-logout/force-logout.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { NewInvitedUserComponent } from "./new-invited-user/new-invited-user.component";
import { RegistrationSuccessComponent } from "./registration-success/registration-success.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@NgModule({
  declarations: [
    LoginComponent,
    FirmRegistrationComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    NewInvitedUserComponent,
    EmailConfirmationComponent,
    RegistrationSuccessComponent,
    LogoutComponent,
    ConfirmAccountComponent,
    ForceLogoutComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature("auth", loginReducer),
    EffectsModule.forFeature([LoginEffects])
  ],
  providers: [AuthService]
})
export class AuthModule {
}
