import { AppConfigService } from "../../../views/shared/helpers/app-config.service";

export class Endpoints {
  inviteEndpoint = "firm/invite/user";
  registerEndpoint = "firm";
  // http://lawpavilioncasemanager.uksouth.cloudapp.azure.com/casemanager_api/api/v1/
  loginEndPoint = "account/login";
  // remoteEndPoint  = this.config.getConfig().serverEndpoint;
  //  remoteEndPoint  = 'https://lpcasemanager.azurewebsites.net/api/v1/';
  // rootEndPoint = 'http://localhost:20532/api/v1/';
  confirmEmailEndPoint = "account/confirm-email";
  resendEmailConfirmationEndPoint = "account/confirm-email";
  forgotPasswordEndPoint = "account/forgot-password";
  addfirmUser = "firm/user";
  getUserProfile = "firm/{firmId:int}/user/{userId:int}";
  updateUserProfile = "firm/user";
  getfirmDetails = "firm";
  updateFirmDetails = "firm";
  disableUser = "firm/disable/user";
  enableUser = "firm/enable/user";
  deleteUser = "firm/enable/user";
  newRole = "firm/role";
  updateRole = "firm/permission/role";
  changePasswordEndpoint = "account/change-password";
  addFirmLabelEndpoint = "contact/firm/label";
  contactEndpoint = "contact"; // add contact
  attachFirmLabel = "contact/attach/firm/label";
  updateContactStatus = "contact/status";
  validateEmail = "account/validate-email/";
  matterEndPoint = "matter";
  changeMatterCourtEndPoint = "matter/change-court/court";
  matterContactEndPoint = "matter/contact";
  getContactMatter = "contact/{{contactId}}/matter";
  firmRoles = "";
  noteEndPoint = "matter/note";
  timeSheetEndPoint = "expense/TimeSheets";
  newUserGroup = "firm/firmgroup";
  // tslint:disable-next-line:max-line-length
  NBAMailRegisterLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=edb15f30-56a6-4c50-b20c-a743368a3daa
  &redirect_uri=http://localhost:4200/mailbox/confirm-mailbox&response_type=code&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read`;
  // getTimeSheetEndPoint = `expense/${matterId}/Timesheets`;
  // getInvitedUsers = `firm/invite/${firmId}/user`;
  // getFirmUsers = 'firm/{firmId}/user';
  // Reset = '[Scoreboard Page] Score Reset';
  // tslint:disable-next-line:max-line-length
  GMAILMailLink =
    "https://accounts.google.com/o/oauth2/v2/auth?client_id=464629835007-ufvfc4c8rmsp0sj9qnnrls7sejqcvrnb.apps.googleusercontent.com&access_type=offline&prompt=consent&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fmail.google.com&redirect_uri=https://casemanagerapp.azurewebsites.net/mail/gmail/";
  // tslint:disable-next-line: max-line-length
  // "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=edb15f30-56a6-4c50-b20c-a743368a3daa&redirect_uri=https://casemanagerapp.azurewebsites.net/mail/outlook&response_type=code&scope=Files.ReadWrite.All+Mail.Read+Mail.ReadWrite+Mail.Send+Mail.Send.Shared+MailboxSettings.ReadWrite+openid+offline_access+User.Read";

  /**
   *
   */
  constructor(private config: AppConfigService) {
  }
}
