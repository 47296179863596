import { Action } from "@ngrx/store";
import { UserTask } from "../../models/view-model/user-tasks";

export enum TasksActionTypes {
  SetCurrentTask = "[Tasks] Set Current Task",
  LoadTasks = "[Tasks] Load Tasks",
  LoadTasksSuccess = "[Tasks] Load Tasks Success",
  LoadTasksFail = "[Tasks] Load Tasks Fail",
  AddTask = "[Tasks] Add Task",
  AddTaskSuccess = "[Tasks] Add New Task Success",
  AddTaskFail = "[Tasks] Add New Task Fail",

  // Set Matter Task
  SetCurrentMatterTask = "[MatterTasks] Set Current Task",
  LoadMatterTasks = "[MatterTasks] Load Tasks",
  LoadMatterTasksSuccess = "[MatterTasks] Load Tasks Success",
  LoadMatterTasksFail = "[MatterTasks] Load Tasks Fail",
  CheckMatterTask = "[MatterTasks] Check Task",
  CheckMatterTaskSuccess = "[MatterTasks] Check Task Success",
  CheckMatterTaskFail = "[MatterTasks] Check Task Fail",
  ViewMatterTask = "[MatterTasks] view Task",

  UpdateTask = "[Tasks] Update Task",
  UpdateTaskSuccess = "[Tasks] Update Task Success",
  UpdateTaskFail = "[Tasks] Update Task Fail",

  CheckTask = "[Tasks] Check Task",
  CheckTaskSuccess = "[Tasks] Check Task Success",
  CheckTaskFail = "[Tasks] Check Task Fail",
  ViewTask = "[Tasks] view Task",
}

export class LoadMatterTasks implements Action {
  readonly type = TasksActionTypes.LoadMatterTasks;

  constructor(
    public payload: {
      matterId: number;
      searchQuery;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
    }
  ) {
  }
}

export class LoadMatterTasksSuccess implements Action {
  readonly type = TasksActionTypes.LoadMatterTasksSuccess;

  constructor(public payload: UserTask[]) {
  }
}

export class LoadMatterTasksFail implements Action {
  readonly type = TasksActionTypes.LoadMatterTasksFail;

  constructor(public payload: string) {
  }
}

export class CheckMatterTask implements Action {
  readonly type = TasksActionTypes.CheckMatterTask;

  constructor(public payload: UserTask) {
  }
}

export class CheckMatterTaskSuccess implements Action {
  readonly type = TasksActionTypes.CheckMatterTaskSuccess;

  constructor(public payload: boolean) {
  }
}

export class CheckMatterTaskFail implements Action {
  readonly type = TasksActionTypes.CheckMatterTaskFail;

  constructor(public payload: any) {
  }
}

export class ViewMatterTask implements Action {
  readonly type = TasksActionTypes.ViewMatterTask;

  constructor(public payload: boolean) {
  }
}

export class SetCurrentMatterTask implements Action {
  readonly type = TasksActionTypes.SetCurrentMatterTask;

  constructor(public payload: UserTask) {
  }
}

export class LoadTasks implements Action {
  readonly type = TasksActionTypes.LoadTasks;

  constructor(
    public payload: {
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
    }
  ) {
  }
}

export class LoadTasksSuccess implements Action {
  readonly type = TasksActionTypes.LoadTasksSuccess;

  constructor(public payload: any) {
  }
}

export class LoadTasksFail implements Action {
  readonly type = TasksActionTypes.LoadTasksFail;

  constructor(public payload: string) {
  }
}

export class AddTask implements Action {
  readonly type = TasksActionTypes.AddTask;

  constructor(public payload: any) {
  }
}

export class AddTaskSuccess implements Action {
  readonly type = TasksActionTypes.AddTaskSuccess;

  constructor(public payload: number) {
  }
}

export class AddTaskFail implements Action {
  readonly type = TasksActionTypes.AddTaskFail;

  constructor(public payload: any) {
  }
}

export class UpdateTask implements Action {
  readonly type = TasksActionTypes.UpdateTask;

  constructor(public payload: any) {
  }
}

export class UpdateTaskSuccess implements Action {
  readonly type = TasksActionTypes.UpdateTaskSuccess;

  constructor(public payload: boolean) {
  }
}

export class UpdateTaskFail implements Action {
  readonly type = TasksActionTypes.UpdateTaskFail;

  constructor(public payload: any) {
  }
}

export class CheckTask implements Action {
  readonly type = TasksActionTypes.CheckTask;

  constructor(public payload: UserTask) {
  }
}

export class CheckTaskSuccess implements Action {
  readonly type = TasksActionTypes.CheckTaskSuccess;

  constructor(public payload: boolean) {
  }
}

export class CheckTaskFail implements Action {
  readonly type = TasksActionTypes.CheckTaskFail;

  constructor(public payload: any) {
  }
}

export class ViewTask implements Action {
  readonly type = TasksActionTypes.ViewTask;

  constructor(public payload: boolean) {
  }
}

export class SetCurrentTask implements Action {
  readonly type = TasksActionTypes.SetCurrentTask;

  constructor(public payload: UserTask) {
  }
}

export type TaskActions =
  | LoadTasks
  | LoadTasksSuccess
  | LoadTasksFail
  | AddTask
  | AddTaskSuccess
  | AddTaskFail
  | UpdateTask
  | UpdateTaskSuccess
  | UpdateTaskFail
  | CheckTask
  | CheckTaskSuccess
  | CheckTaskFail
  | ViewTask
  | SetCurrentTask
  | SetCurrentMatterTask
  | LoadMatterTasks
  | LoadMatterTasksSuccess
  | LoadMatterTasksFail
  | CheckMatterTask
  | CheckMatterTaskSuccess
  | CheckMatterTaskFail
  | ViewMatterTask;
