import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { UserProfileViewModelLite } from "../../../core/models/view-model/UserProfileViewModelLite";
import { GenericService } from "../../../core/services/generic.service";
import { NotificationService } from "../../../core/services/notification.service";
import { UserGroupService } from "../../../core/services/user-group.service";
import * as fromStore from "../../../core/store";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as UserHeaderActions from "../../../core/store/actions/user-header.actions";
// since it's lazy loaded
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";

@Component({
  selector: "app-user-header",
  templateUrl: "./user-header.component.html",
  styleUrls: ["./user-header.component.scss"]
})
export class UserHeaderComponent implements OnInit {
  timerActive: boolean;
  userData = new UserDetail();
  unreadNotificationCount: number;

  // searchForm: FormGroup;
  firmId: number;
  userId: number;

  // *****************
  actor: UserProfileViewModelLite;
  action: string;
  entityName: string;
  entityTypeObjecct: any;
  // *****************

  activitiesDrawerShown: boolean;

  activityList: any[] = [];

  @ViewChild("searchText") searchText: ElementRef;

  constructor(
    private store: Store<fromUserHeaderStore.HeaderState>,
    private _notyService: NotificationService,
    // private fb: FormBuilder,
    private userGroup: UserGroupService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private genericService: GenericService
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data;
    });

    this.store
      .pipe(select(fromUserHeaderStore.getTimerState))
      .subscribe((data) => {
        console.log(data);
        this.timerActive = data;
      });

    this._notyService.getActivities().subscribe((res) => {
      console.log(res);
      this.activityList = res.body;
    });

    this.store
      .pipe(select(fromUserHeaderStore.getNotificationCount))
      .subscribe((res) => {
        this.unreadNotificationCount = res;
      });
  }

  public _searchTerm: string;

  get searchTerm(): string {
    return this._searchTerm;
  }

  set searchTerm(value: string) {
    this._searchTerm = value;
  }

  showTimer() {
    if (!this.timerActive) {
      this.store.dispatch(new UserHeaderActions.ShowTimer());
    } else {
      this.store.dispatch(new UserHeaderActions.HideTimer());
    }
  }

  showActivitiesDrawer() {
    this.activitiesDrawerShown = true;
  }

  closeActivitiesDrawer() {
    this.activitiesDrawerShown = false;
  }

  clearSearchField() {
    this.searchText.nativeElement.value = "";
  }

  search() {
    // do search logic
    console.log(this.searchText.nativeElement.value);
    this.router.navigate(["/firm/search"], {
      queryParams: {q: this.searchTerm}
    });
  }

  hideTimer() {
  }

  ngOnInit() {
    this.actor = {
      firstName: "Lekan",
      lastName: "Sogbehin"
    };

    this.entityTypeObjecct = {
      name: "",
      title: "Complete Ade Case"
    };

    this.entityName = this.entityTypeObjecct.title;
    this.action = "March 22, 9:09AM";

    this.activitiesDrawerShown = false;
  }
}
