import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { config } from "../../../../assets/config";


@Injectable({
  providedIn: "root"
})
export class AppConfigService {
  configUrl = "../../.assets/config.ts";
  private appData;

  constructor(private http: HttpClient) {
  }

  loadAppConfig() {
    return (this.appData = config);
  }

  // loadAppConfig() {
  //   return this.http.get(this.configUrl)
  //   .toPromise()
  //       .then(data => {
  //         this.appData = data;
  //         console.log('getting data  =>', this.appData);
  //       }).catch(reason => {
  //         console.log(reason);
  //       });
  //   // .pipe(
  //   //   retry(3), // retry a failed request up to 3 times
  //   //   catchError(this.handleError) // then handle the error
  //   // );
  // }

  getConfig() {
    console.log("calling the config ");
    this.appData = this.loadAppConfig();
    // this.appData = config;
    console.log("got the config ", this.appData);
    return this.appData;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
