import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import * as TaskActions from "../../../core/store/actions/task.action";
import * as fromTask from "../../../core/store/reducers/task.reducer";

@Component({
  selector: "app-completed-task",
  templateUrl: "./completed-task.component.html",
  styleUrls: ["./completed-task.component.scss"]
})
export class CompletedTaskComponent implements OnInit {
  @Input() tasks: UserTask[];
  currentTask: any;
  showCurrentTask: boolean;

  constructor(private taskStore: Store<fromTask.State>) {
  }

  ngOnInit() {
  }

  closeViewTask(e) {
    if (e === "true") {
      this.taskStore.dispatch(new TaskActions.ViewTask(false));
    }
  }

  setCurrentTask(task) {
    this.currentTask = task;
  }
}
