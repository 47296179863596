import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { isNullOrUndefined } from "util";
import { Token } from "../../../core/models/generic-model.ts/token";
import { UserLoginDetail } from "../../../core/models/parse-model/login";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { AuthService } from "../../../core/services/auth.service";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

import * as fromStore from "../../../core/store";
import * as LoginActions from "../../../core/store/actions/login.actions";
// if its a lazy loaded feature
// import * as fromAuth from '../../store/reducers/login.reducer';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: UserDetail;
  responseData: Token;
  token: any;
  loginShowError;
  userProfile: UserDetail;
  userToken$: Observable<any>;
  loginBtnSpinner: boolean;
  pageTitle = "Sign In | LP Court manager";
  isLoginError: boolean;
  emailVal: string;
  errorMessage: any;
  alreadyLoogedIn: boolean;

  @ViewChild("errorBox") errorBox: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private currentUserService: CurrentUserService,
    //   private store: Store<fromAuth.AuthState>,
    private store: Store<fromStore.State>,
    private loginService: AuthService,
    private route: ActivatedRoute
  ) {
    this.emailVal = this.route.snapshot.paramMap.get("email");
    this.createForm();
    // this.userToken$ =
    // this.store.pipe(select(fromStore.getUserToken)).subscribe(data => {
    this.store.pipe(select("auth")).subscribe((data) => {
      if (data.isLogging) {
        this.loginBtnSpinner = true;
      }

      console.log(data);
      if (!isNullOrUndefined(data.errorMsg)) {
        console.log(data);
        this.isLoginError = true;

        this.loginBtnSpinner = false;

        if (data.errorMsg.status === 404) {
          // this.alreadyLoogedIn = true;

          this.errorMessage = "<strong>The email entered/password is incorrect, please contact your admin";
        } else if (data.errorMsg.status === 400) {
          // this.alreadyLoogedIn = true;

          this.errorMessage = "<strong>The email entered/password is incorrect, please contact your admin";
        } else if (data.errorMsg.status === 501) {
          this.errorMessage = "<strong>This is embarrassing!!! , an error occurred on our server, try to refresh and clear your browser data, or send us an email if it persist</strong>";
        } else if (data.errorMsg.status === 409) {
          this.alreadyLoogedIn = true;
          //  this.email.disable();
        } else {
          this.errorMessage = "<strong>An unknown error occured !!! Please check your internet connection</strong>";
        }
      } else if (data.loggedIn) {
        console.log(data);
        // / save token and user detail to local storage
        let userData = new UserDetail();
        userData = data.userProfile;
        this.currentUserService.addToken(
          data.authToken,
          data.accountPermissions
        );
        this.currentUserService.addUserProfile(userData);
        this.currentUserService.addEmail(data.email);
        this.currentUserService.addRole(data.role);
      } else {
        this.errorMessage = "<strong>An unknown error occured !!! Please check your internet connection</strong>";
      }
    });
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  forceLogout() {
    console.log(this.email.value);
    this.router.navigate(["/logout", this.email.value]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [
        this.emailVal,
        [
          Validators.required,
          Validators.pattern(
            /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/
          )
        ]
      ],
      password: ["", Validators.required]
    });
  }

  ngOnInit(): void {
    this.isLoginError = false;
    this.loginBtnSpinner = false;
    this.alreadyLoogedIn = false;
  }

  login() {
    this.loginBtnSpinner = true;
    let userLoginDetail: UserLoginDetail;
    userLoginDetail = this.loginForm.value;
    const email: string = this.loginForm.value.email;
    const password: string = this.loginForm.value.password;
    console.log(userLoginDetail);
    this.store.dispatch(new LoginActions.Login({email, password}));
    if (this.isLoginError) {
      this.errorBox.nativeElement.focus();
    }

    // this.loginService.signIn({email, password}).subscribe(
    //   data => {
    //     console.log(data);

    // });

    // this
    //   .store
    //   .select(selectIsLoginState)
    //   .subscribe(res => {
    //     this.isLogging$ = res;
    //   });

    // this.loginService.signIn(this.user)
    //   .subscribe(
    //     data => {
    //         console.log(data);
    //         this.responseData = data;
    //         if (this.responseData.token) {
    //         this.token = this.responseData.token.auth_token;
    //         this.userProfile = this.responseData.userProfileObject;
    //         this.loginService.AccessToken = this.responseData.token.auth_token;
    //         // // we delete the existing cookies first
    //         //   this.cookieService.delete('token');
    //         //   this.cookieService.delete('user_profile');
    //         //   // we set new cookies
    //         // this.cookieService.set('token', this.token);
    //         // this.cookieService.set('user_profile', JSON.stringify(this.userProfile));
    //         this.currentUserService.addToken(this.token);
    //         this.currentUserService.addUserProfile(this.userProfile);
    //         this.currentUserService.savePermissions(this.responseData.userProfileObject.userMenus);

    //         this.mytoken = this.currentUserService.getToken();  // this.cookieService.get('token'); // get the cookie object value
    //         console.log(this.userProfile);
    //         // Added admin roles in an array. If user role is found in admin roles list, then go to admin page.
    //         if (this.userProfile.isAdmin) {
    //           this.router.navigate(['/admin']);
    //         } else {
    //           this.router.navigate(['/dashboard']);
    //         }
    //       } else {
    //         console.log('login failed !');
    //         this.loginShowError = true;
    //         this.loginBtnSpinner = false;
    //       }
    //     },
    //     error => {
    //       console.log('login failed !');
    //      this.loginShowError = true;
    //      this.loginBtnSpinner = false;
    //     },
    //     () => {
    //       console.log('got here');
    //       // show error message
    //     }
    //   );
    // show error response
  }

  LoginEnter() {
    if (this.email.value !== "" && this.password.value !== "") {
      this.login();
    }
  }
}
