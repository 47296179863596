export class PermissionEditModel {
  permissionsActionId?: number[];
  permissionEntityId?: number;
}

export class PermissionControlModel {
  action: string;
  actionId: number;
  firmRoleId: number;
  id: number;
  permissionEntity: string;
  permissionEntityId: number;
}
