import { Injectable } from "@angular/core";

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CalendarViewModel } from "../models/view-model/calendar-event";
import { CalendarService } from "../services/calendar.service";

@Injectable()
export class CalendarResolverService implements Resolve<CalendarViewModel[]> {
  upcomingCalendarEvents: CalendarViewModel[] = [];

  constructor(private calendarService: CalendarService) {
  }

  getCurrentMonth(): number {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = new Date().getMonth() + 1;
    const date = new Date();
    console.log(date.getMonth());

    return currentMonth;
  }

  getCurrentYear(): number {
    let currentMonth: number;
    currentMonth = new Date().getFullYear();

    return currentMonth;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CalendarViewModel[]> {
    const month = this.getCurrentMonth();
    const year = this.getCurrentYear();

    // this.calendarService
    //   .loadUserTaskCalendar(month, year)
    //   .subscribe(calendarEventData => {
    //     console.log(calendarEventData);
    //     if (!isNullOrUndefined(calendarEventData)) {
    //       if (calendarEventData.length > 0) {
    //         this.upcomingCalendarEvents = this.upcomingCalendarEvents.concat(
    //           calendarEventData
    //         );
    //       }
    //     }
    //   });
    // this.calendarService
    //   .loadFirmApplicationCalendar(month, year)
    //   .subscribe(calendarEventData => {
    //     console.log(calendarEventData);
    //     if (!isNullOrUndefined(calendarEventData)) {
    //       if (calendarEventData.length > 0) {
    //         this.upcomingCalendarEvents = this.upcomingCalendarEvents.concat(
    //           calendarEventData
    //         );
    //       }
    //     }
    //   });

    return this.calendarService.loadDashboardFirmUpcomingEvents();

    // this.calendarService
    //   .loadFirmProceedingCalendar(month, year)
    //   .subscribe(calendarEventData => {
    //     console.log(calendarEventData);
    //     if (!isNullOrUndefined(calendarEventData)) {
    //       if (calendarEventData.length > 0) {
    //         this.upcomingCalendarEvents = this.upcomingCalendarEvents.concat(
    //           calendarEventData
    //         );
    //       }
    //     }
    //   });

    // return of( this.upcomingCalendarEvents)

    // return null;
  }
}
