import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FirmRole } from "../../models/view-model/role-list";
import { FirmRoleActions, FirmRoleActionTypes } from "../actions/firm-roles.action";
import * as fromRoot from "../app.state";

export interface State extends fromRoot.State {
  firmRoles: FirmRoleState;
}

export interface FirmRoleState {
  firmRoles: FirmRole[];
  error: string;
  currentRoleId: number;
  currentRole: FirmRole;
  isUpdating: boolean;
  updateSuccessful: boolean;
}

const initialState: FirmRoleState = {
  firmRoles: [],
  error: null,
  currentRoleId: 0,
  isUpdating: false,
  currentRole: null,
  updateSuccessful: false
};
const getFirmRoleFeatureState = createFeatureSelector<FirmRoleState>("roles");

export const getFirmRoles = createSelector(
  getFirmRoleFeatureState,
  (state) => state.firmRoles
);
export const getErrorState = createSelector(
  getFirmRoleFeatureState,
  (state) => state.error
);
export const getCurrentRoleId = createSelector(
  getFirmRoleFeatureState,
  (state) => state.currentRoleId
);

export const getUpdateState = createSelector(
  getFirmRoleFeatureState,
  (state) => state.updateSuccessful
);
export const getCurrentRole = createSelector(
  // getFirmRoleFeatureState,
  // getCurrentRoleId,
  // (state, currentRoleId) => {
  //     if (currentRoleId === 0) {
  //         return {
  //             Id: 0,
  //             Name: '',
  //             Description: '',
  //             PermissionCount: 0,
  //             UserCount: 0,
  //             FirmRolePermissions: []
  //         };
  //     } else {
  //         return currentRoleId ? state.firmRoles.find(p => p.id === currentRoleId) : null;
  //     }
  // }

  getFirmRoleFeatureState,
  (state) => state.currentRole
);

export function firmRoleReducer(
  state = initialState,
  action: FirmRoleActions
): FirmRoleState {
  switch (action.type) {
    case FirmRoleActionTypes.LoadRoles:
      return {
        ...state,
        error: null,
        updateSuccessful: false
      };

    case FirmRoleActionTypes.LoadRolesSuccess:
      return {
        ...state,
        firmRoles: action.payload,
        error: null,
        updateSuccessful: false
      };
    case FirmRoleActionTypes.LoadRolesFail:
      return {
        ...state,
        firmRoles: [],
        error: action.payload,
        updateSuccessful: false
      };
    case FirmRoleActionTypes.setCurrentRole:
      return {
        ...state,
        updateSuccessful: false
        // currentRoleId: action.payload
      };

    case FirmRoleActionTypes.SET_CURRENT_ROLE_SUCCESS:
      //    state.currentRole = state.firmRoles.find(c => c.id === action.payload);
      //    console.log(state.firmRoles);
      console.log(action.payload);
      return {
        ...state,
        currentRole: action.payload,
        error: null,
        updateSuccessful: false
      };

    case FirmRoleActionTypes.SET_CURRENT_ROLE_FAIL:
      //    state.currentRole = state.firmRoles.find(c => c.id === action.payload);
      //    console.log(state.firmRoles);
      // console.log(state.currentRole);
      return {
        ...state,
        currentRole: null,
        error: "Error occured with fetching role",
        updateSuccessful: false
      };

    case FirmRoleActionTypes.UpdateRoles:
      return {
        ...state,
        isUpdating: true,
        updateSuccessful: false
        // currentRoleId: action.payload
      };
    case FirmRoleActionTypes.UpdateRolesSuccess:
      console.log(action.payload);
      return {
        ...state,
        error: null,
        isUpdating: false,
        updateSuccessful: true
      };
    case FirmRoleActionTypes.UpdateRolesFail:
      //    state.currentRole = state.firmRoles.find(c => c.id === action.payload);
      //    console.log(state.firmRoles);
      // console.log(state.currentRole);
      return {
        ...state,
        currentRole: null,
        error: "Error occured with updating role",
        isUpdating: false,
        updateSuccessful: false
      };
    // case FirmRoleActionTypes.UpdateRolesSuccess:
    //     const updatedFirmRoles = state.firmRoles.map(item => item.id === action.payload.id ? action.payload : item);
    //     return {
    //         ...state,
    //         firmRoles: updatedFirmRoles,
    //         error: null,
    //         currentRoleId: action.payload.id
    //     };
    default:
      return state;
  }
}
