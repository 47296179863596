export class LicenseModel {
  id: number;
  name: string;
  discountedPercentagePerUser: number;
  minimumUserCap: number;
  maximumUserCap: number;
  dataCap: number;
  isFreeSubscriptionType: boolean;
  valueDays: number;
}

export class PlanGroup {
  days: number;
  groupName: string;
  minimumAmount: number;
  maximumuAmount: number;

  bucketList: LicenseEndPointModel[];
}

export class LicenseEndPointModel {
  id: number;
  name: string;
  discountedPercentagePerUser: number;
  minimumUserCap: number;
  maximumUserCap: number;
  dataCap: number;
  isFreeSubscriptionType: boolean;
  valueDays: number;
  amount: number;
  crmPlanId?: number;
}

export interface MonthLicenseCost {
  id: number;
  name: string;
}

export interface PeriodDiscount {
  id: number;
  period: string;
  discountPercentage: number;
  monthCount: number;
  periodCost: number;
}
