import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { FilterGenericModel } from "../models/generic-model.ts/filter-model";
import { GenericListViewModel } from "../models/generic-model.ts/generic-list";
import { GenericListCountViewModel } from "../models/generic-model.ts/list-count";

import { ApplicationParseModel } from "../models/parse-model/add-application";
import { AddMatter } from "../models/parse-model/add-matter";
import { CourtMatterParseModel } from "../models/parse-model/matter-court";
import { JudgemntParsemModel } from "../models/parse-model/new-judgement";
import { Endpoints } from "../models/shared-model/endpoint";
import { ApplicationViewModel } from "../models/view-model/application-list";
import { ContactViewModel } from "../models/view-model/contact-list";
import { documentListSample, folderSampleList } from "../models/view-model/document-list";
import { ProceedingViewModel } from "../models/view-model/endorsement-list";
import { ExpenseSample } from "../models/view-model/matter-expenses";
import { ContactListLite, CourtListModel, EditMatterCourt } from "../models/view-model/matter-list";
import { TasksSample } from "../models/view-model/matter-tasks";
import { MatterTeamMember } from "../models/view-model/team-list";
import { MatterTimeSheetModel } from "../models/view-model/time-sheet";
import { UserDetail } from "../models/view-model/user-detail";
import * as fromStore from "../store";

@Injectable({
  providedIn: "root"
})
export class MatterService {
  matterCategory = new GenericListViewModel();

  Endpoints = new Endpoints(this.config);

  filter = new FilterGenericModel();

  userData = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private config: AppConfigService,
    private store: Store<fromStore.State>
  ) {
    this.filter = new FilterGenericModel();

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.matterCategory.id = +params.get("categoryId");
      this.matterCategory.name = params.get("categoryName");

      console.log("Query params ", this.matterCategory);

      if (!isNullOrUndefined(params.get("ongoing"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.ongoing =
          params.get("ongoing").toLowerCase() === "true"
            ? true
            : false;
      }

      if (!isNullOrUndefined(params.get("archived"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.archived =
          params.get("archived").toLowerCase() === "true"
            ? true
            : false;
      }

      if (!isNullOrUndefined(params.get("completed"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.completed =
          params.get("completed").toLowerCase() === "true"
            ? true
            : false;
      }

      if (!isNullOrUndefined(params.get("categoryType"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.categoryType = JSON.parse(
          "[" + params.getAll("categoryType") + "]"
        );

        // this.filter.categoryType.forEach(c => {
        //   // tslint:disable-next-line:triple-equals
        //   this.practiceAreas.find(p => p.id === c).checked = true;
        // });
      }
      if (!isNullOrUndefined(params.get("categorySubType"))) {
        this.filter.categorySubType = JSON.parse(
          "[" + params.getAll("categorySubType") + "]"
        );
      }

      if (!isNullOrUndefined(params.get("clientId"))) {
        // tslint:disable-next-line:triple-equals
        this.filter.clientId = Number.parseInt(
          params.get("clientId"),
          10
        );
        console.log(this.filter.clientId);
      }

      console.log(this.matterCategory);
      console.log(this.filter);
    });

    // geth the userId from the store (used NGRX)
    this.store.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
    });
  }

  /** **********
   *  MATTER
   ************/
  public getMatterList(
    firmId?: number,
    searchQuery = "",
    sort = "",
    matterCategoryId?: number,
    order = "asc",
    page?: number,
    pageSize = 0,
    usePageSize = false
  ): Observable<any> {
    console.log(firmId);

    const endpoint =
      this.config.getConfig().serverEndpoint +
      `matter/${firmId}/firmlite`;
    // tslint:disable-next-line:max-line-length
    // const api = `${url}??ongoing=${ongoing}&archived=${archived}&completed=${completed}categoryType=${categoryType}&categorySubType=${categorySubType}&clientId=${clientId}&searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}&matterFilter=${matterFilter}`;
    // console.log(api);
    const url = `${endpoint}?searchQuery=${searchQuery}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
    // return of(matterListSample);
    // return of(dashboardMatterListSample);
  }

  getMatterDetailsForNewTask(resourceId: number, search: string = ''): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `task/matter/${resourceId}?searchQuery=${search}`;
    return this.httpClient.get<any>(api);

  }

  getMatterEndorsement(
    matterId?: number,
    isAdmin?: boolean,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex = 0,
    pageSize = 0
  ): Observable<any> {
    console.log(matterId);
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "matter/" +
      matterId +
      "/proceeding";
    const usePageSize = true;
    // tslint:disable-next-line: max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getMatterApplication(
    matterId?: number,
    isAdmin?: boolean,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex = 0,
    pageSize = 0
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "matter/" +
      matterId +
      "/application";
    const usePageSize = true;
    // tslint:disable-next-line: max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getMatterApplicationById(
    applicationId: number
  ): Observable<ApplicationViewModel> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/application/" +
      applicationId;
    return this.httpClient.get<ApplicationViewModel>(api);
  }

  updateMatterApplication(model: ApplicationParseModel): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + "matter/application";
    return this.httpClient.put<any>(api, model);
  }

  public getMatterListNoSearch(firmId: number): Observable<any> {
    console.log(firmId);

    const url =
      this.config.getConfig().serverEndpoint + `matter/${firmId}/firm`;
    // tslint:disable-next-line:max-line-length
    // const api = `${url}??ongoing=${ongoing}&archived=${archived}&completed=${completed}categoryType=${categoryType}&categorySubType=${categorySubType}&clientId=${clientId}&searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}&matterFilter=${matterFilter}`;
    // console.log(api);
    return this.httpClient.get<any>(url);
    // return of(matterListSample);
    // return of(dashboardMatterListSample);
  }

  getMatterendorsementByPrcceedingId(
    proceedingId?: number
  ): Observable<ProceedingViewModel> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/proceeding/" +
      proceedingId;
    return this.httpClient.get<ProceedingViewModel>(api);
  }

  deleteMatterEndorsement(matterId: number): Observable<boolean> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/proceeding/" +
      matterId;
    return this.httpClient.delete<boolean>(api);
  }

  getMatterOverview(matterID: number): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + 'matter/' + matterID;
    return this.httpClient.get<any>(api);
  }

  addApplicationFiles(model) {
    const url = this.config.getConfig().serverEndpoint + "task/taskfiles";
    return this.httpClient.post<any>(url, model);
  }

  deleteMatterApplication(applicationId: number): Observable<boolean> {
    const api =
      this.config.getConfig().serverEndpoint + "matter/" + applicationId;
    return this.httpClient.delete<boolean>(api);
  }

  // matter filter calls
  public getMatterPractiseArearAndMatterCount(categoryId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${categoryId}/categorysubtypemattercount`;
    console.log(url);
    return this.httpClient.get<GenericListCountViewModel[]>(url);
  }

  public getMatterSubCategoryAndMatterCount(categoryId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${categoryId}/categorytypemattercount`;
    console.log(url);
    return this.httpClient.get<GenericListCountViewModel[]>(url);
  }

  public getMatterCategoryClient(categoryId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${categoryId}/GetMatterCategoryClient`;
    return this.httpClient.get<ContactViewModel[]>(url);
  }

  // end of filter calls
  // ongoing: boolean;
  // archived: boolean;
  // completed: boolean;
  // categoryType: number[];
  // categorySubType: number[];
  // clientId: number;
  // public getMatterListByCatgory(
  //   ongoing?: boolean,
  //   archived?: boolean,
  //   completed?: boolean,
  //   categoryType = [0],
  //   categorySubType = [0],
  //   clientId = 0,
  //   searchQuery = "",
  //   sort = "",
  //   firmId = 0,
  //   matterCategoryId?: number,
  //   order = "asc",
  //   page?: number,
  //   pageSize = 0,
  //   usePageSize?: boolean
  // ): Observable<any> {
  //   console.log(categorySubType);
  //   console.log(categoryType);
  //   if (usePageSize !== false) {
  //     usePageSize = true;
  //   }
  //   console.log(categoryType);
  //   console.log(categorySubType);
  //   let categoryTypeQueryString: string;
  //   let categorySubTypeQueryString: string;
  //   let clientQueryNumber: string;

  //   let ongoingQueryString: string;
  //   let archivedQueryString: string;
  //   let completedQueryString: string;

  //   categoryTypeQueryString = "";
  //   categorySubTypeQueryString = "";
  //   clientQueryNumber = "";
  //   if (isNullOrUndefined(ongoing)) {
  //     ongoingQueryString = `ongoing=${false}&`;
  //   } else {
  //     ongoingQueryString = `ongoing=${ongoing}`;
  //   }
  //   if (isNullOrUndefined(completed)) {
  //     completedQueryString = `completed=${false}&`;
  //   } else {
  //     completedQueryString = `&completed=${completed}`;
  //   }
  //   if (isNullOrUndefined(archived)) {
  //     archivedQueryString = `archived=${false}&`;
  //   } else {
  //     archivedQueryString = `&archived=${archived}`;
  //   }
  //   if (
  //     archived ||
  //     completed ||
  //     ongoing ||
  //     categorySubType[0] > 0 ||
  //     categoryType[0] > 0 ||
  //     clientId > 0
  //   ) {
  //     if (isNullOrUndefined(ongoing)) {
  //       ongoingQueryString = `ongoing=${false}&`;
  //     } else {
  //       ongoingQueryString = `ongoing=${ongoing}&`;
  //     }

  //     if (isNullOrUndefined(archived)) {
  //       archivedQueryString = `archived=${false}&`;
  //     } else {
  //       archivedQueryString = `archived=${archived}&`;
  //     }

  //     if (isNullOrUndefined(completed)) {
  //       completedQueryString = `completed=${false}&`;
  //     } else {
  //       completedQueryString = `completed=${completed}&`;
  //     }
  //     if (isNullOrUndefined(categorySubType === null)) {
  //       console.log(categorySubType);
  //       categorySubTypeQueryString = "&";
  //       // categoryTypeQueryString = `&CategoryType=${[0]}`;
  //     }

  //     if (isNullOrUndefined(categoryType === null)) {
  //       console.log(categoryType);
  //       // categorySubTypeQueryString = `&CategorySubType=${[0]}`;
  //       categoryTypeQueryString = "&";
  //     }

  //     if (!isNullOrUndefined(clientId === null)) {
  //       clientQueryNumber = `&clientId=${clientId}`;
  //     } else {
  //       clientQueryNumber = "";
  //     }
  //   }
  //   if (categorySubType.length > 1 || categorySubType[0] > 0) {
  //     categorySubType.forEach((c) => {
  //       categorySubTypeQueryString =
  //         categorySubTypeQueryString + `&CategorySubType=${c}`;
  //     });
  //   } else {
  //     // if the query string is default or one query param
  //     categorySubTypeQueryString = "";
  //   }

  //   if (categoryType.length > 1 || categoryType[0] > 0) {
  //     categoryType.forEach((c) => {
  //       categoryTypeQueryString =
  //         categoryTypeQueryString + `&CategoryType=${c}`;
  //     });
  //   } else {
  //     // if the query string is default or one query param
  //     categoryTypeQueryString = "";
  //   }

  //   if (!isNullOrUndefined(clientId === null) && clientId !== 0) {
  //     clientQueryNumber = `&clientId=${clientId}`;
  //   } else {
  //     clientQueryNumber = "";
  //   }

  //   // console.log(firmId);
  //   console.log(matterCategoryId);
  //   console.log(this.filter);
  //   let url = "";
  //   const allUrl =
  //     this.config.getConfig().serverEndpoint +
  //     `matter/${matterCategoryId}/category/All`;
  //   const ongoingUrl =
  //     this.config.getConfig().serverEndpoint +
  //     `matter/${matterCategoryId}/category/Ongoing`;
  //   const archievedUrl =
  //     this.config.getConfig().serverEndpoint +
  //     `matter/${matterCategoryId}/category/Archeived`;
  //   const completedUrl =
  //     this.config.getConfig().serverEndpoint +
  //     `matter/${matterCategoryId}/category/completed`;
  //   if (!isNullOrUndefined(ongoing) && ongoing) {
  //     url = ongoingUrl;
  //   } else if (!isNullOrUndefined(completed) && completed) {
  //     url = completedUrl;
  //   } else if (!isNullOrUndefined(archived) && archived) {
  //     url = archievedUrl;
  //   } else {
  //     url = allUrl;
  //   }

  //   // tslint:disable-next-line:max-line-length
  //   const api = `${url}?${ongoingQueryString}${archivedQueryString}${completedQueryString}${clientQueryNumber}&searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
  //     page + 1
  //   }&pageSize=${pageSize}&usePageSize=${usePageSize}${categoryTypeQueryString}${categorySubTypeQueryString}`;
  //   console.log(api);
  //   return this.httpClient.get<any>(api, {observe: "response"});
  //   // return of(matterListSample);
  //   // return of(dashboardMatterListSample);
  // }

  // change type of observable returned
  public getCurrentMatter(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    return this.httpClient.get(url);
  }

  public getCurrentMatterJudges(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/judge`;
    return this.httpClient.get(url);
  }

  public addMatter(data: AddMatter) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.matterEndPoint;
    return this.httpClient.post<any>(url, data);
  }

  public getMatterCategory(): Observable<GenericListViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + "matter_category";
    // return this.httpClient.post<any>(url, data);
    const matterCategory: GenericListViewModel[] = [
      {
        id: 1,
        name: "ongoing"
      },
      {
        id: 2,
        name: "closed"
      },
      {
        id: 3,
        name: "archived"
      }
    ];
    return of(matterCategory);
  }

  /**
   * Get Matter Contacts
   * @param matterId The Id of the matter
   */
  public getContacts(matterId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/contact`;
    return this.httpClient.get(url);
  }

  /** **********
   *  Judges
   ************/

  public deleteCourtJudge(courtMatterJudgeId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${courtMatterJudgeId}/judge`;
    return this.httpClient.delete<any>(url);
  }

  public getFiles(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/file`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(documentListSample).pipe(catchError(this.handleError));
  }

  public getFolders(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/directory`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(folderSampleList).pipe(catchError(this.handleError));
  }

  public getAllFiles(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/file`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(documentListSample).pipe(catchError(this.handleError));
  }

  public getAllFolders(matterId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/directory`;
    // return this.httpClient.get(url).pipe(
    //   catchError(this.handleError)
    //   );
    return of(folderSampleList).pipe(catchError(this.handleError));
  }

  // getAllApplicationStatus(): Observable<GenericListViewModel[]> {
  //   const api = this.config.getConfig().serverEndpoint + '';
  //   return this.httpClient.get<GenericListViewModel[]>(api);
  // }
  // getApplicationType(): Observable<GenericListViewModel[]> {
  //   const api = this.config.getConfig().serverEndpoint + '';
  //   return this.httpClient.get<GenericListViewModel[]>(api);

  // }
  getCourtList(matterId): Observable<CourtListModel[]> {
    const api = this.config.getConfig().serverEndpoint + "CourtType";
    return this.httpClient.get<CourtListModel[]>(api);
  }

  updateMatterCourt(courtDetails: EditMatterCourt): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/change-court/court";
    return this.httpClient.patch<any>(api, courtDetails);
  }

  // getJudgementTypeList(): Observable<GenericListViewModel[]> {
  //   const api = this.config.getConfig().serverEndpoint + '';
  //   return this.httpClient.get<GenericListViewModel[]>(api);
  // }
  addJudgement(model: JudgemntParsemModel): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "matter/judgement";
    return this.httpClient.post<any>(api, model);
  }

  public getMatterDocument(matterId: number): Observable<Document[]> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/ " +
      matterId +
      "/document";
    return this.httpClient.get<Document[]>(api);
  }

  /** ******
   *  NOTE
   ********/

  /** *************
   *  COURT
   ***************/

  public changeCourt(data: CourtMatterParseModel) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.changeMatterCourtEndPoint;
    return this.httpClient.patch<any>(url, data);
  }

  public deleteCurrentCourt(courtMatterId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${courtMatterId}/court`;
    return this.httpClient.delete<any>(url);
  }

  /** *************
   *  APPLICATIONS
   ***************/

  public loadApplicationList(matterId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/application`;
    return this.httpClient.get(url);
    // return of (applicationListSample);
  }

  public addNewApplication(object) {
    const url =
      this.config.getConfig().serverEndpoint + "matter/application";
    return this.httpClient.post<any>(url, object);
  }

  public editApplication(data): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "/matter/application";
    return this.httpClient.put(url, data);
    // return of (data);
  }

  /** *************
   *  JUDGEMENTS
   ***************/
  public loadJudgementList(matterId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/judgement`;
    return this.httpClient.get(url);
    // return of (judgementListSample);
  }

  public addNewJudgement(object) {
    const url = this.config.getConfig().serverEndpoint + "matter/judgement";
    return this.httpClient.post<any>(url, object);
  }

  public editJudgement(data): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "/matter/judgement";
    return this.httpClient.put(url, data);
    // return of (data);
  }

  getJudgementByJudgementId(judgementId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/judgement/" +
      judgementId;
    return this.httpClient.get<any>(api);
  }

  /** *************
   *  ENDORSEMENT
   ***************/
  public loadEndorsementList(matterId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/proceeding`;
    return this.httpClient.get(url);
    // return of (endorsementListSample);
  }

  public addNewEndorsement(object) {
    const url =
      this.config.getConfig().serverEndpoint + "matter/proceeding";
    return this.httpClient.post<any>(url, object);
  }

  public addNewCourtAppearance(object) {
    const url =
      this.config.getConfig().serverEndpoint + "matter/Appearance";
    return this.httpClient.post<any>(url, object);
  }

  public editEndorsement(object) {
    const url =
      this.config.getConfig().serverEndpoint + "matter/proceeding";
    return this.httpClient.put<any>(url, object);
  }

  public getEndorsementDetails(proceedingId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/proceeding/${proceedingId}`;
    return this.httpClient.get<any>(url);
  }

  /** ******
   *  TEAM
   ********/

  public getTeam(teamId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${teamId}/teammembers`;
    return this.httpClient.get<any>(url);
  }

  public deleteTeamMember(teamMemberId: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${teamMemberId}/member/tem`;
    return this.httpClient
      .delete<any>(url)
      .pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteClient(matterId, client): Observable<any> {
    const contactId = client.contactId;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/contact/${contactId}`;
    return this.httpClient
      .delete<any>(url)
      .pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteEndorsementDate(proceedingdateId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/proceedingdate/${proceedingdateId}`;
    return this.httpClient
      .delete<any>(url)
      .pipe(catchError(this.handleError));
    // return of (true);
  }

  public deleteParty(matterId, party): Observable<any> {
    const contactId = party.contactId;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/contact/${contactId}`;
    return this.httpClient
      .delete<any>(url)
      .pipe(catchError(this.handleError));
    // return of (true);
  }

  public appearanceDate(proceedingId, date): Observable<any> {
    const _date = date;
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/${proceedingId}/appearanceDate/${_date}`;
    return this.httpClient
      .delete<any>(url)
      .pipe(catchError(this.handleError));
    // return of (true);
  }

  public addNewTeamMember(teamMemberFull): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/member/team";
    const obj: any[] = [];
    teamMemberFull.forEach((m) => {
      obj.push({
        userId: m.userId,
        firmRoleId: m.firmRoleId,
        teamId: m.teamId
      });
    });
    console.log(obj);
    return this.httpClient
      .post(url, obj)
      .pipe(catchError(this.handleError));
    // return of (12)
  }

  /** ******
   *  PARTY
   ********/

  public addNewParty(data: ContactListLite): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.matterContactEndPoint;
    return this.httpClient.post(url, data);
    // return of (null).pipe(
    //   catchError(this.handleError)
    // );
  }

  attachDocumentForMatters(model): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "file";
    return this.httpClient.post<any>(api, model);
  }

  createNewCourt(obj) {
    const url = this.config.getConfig().serverEndpoint + "matter/court";
    return this.httpClient.post<any>(url, obj);
  }

  createNewJudge(obj) {
    const url = this.config.getConfig().serverEndpoint + "matter/judge";
    return this.httpClient.post<any>(url, obj);
  }

  public addNewTask(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "task/matter";
    return this.httpClient.post(url, payload);
  }

  // Tasks

  public updateTask(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "task/matter";
    return this.httpClient.put(url, payload);
  }

  public getMatterTasks(id: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of(TasksSample);
  }

  public markTaskAsCompleted(id: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of(TasksSample[0]);
  }

  public deleteTask(id: number): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "task/matter/" + id;
    // return this.httpClient.get(url);
    return of({});
  }

  public addNewExpense(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.post(url, payload);
  }

  // public addTaskComment(payload): Observable<any> {
  //   const url = this.config.getConfig().serverEndpoint + '';
  //   // return this.httpClient.post(url, payload);
  //   return of({});
  // }

  // expenses

  public updateExpense(payload: any) {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.put(url, payload);
  }

  public getExpense(expenseId: number) {
    const url =
      this.config.getConfig().serverEndpoint + "expense/" + expenseId;
    // return this.httpClient.get(url);
    return of(ExpenseSample[0]);
  }

  public getMatterExpenses(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number = 1,
    pageSize = 1,
    usePageSize = true
  ): Observable<any> {
    let url =
      this.config.getConfig().serverEndpoint +
      "expense/" +
      matterId +
      "/Expenses";
    url = `${url}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get(url);
    // return of(ExpenseSample);
  }

  public deleteExpense(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "expense/" + id;
    return this.httpClient.delete(url);
    // return of({});
  }

  saveTimeEntry(payload: MatterTimeSheetModel) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.timeSheetEndPoint;
    return this.httpClient.post(url, payload);
  }

  // time sheet

  public getMatterTimes(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number = 1,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    let url =
      this.config.getConfig().serverEndpoint +
      `expense/${matterId}/Timesheets`;
    url = `${url}?searchQuery=${searchQuery}&sort=${sort}&isAdmin=${this.userData.isAdmin}&order=${order}&page=${page}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(url);
    return this.httpClient.get(url, {observe: "response"});
    // return of(TimeSheetSample);
  }

  public deleteTime(id: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "time/" + id;
    return this.httpClient.delete(url);
    // return of({});
  }

  /** **********
   *  DOCUMENTS
   ************/
  /**
   *
   * @param matterId
   * @param searchQuery
   * @param sort
   * @param order
   * @param page
   * @param pageSize
   * @param usePageSize
   */
  public getMatterDocumentFiles(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `file/${matterId}/${this.userData.isAdmin}/matterfilesfolder`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  // tslint:disable-next-line: max-line-length
  public getMatterDocumentFolders(
    directoryId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const fileType = 4;
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `file/Directory/${directoryId}/fileType/${fileType}`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?&matterId=${directoryId}&fileType=${fileType}searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  /**
   *
   * @param matterFileId for Deleting (files)
   */
  public deletetMatterDocumentFiles(id: number): Observable<any> {
    const fileType = 4;
    const api =
      this.config.getConfig().serverEndpoint +
      `file/${id}/filetype/${fileType}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  /**
   *
   * @param matterDirectoryId for Deleting Folder(directories)
   */
  public deleteMatterDocumentFolders(id: number): Observable<any> {
    const fileType = 4;
    const api =
      this.config.getConfig().serverEndpoint +
      `file/Directory/${id}/filetype/${fileType}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  /**
   *
   * @param values Create New Note
   */
  public createNote(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/note";
    return this.httpClient.post<any>(url, values);
  }

  /** **********
   *  DOCUMENTS
   ************/

  /** **********
   * NOTES
   ************/

  /**
   *
   * @param values Edit Note
   */
  public EditNote(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/note";
    return this.httpClient.put<any>(url, values);
  }

  /**
   * Get list of Notes
   * @param matterId
   * @param searchQuery
   * @param sort
   * @param order
   * @param page
   * @param pageSize
   * @param usePageSize
   */
  // tslint:disable-next-line: max-line-length
  public getNoteList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 6,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + `matter/${matterId}/note`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&isAdmin=${
      this.userData.isAdmin
    }&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  /**
   * Delete Note
   * @param matterNoteId
   */
  public deleteNote(matterNoteId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `matter/${matterNoteId}/note`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  getNoteById(noteId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "matter/" +
      noteId +
      "/notedetail";
    return this.httpClient.get<any>(api);
  }

  /** **********
   * NOTES
   ************/

  public createExpense(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.post<any>(url, values);
  }

  /** **********
   * TIME SHEETS
   ************/
  public getTimeSheets(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `expense/${matterId}/Timesheets`;
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  // expense/{matterId}/Timesheets expense/TimeSheets

  deleteTimeSheet(timeSheetId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `expense/${timeSheetId}/TimeSheets`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  /** **********
   * JUDGEMENT
   ************/
  public getJudgementList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `matter/${matterId}/judgement`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  /** **********
   * TIME SHEETS
   ************/

  public deleteJudgement(judgementId: number) {
    const api =
      this.config.getConfig().serverEndpoint + `matter/${judgementId}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  /** **********
   * JUDGEMENT matter/{judgementId}
   ************/
  public briefGetMatterDetails(id: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${id}`;
    return this.httpClient.get<any>(url, id);
  }

  public briefEditDetails(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter";
    return this.httpClient.put<any>(url, values);
  }

  // tslint:disable-next-line: max-line-length
  public getExpensesList(
    matterId: number,
    searchQuery = "",
    sort = "",
    order = "",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `expense/${matterId}/Expenses`;
    // tslint:disable-next-line: max-line-length
    const api = `${endpoint}?searchQuery=${searchQuery}&isAdmin=${
      this.userData.isAdmin
    }&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  public deleteExpenses(expenseId: number) {
    const api =
      this.config.getConfig().serverEndpoint + `expense/${expenseId}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  public completeBriefDetails(id: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/complete/${id}`;
    return this.httpClient.patch<any>(url, id);
  }

  public unCompleteBriefDetails(id: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/Uncomplete/${id}`;
    return this.httpClient.patch<any>(url, id);
  }

  public newCategorySubType(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/categorysubtype";
    return this.httpClient.post<any>(url, values);
  }

  public deleteBrief(matterId: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    return this.httpClient.delete<any>(url, matterId);
  }

  public editExpense(values: any): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "expense/matter";
    return this.httpClient.put<any>(url, values);
  }

  public updateTeamMember(userId, roleId, teamId: number) {
    const url =
      this.config.getConfig().serverEndpoint + "matter/member/team";
    return this.httpClient.put(url, {
      firmUserId: userId,
      firmRoleId: roleId,
      teamId: teamId
    });
  }

  public deleteTeamMemeber(payload: MatterTeamMember, teamId: number) {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/team/${teamId}/member/${payload.id}`;
    return this.httpClient.delete(url);
  }

  public deleteMatter(matterId: number): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + `matter/${matterId}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  public newClientReport(report: any) {
    const url = this.config.getConfig().serverEndpoint + "ClientReport";
    return this.httpClient.post(url, report);
  }

  public addSubCategory(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/categorytype";
    return this.httpClient.post(url, values);
  }

  // categorytype

  public stepDownProceeding(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/appearance/date";
    return this.httpClient.put(url, values);
  }
  public addPractise(values: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "matter/categorysubtype";
    return this.httpClient.post(url, values);
  }

  public archiveStatus(matterID: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/Archive/${matterID}`;
    return this.httpClient.patch(url, matterID);
  }
  public closeMatter(matterID: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/close/${matterID}`;
    return this.httpClient.patch(url, matterID);
  }
  public openMatter(matterID: any): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `matter/open/${matterID}`;
    return this.httpClient.patch(url, matterID);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  // public archiveStatus(id: any): Observable<any> {
  //   const url = this.config.getConfig().serverEndpoint + `matter/complete/${id}`;
  //   return this.httpClient.patch<any>(url, id);
  // }
}
