import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { FileViewModel } from "../../../core/models/view-model/file";
import { FileUploadService } from "../../../core/services/file-upload.service";

@Component({
  selector: "app-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.scss"]
})
export class FileListComponent implements OnInit, OnChanges {
  @Input() files: FileViewModel[] = [];

  @ViewChild("downloadBtn") downloadBtn: ElementRef;
  downloadUrl: "";

  existingDocument: FileViewModel[] = [];

  constructor(private _fileUploadService: FileUploadService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    console.log(changes);

    this.existingDocument = this.files;
  }

  download(document: FileViewModel) {
    console.log(document);

    this._fileUploadService
      .downloadNonAsync(document.id, document.itemTag)
      .toPromise()
      .then((result) => {
        this.downloadUrl = result.serverFileName;
        console.log(result);

        if (this.downloadUrl !== "") {
          console.log(this.downloadUrl);
          window.open(this.downloadUrl, "_blank");
        }
      });
  }

  closeFileItem(document: FileViewModel, index) {
    // delete file

    console.log(document);

    const url = "";

    this._fileUploadService
      .deleteNonAsync(document.id, document.itemTag)
      .toPromise()
      .then((result) => {
        console.log(url);
        this.existingDocument.splice(index, 1);
      });
  }
}
