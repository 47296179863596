import { Component, OnInit } from "@angular/core";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";
import { PricingService } from "../../../core/services/pricing.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"]
})
export class PricingComponent implements OnInit {
  valuePerMonth: number;
  currentUser = new UserDetail();

  constructor(
    private pricingService: PricingService,
    private currentUserService: CurrentUserService
  ) {
    this.currentUserService.getuserProfile().subscribe(
      (res) => {
        this.currentUser = res;
      },
      (err) => {
        console.log(err);
        this.currentUser = null;
      }
    );
  }

  ngOnInit() {
    this.pricingService
      .getMonthlyValue()
      .subscribe((monthlyValueResponse) => {
        this.valuePerMonth = monthlyValueResponse;
      });
  }

  goToLink(url) {
    window.open(url, "_blank");
  }
}
