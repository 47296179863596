import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-registration-success",
  templateUrl: "./registration-success.component.html",
  styleUrls: ["./registration-success.component.scss"]
})
export class RegistrationSuccessComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
