import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AddDocumentFolderModel } from "../../../core/models/view-model/document-folder";

@Component({
  selector: "app-new-folder",
  templateUrl: "./new-folder.component.html",
  styleUrls: ["./new-folder.component.scss"]
})
export class NewFolderComponent implements OnInit, OnChanges {
  newFolderName = new AddDocumentFolderModel();
  @Input() publicBtnSpinner: boolean = false;
  btnSpinner: boolean;
  @ViewChild("close_modal_addFolder") close_modal_addFolder: ElementRef;
  @Output() createFolder = new EventEmitter();

  folderGroup: FormGroup;

  constructor(private fb: FormBuilder) {
    this.folderGroup = this.fb.group({
      folderName: ["", Validators.required]
    });
  }

  get folderName() {
    return this.folderGroup.get("folderName");
  }

  ngOnChanges(changes) {
    if (this.publicBtnSpinner) {
      this.btnSpinner = true;
      this.close_modal_addFolder.nativeElement.click();
      this.folderName.reset();
    }
  }

  addFolder() {
    this.btnSpinner = true;

    this.newFolderName.folderName = this.folderGroup.value.folderName;
    this.createFolder.emit(this.folderName.value);
  }

  ngOnInit() {
  }
}
