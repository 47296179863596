import { Action } from "@ngrx/store";

// [AUTH] Auth module
export enum UserHeaderActionTypes {
  SHOW_TIMER = "[USERHEADER] SHOW_TIMER",
  SHOW_TIMER_SUCCESS = "[USERHEADER] SHOW_TIMER_LOGIN_SUCCESS",
  SHOW_TIMER_FAIL = "[USERHEADER] SHOW_TIMER_FAIL",
  HIDE_TIMER = "[USERHEADER] HIDE_TIMER",
  HIDE_TIMER_SUCCESS = "[USERHEADER] HIDE_TIMER_LOGIN_SUCCESS",
  HIDE_TIMER_FAIL = "[USERHEADER] HIDE_TIMER_FAIL",
  SELECT_CURRENT_MATTER = "[USERHEADER] SELECT_CURRENT_MATTER",
  DESELECT_CURRENT_MATTER = "[USERHEADER] DESELECT_CURRENT_MATTER",
  PUSH_NOTIFICATION = "[USERHEADER] PUSH_NOTIFICATION",
  PUSH_NOTIFICATION_SUCCESS = "[USERHEADER] PUSH_NOTIFICATION_FAIL",
  PUSH_NOTIFICATION_FAIL = "[USERHEADER] PUSH_NOTIFICATION_FAIL",
  SEND_ERROR = "[USERHEADER] SEND_ERROR",
  SET_INTERNET_AVAILABLE = "[USERHEADER] SET_INTERNET_AVAILABLE",
  SET_NOTIFICATION_COUNT = "[USERHEADER] SET_NOTIFICATION_COUNT",
  CLEAR_FROM_NOTIFICATION_COUNT = "[USERHEADER] CLEAR_FROM_NOTIFICATION_COUNT",
  // SetInternetNotAvailable
}

export class ShowTimer implements Action {
  readonly type = UserHeaderActionTypes.SHOW_TIMER;
}

export class ShowTimerSuccess implements Action {
  readonly type = UserHeaderActionTypes.SHOW_TIMER_SUCCESS;
  // constructor(public payload: boolean ) {}
}

export class ShowTimerFail implements Action {
  readonly type = UserHeaderActionTypes.SHOW_TIMER_FAIL;

  constructor(public payload: string) {
  }
}

export class SelectCurrentMatter implements Action {
  readonly type = UserHeaderActionTypes.SELECT_CURRENT_MATTER;

  constructor(public payload: number) {
  }
}

export class SendError implements Action {
  readonly type = UserHeaderActionTypes.SEND_ERROR;

  constructor(public payload: string) {
  }
}

export class SetInternetAvailable implements Action {
  readonly type = UserHeaderActionTypes.SET_INTERNET_AVAILABLE;

  constructor(public payload: string) {
  }
}

export class DeselectCurrentMatter implements Action {
  readonly type = UserHeaderActionTypes.DESELECT_CURRENT_MATTER;
}

export class HideTimer implements Action {
  readonly type = UserHeaderActionTypes.HIDE_TIMER;
}

export class HideTimerSuccess implements Action {
  readonly type = UserHeaderActionTypes.HIDE_TIMER_SUCCESS;
  // constructor(public payload: boolean ) {}
}

export class HideTimerFail implements Action {
  readonly type = UserHeaderActionTypes.HIDE_TIMER_FAIL;

  constructor(public payload: string) {
  }
}

export class PushNotification implements Action {
  readonly type = UserHeaderActionTypes.PUSH_NOTIFICATION;

  constructor(public payload: string) {
  }
}

export class PushNotificationSuccess implements Action {
  readonly type = UserHeaderActionTypes.PUSH_NOTIFICATION_SUCCESS;
}

export class PushNotificationFail implements Action {
  readonly type = UserHeaderActionTypes.PUSH_NOTIFICATION_FAIL;
}

export class SetNotificationCount implements Action {
  readonly type = UserHeaderActionTypes.SET_NOTIFICATION_COUNT;

  constructor(public payload: number) {
  }
}

export class ClearFromNotificationCount implements Action {
  readonly type = UserHeaderActionTypes.CLEAR_FROM_NOTIFICATION_COUNT;

  constructor(public payload: number) {
  }
}

export type UserHeaderActionsUnion =
  | ShowTimer
  | ShowTimerSuccess
  | ShowTimerFail
  | HideTimer
  | HideTimerSuccess
  | HideTimerFail
  | SelectCurrentMatter
  | DeselectCurrentMatter
  | PushNotification
  | PushNotificationSuccess
  | SendError
  | SetInternetAvailable
  | PushNotificationFail
  | ClearFromNotificationCount
  | SetNotificationCount;
