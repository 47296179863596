export class InviteToken {
  firstName: string;
  lastName: string;
  inviteeEmail: string;
  firmName: string;
  token: string;
  isUserAlreadyExistOnPlatform: boolean;
  firmRoleId?: number;
  hourlyRate?: number;
}

export const mockTokenObj: InviteToken = {
  firstName: "victory",
  lastName: "oki",
  firmName: "victorysplogi and co",
  inviteeEmail: "victorysplogi@gmail.com",
  token: "30dipsof5hieldequid",
  isUserAlreadyExistOnPlatform: false
};
