import { Action } from "@ngrx/store";
import { CalendarView } from "angular-calendar";
import { CalendarViewModel } from "../../models/view-model/calendar-event";

export enum CalendarActionTypes {
  CHANGE_CALENDAR_VIEW = "[CALENDAR] CHANGE_CALENDAR_VIEW",
  // reminders
  LOAD_CALENDAR_EVENTS = "[CALENDAR] LOAD_CALENDAR_EVENTS",
  LOAD_CALENDAR_EVENTS_SUCCESS = "[CALENDAR] LOAD_CALENDAR_EVENTS_SUCCESS",
  LOAD_CALENDAR_EVENTS_FAIL = "[CALENDAR] LOAD_CALENDAR_EVENTS_FAIL",

  // task
  LOAD_CALENDAR_EVENTS_TASK = "[CALENDAR] LOAD_CALENDAR_EVENTS_TASK",
  LOAD_CALENDAR_EVENTS_TASK_SUCCESS = "[CALENDAR] LOAD_CALENDAR_EVENTS_TASK_SUCCESS",
  LOAD_CALENDAR_EVENTS_TASK_FAIL = "[CALENDAR] LOAD_CALENDAR_EVENTS_TASK_FAIL",

  // endorsements
  LOAD_CALENDAR_EVENTS_PROCEEDING = "[CALENDAR] LOAD_CALENDAR_EVENTS_PROCEEDING",
  LOAD_CALENDAR_EVENTS_PROCEEDING_SUCCESS = "[CALENDAR] LOAD_CALENDAR_EVENTS_PROCEEDING_SUCCESS",
  LOAD_CALENDAR_EVENTS_PROCEEDING_FAIL = "[CALENDAR] LOAD_CALENDAR_EVENTS_PROCEEDING_FAIL",

  // application
  LOAD_CALENDAR_EVENTS_APPLICATION = "[CALENDAR] LOAD_CALENDAR_EVENTS_APPLICATION",
  LOAD_CALENDAR_EVENTS_APPLICATION_SUCCESS = "[CALENDAR] LOAD_CALENDAR_EVENTS_APPLICATION_SUCCESS",
  LOAD_CALENDAR_EVENTS_APPLICATION_FAIL = "[CALENDAR] LOAD_CALENDAR_EVENTS_APPLICATION_FAIL",

  ADD_CALENDAR_EVENTS = "[CALENDAR] ADD_CALENDAR_EVENTS",
  ADD_CALENDAR_EVENTS_SUCCESS = "[CALENDAR] ADD_CALENDAR_EVENTS_SUCCESS",
  ADD_CALENDAR_EVENTS_FAIL = "[CALENDAR] ADD_CALENDAR_EVENTS_FAIL",

  PUSH_TO_CALENDAR_EVENTS = "[CALENDAR] PUSH_TO_CALENDAR_EVENTS",
}

export class ChangeCalendar implements Action {
  readonly type = CalendarActionTypes.CHANGE_CALENDAR_VIEW;

  constructor(public payload: CalendarView) {
  }
}

export class LoadCalendarEvents implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS;

  constructor(public payload: { month: number; year: number }) {
  }
}

export class LoadCalendarEventsSuccess implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_SUCCESS;

  constructor(public payload: CalendarViewModel[]) {
  }
}

export class LoadCalendarEventsFail implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_FAIL;

  constructor(public payload: string) {
  }
}

export class LoadCalendarEventsTask implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_TASK;

  constructor(public payload: { month: number; year: number }) {
  }
}

export class LoadCalendarEventsTaskSuccess implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_TASK_SUCCESS;

  constructor(public payload: CalendarViewModel[]) {
  }
}

export class LoadCalendarEventsTaskFail implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_TASK_FAIL;

  constructor(public payload: string) {
  }
}

export class LoadCalendarEventsProceeding implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_PROCEEDING;

  constructor(public payload: { month: number; year: number }) {
  }
}

export class LoadCalendarEventsProceedingSuccess implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_PROCEEDING_SUCCESS;

  constructor(public payload: CalendarViewModel[]) {
  }
}

export class LoadCalendarEventsProceedingFail implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_PROCEEDING_FAIL;

  constructor(public payload: string) {
  }
}

export class LoadCalendarEventsApplication implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_APPLICATION;

  constructor(public payload: { month: number; year: number }) {
  }
}

export class LoadCalendarEventsApplicationSuccess implements Action {
  readonly type =
    CalendarActionTypes.LOAD_CALENDAR_EVENTS_APPLICATION_SUCCESS;

  constructor(public payload: CalendarViewModel[]) {
  }
}

export class LoadCalendarEventsApplicationFail implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDAR_EVENTS_APPLICATION_FAIL;

  constructor(public payload: string) {
  }
}

export class PushToCalendar implements Action {
  readonly type = CalendarActionTypes.PUSH_TO_CALENDAR_EVENTS;

  constructor(public payload: CalendarViewModel) {
  }
}

export class AddCalendarEvent implements Action {
  readonly type = CalendarActionTypes.ADD_CALENDAR_EVENTS;

  constructor(public payload: CalendarViewModel) {
  }
}

export class AddCalendarEventSuccess implements Action {
  readonly type = CalendarActionTypes.ADD_CALENDAR_EVENTS_SUCCESS;

  constructor(public payload: number) {
  }
}

export class AddCalendarEventFail implements Action {
  readonly type = CalendarActionTypes.ADD_CALENDAR_EVENTS_FAIL;

  constructor(public payload: string) {
  }
}

export type CalendarActionsUnion =
  | ChangeCalendar
  | AddCalendarEventFail
  | AddCalendarEventSuccess
  | AddCalendarEvent
  | LoadCalendarEventsFail
  | LoadCalendarEventsSuccess
  | LoadCalendarEvents
  | LoadCalendarEventsTask
  | LoadCalendarEventsTaskSuccess
  | LoadCalendarEventsTaskFail
  | LoadCalendarEventsProceeding
  | LoadCalendarEventsProceedingSuccess
  | LoadCalendarEventsProceedingFail
  | LoadCalendarEventsApplication
  | LoadCalendarEventsApplicationSuccess
  | LoadCalendarEventsApplicationFail
  | PushToCalendar;
