import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { CalendarViewModel } from "../models/view-model/calendar-event";
import { UserDetail } from "../models/view-model/user-detail";
import * as fromStore from "../store";

@Injectable({
  providedIn: "root"
})
export class CalendarService {
  user = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
    // private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>
  ) {
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((myUser) => {
      console.log(myUser);
      this.user = myUser;
    });
  }

  public addCalendarEvent(payload: CalendarViewModel) {
    const url =
      this.config.getConfig().serverEndpoint + "task/singlereminder";
    return this.httpClient.post(url, payload);
  }

  public loadUserReminderCalendar(
    month: number = 0,
    year: number
  ): Observable<CalendarViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `Dashboard/${month}/${year}/userremindercalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadDashboardFirmUpcomingEvents(): Observable<CalendarViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint + "Dashboard/Upcomingevents";
    return this.httpClient
      .get<CalendarViewModel[]>(url)
      .pipe(catchError(this.handleError));
    // return of (dashboardOverviewSample);
  }

  public loadUserCalendar(
    month: number = 0,
    year: number
  ): Observable<CalendarViewModel[]> {
    let url = "";

    if (this.user.isAdmin) {
      url =
        this.config.getConfig().serverEndpoint +
        `Dashboard/${month}/${year}/firmgeneralcalender`;
    } else {
      url =
        this.config.getConfig().serverEndpoint +
        `Dashboard/${month}/${year}/usergeneralcalender`;
    }

    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadCourtProceedingCalendar(
    month: number = 0,
    year: number
  ): Observable<CalendarViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `Dashboard/${month}/${year}/courtproceedingcalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadFirmApplicationCalendar(
    month: number = 0,
    year: number
  ): Observable<CalendarViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `Dashboard/${month}/${year}/firmapplicationcalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public loadUserTaskCalendar(
    month: number = 0,
    year: number
  ): Observable<CalendarViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `Dashboard/${month}/${year}/userTaskCalender`;
    return this.httpClient.get<CalendarViewModel[]>(url);
  }

  public deleteCalendarReminder(reminderId) {
    const url =
      this.config.getConfig().serverEndpoint +
      `task/singlereminder/${reminderId}`;
    return this.httpClient.delete<any>(url);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
