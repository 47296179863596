import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CalendarResolverService } from "./core/resolver/calendar-resolver.service";
import { ConfirmAccountComponent } from "./views/auth/confirm-account/confirm-account.component";
import { EmailConfirmationComponent } from "./views/auth/email-confirmation/email-confirmation.component";
import { FirmRegistrationComponent } from "./views/auth/firm-registration/firm-registration.component";
import { ForceLogoutComponent } from "./views/auth/force-logout/force-logout.component";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";
import { LoginComponent } from "./views/auth/login/login.component";
import { LogoutComponent } from "./views/auth/logout/logout.component";
import { NewInvitedUserComponent } from "./views/auth/new-invited-user/new-invited-user.component";
import { RegistrationSuccessComponent } from "./views/auth/registration-success/registration-success.component";
import { ResetPasswordComponent } from "./views/auth/reset-password/reset-password.component";
import { HomepageComponent } from "./views/home/homepage/homepage.component";
import { HomeGuard } from "./views/shared/guards/home-guard.service";
import { LicenseGuard } from "./views/shared/guards/license-guard.service";
import { MainGuard } from "./views/shared/guards/main-guard.service";
import { LicensePaymentComponent } from "./views/shared/license-payment/license-payment.component";
// import { Eror404Component } from "./error-pages/eror404/eror404.component";

const routes: Routes = [
  {
    path: "",
    component: HomepageComponent
    // loadChildren: './views/home/home.module#HomeModule',
    // pathMatch: 'full'
  },

  {
    path: "firm",
    // component: DashboardComponent,
    loadChildren: "./views/user-dashboard/user-dashboard.module#UserDashboardModule",
    //  pathMatch: 'full',
    canLoad: [MainGuard, LicenseGuard],
    resolve: {activities: CalendarResolverService}
  },

  {path: "login", component: LoginComponent, canActivate: [HomeGuard]},
  {path: "logout", component: LogoutComponent},
  {path: "logout/:email", component: ForceLogoutComponent},
  {path: "confirm-email/:email", component: ConfirmAccountComponent},
  {path: "email-confirmed", component: EmailConfirmationComponent},
  {
    path: "login/:email",
    component: LoginComponent,
    canActivate: [HomeGuard]
  },
  {path: "register", component: FirmRegistrationComponent},
  {path: "forgot-password", component: ForgotPasswordComponent},
  {path: "join", component: NewInvitedUserComponent},
  {path: "reset-password", component: ResetPasswordComponent},
  {path: "join-successfull", component: RegistrationSuccessComponent},
  {path: "license-payment", component: LicensePaymentComponent},
  {path: "draft", loadChildren: "./views/lp-doc/lp-doc.module#LpDocModule"},
  {
    path: "manage-user",
    loadChildren:
      "./views/user-management/user-management.module#UserManagementModule",

    canLoad: [MainGuard]
  },

  {
    path: "mailbox",
    loadChildren: "./views/mailbox/mailbox.module#MailboxModule",
    canLoad: [MainGuard]
  },

  {
    path: "new-matter",
    loadChildren:
      "./views/form-widgets/form-widgets.module#FormWidgetsModule",
    canLoad: [MainGuard]
  },

  {
    path: "**",
    loadChildren: "./views/error-pages/error-pages.module#ErrorPagesModule"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
