import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { MatterService } from "../../../core/services/matter.service";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../../shared/base-class/notifier";
import { UtilsService } from "../services/util.service";

@Component({
  selector: "app-new-expense",
  templateUrl: "./new-expense.component.html",
  styleUrls: ["./new-expense.component.scss"]
})
export class NewExpenseComponent extends Notifier implements OnInit {
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  expenseForm: FormGroup;
  btnSpinner: boolean;
  showResourceNotSelectedError: boolean;
  matterId: number;
  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("closeModal") closeModal: ElementRef;
  @Output() isSuccess = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private util: UtilsService,
    private route: ActivatedRoute,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private Service: MatterService
  ) {
    super(headerStore);
    this.expenseForm = fb.group({
      name: ["", Validators.required],
      desc: ["", Validators.required],
      date: ["", Validators.required],
      price: ["", Validators.pattern("^[0-9]+(.[0-9]{1,2})?$")],
      qty: ["", Validators.pattern("^[0-9]+(.[0-9]{1,2})?$")]
    });
  }

  get expenseName() {
    return this.expenseForm.get("name");
  }

  get expenseDesc() {
    return this.expenseForm.get("desc");
  }

  get expenseDate() {
    return this.expenseForm.get("date");
  }

  get expensePrice() {
    return this.expenseForm.get("price");
  }

  get expenseQty() {
    return this.expenseForm.get("qty");
  }

  ngOnInit() {
    this.matterId = this.route.snapshot.params["matterId"];
    this.showResourceNotSelectedError = false;
    this.isSuccess.emit(false);
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }

  closeError() {
    this.showErrorMessage = false;
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      // this.newTaskForm.patchValue(
      //   {
      //     matterId: matterId
      //   }
      // )

      console.log(resourceId);

      this.matterId = resourceId;
    }

    console.log(this.matterId);
  }

  createExpense() {
    this.btnSpinner = true;

    if (this.matterId > 0) {
    } else {
      this.showResourceNotSelectedError = true;
      return;
    }
    const calcAmount =
      Number(this.expensePrice.value) * Number(this.expenseQty.value);
    const sendExpense = {
      amount: calcAmount,
      matterId: this.matterId,
      narration: this.expenseDesc.value,
      title: this.expenseName.value,
      dateRecorded: +new Date(this.expenseDate.value),
      quantity: this.expenseQty.value,
      price: this.expensePrice.value
    };

    console.log(sendExpense);

    this.Service.createExpense(sendExpense).subscribe(
      (data) => {
        if (data > 0) {
          this.pushMessage(
            "Expense, <strong>" +
            sendExpense.title +
            "</strong> Was saved successfully"
          );
          this.isSuccess.emit(true);
          this.btnSpinner = false;
          this.expenseForm.reset();
          this.closeModal.nativeElement.click();
        }
        // this.showSuccessMessage = true;
        // this.successText = `Your expense, has been successfully added`;
      },
      (error) => {
        this.pushMessage(
          "Adding Expense, " + sendExpense.title + " failed"
        );
        this.showErrorMessage = true;
        this.isSuccess.emit(false);
        this.messageBox.nativeElement.focus();
        console.log(this.messageBox.nativeElement.focus());
        // this.errorText = `creating  expense failed`;
        this.btnSpinner = false;
      }
    );
  }

  goBack() {
    this.util.goBack();
  }
}
