import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { LocalStorageService } from "ngx-webstorage";
import { Observable, of, throwError } from "rxjs";
import { isNullOrUndefined } from "util";
import { AccountResponseModel } from "../../models/generic-model.ts/account-response";
import { EmailAccountTokenModel } from "../../models/generic-model.ts/email-client-token";
import { TokenParams } from "../../models/generic-model.ts/token-params";
import { PermissionViewModel } from "../../models/view-model/permission";
import { UserDetail } from "../../models/view-model/user-detail";

import * as fromStore from "../../store";

@Injectable({
  providedIn: "root"
})
export class CurrentUserService {
  currentUser = new UserDetail();

  constructor(
    private localStorage: LocalStorageService,
    private store: Store<fromStore.State>
  ) {
  }

  getuserProfile(): Observable<UserDetail> {
    const value = this.localStorage.retrieve("user_profile");
    if (value !== "" || " ") {
      let userData = new UserDetail();
      console.log(value);
      userData = JSON.parse(value);
      const email = this.localStorage.retrieve("user_email");
      const role = this.localStorage.retrieve("user_role");
      if (!isNullOrUndefined(role) && !isNullOrUndefined(email)) {
        userData.email = email;

        userData.role = role;
      } else {
        return throwError("user email ans role is not avalilable");
      }
      console.log(userData);
      return of(userData);
    } else {
      return throwError("an error occured");
    }
  }

  // isAdmin

  getToken() {
    const tk = this.localStorage.retrieve("token");
    console.log(tk);
    if (!isNullOrUndefined(tk)) {
      return this.localStorage.retrieve("token");
    }
    // return this.cookieService.get('token');
  }

  addToken(token: TokenParams, permissions: PermissionViewModel[]) {
    // we delete the existing first
    this.localStorage.clear("token");
    this.localStorage.clear("refresh_token");
    this.localStorage.clear("expires_in");
    this.localStorage.clear("perm_acc");

    // then add
    if (!isNullOrUndefined(token)) {
      this.localStorage.store("token", token.auth_token);
      this.localStorage.store("refresh_token", token.refresh_token);
      this.localStorage.store("expires_in", token.expires_in);
      this.localStorage.store("perm_acc", JSON.stringify(permissions));
    }
  }

  addRedirectUrl(url: string) {
    console.log(url);
    this.localStorage.store("redirect_url", url);
  }

  getRedirectUrl(): string {
    if (!isNullOrUndefined(this.localStorage.retrieve("redirect_url"))) {
      return this.localStorage.retrieve("redirect_url");
    } else {
      return "";
    }
  }

  removeRedirectUrl() {
    this.localStorage.clear("redirect_url");
  }

  isLoggedIn() {
    // const token = this.cookieService.get('token');
    const token = this.localStorage.retrieve("token");
    this.currentUser = JSON.parse(
      this.localStorage.retrieve("user_profile")
    );
    if (!isNullOrUndefined(token) && !isNullOrUndefined(this.currentUser)) {
      if (token.length > 0) {
        console.log(token.length);

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLicensed() {
    // const token = this.cookieService.get('token');
    const token = this.localStorage.retrieve("token");
    this.currentUser = JSON.parse(
      this.localStorage.retrieve("user_profile")
    );
    console.log(this.currentUser);
    console.log(token);
    if (!isNullOrUndefined(token) && !isNullOrUndefined(this.currentUser)) {
      if (this.currentUser.isLicensed) {
        console.log(token.length);

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // isAdminLoggedIn() {
  //   // const token = this.cookieService.get('token');
  //   const token = this.localStorage.retrieve('token');

  //   this.currentUser =  JSON.parse(this.localStorage.retrieve('user_profile'));
  //   console.log(this.currentUser);
  //   console.log(token);
  //   if ( (! isNullOrUndefined(token)) && (! isNullOrUndefined(this.currentUser))) {

  //     const  value: boolean = this.currentUser.isAdmin;

  //     if (token.length > 0 && value) {
  //     console.log(token.length);

  //     return true;
  //   }  else {
  //     return false;

  //   }
  //   }

  // }

  addUserProfile(userProfile) {
    this.localStorage.store("user_profile", JSON.stringify(userProfile));
    // emitted to
  }

  addEmail(email: string) {
    console.log(email);
    this.localStorage.store("user_email", email);
    // emitted to
  }

  addRole(role: string) {
    console.log(role);
    this.localStorage.store("user_role", role);
  }

  // savePermissions(userPermissions: UserMenuViewModel []) {
  //   this.localStorage.store('user_permits', JSON.stringify(userPermissions));
  // }

  // returns an array of permissions.
  // getUserPermissions() {
  //  const _userPermissions = this.localStorage.retrieve('user_permits');
  // let userPermissions: UserMenuViewModel [];
  //   userPermissions = JSON.parse(_userPermissions);
  //   return userPermissions;
  // }

  logout() {
    this.localStorage.clear("user_profile");
    this.localStorage.clear("token");
    this.localStorage.clear("user_permits");
    this.localStorage.clear("user_email");
    this.localStorage.clear("user_role");
  }

  clearPermission() {
    this.localStorage.clear("user_permits");
  }

  getUserId(): number {
    this.currentUser = JSON.parse(
      this.localStorage.retrieve("user_profile")
    );
    return this.currentUser.id;
  }

  // getUserFulName() {
  //   // this.currentUser = this.cookieService.get('user_profile') ? JSON.parse(this.cookieService.get('user_profile')) : null;
  //   this.currentUser =  JSON.parse(this.localStorage.retrieve('user_profile'));
  //   console.log(this.currentUser);
  // const  value = this.currentUser.surname + ' ' + this.currentUser.otherName;
  // // console.log(JSON.parse(this.localStorage.retrieve('user_profile')));
  // return value;
  //   // let value: string;
  //   // value = this.currentUser;
  //   // return  Observable.create(observer =>  {
  //   //   console.log('call');
  //   //   // expensive http request
  //   //  // observer.next(this.currentUser);
  //   // }).publishReplay(this.currentUser);
  // }

  // getBranchName() {
  //   this.currentUser = JSON.parse(this.localStorage.retrieve('user_profile'));
  //   const value = this.currentUser.branchName;
  //   return value;

  // }

  addEmailToken(res: AccountResponseModel) {
    let tkns: string[] = [];
    tkns = this.getAllEmailTokenStrings();

    // if(tkns.length > 0) {
    // CONVERT EACH TO STRING AND THEN PUSH TO ARRAY
    tkns.push(JSON.stringify(res));
    // }
    // STRINGIFY ARRAY AND THEN SAVE IN LOCAL STORAGE
    this.localStorage.store("user_emailAccounts", JSON.stringify(tkns));
  }

  getAllEmailTokenStrings(): string[] {
    let emailTokens: string[] = [];
    const ls_tkn = this.localStorage.retrieve("user_emailAccounts");
    if (!isNullOrUndefined(ls_tkn) && ls_tkn !== "") {
      emailTokens = JSON.parse(ls_tkn);
    }
    return emailTokens;
  }

  getArrayEmailTokenObject(): EmailAccountTokenModel[] {
    let result: EmailAccountTokenModel[] = [];

    let tokens: AccountResponseModel[] = [];

    let tokenStrings: string[] = [];

    tokenStrings = this.getAllEmailTokenStrings();
    if (tokenStrings.length > 0) {
      tokenStrings.forEach((tk) => {
        tokens.push(JSON.parse(tk));
      });

      tokens.forEach((t) => {
        const tokenItem = new EmailAccountTokenModel();
        tokenItem.unreadCount = t.unreadCount;
        tokenItem.token = JSON.parse(t.token);
        result.push(tokenItem);
      });
    }

    return result;
  }
}
