import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { FileUploadModel } from "../../../core/models/generic-model.ts/file-upload";
import { CommonUserModel } from "../../../core/models/generic-model.ts/user-share";
import { FileViewModel } from "../../../core/models/view-model/document-list";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import { FileUploadService } from "../../../core/services/file-upload.service";
import { TaskService } from "../../../core/services/task.service";
import * as TaskActions from "../../../core/store/actions/task.action";
import * as fromTask from "../../../core/store/reducers/task.reducer";
import { ModelFactory } from "../helpers/model-factory";
import { IAttachFile } from "../interfaces/attach-file";

@Component({
  selector: "app-view-task",
  templateUrl: "./view-task.component.html",
  styleUrls: ["./view-task.component.scss"]
})
export class ViewTaskComponent implements OnInit, OnChanges, IAttachFile {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentDocument: FileUploadModel[];
  existingDocument: FileViewModel[] = [];
  _modelFactory = new ModelFactory();
  entityId = 6;
  task = new UserTask();
  @Output() closeTask = new EventEmitter();
  @Output() closeDashboardTask = new EventEmitter();
  @Output() myMessgae = new EventEmitter<boolean>();
  @Output() updateUser = new EventEmitter<CommonUserModel[]>();

  @Output() taskCheckSuccess = new EventEmitter();

  @Input() taskTeamMembers$: Observable<CommonUserModel[]> = of([]);

  isViewTask = true;

  taskTeamMembers: CommonUserModel[] = [];

  constructor(
    private taskStore: Store<fromTask.State>,
    private _fileUploadService: FileUploadService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private taskService: TaskService
  ) {
    this.taskStore.pipe(select(fromTask.currentTask)).subscribe((data) => {
      this.task = data;
      this.taskTeamMembers = [];

      if (!isNullOrUndefined(this.task.id)) {
        this.taskService
          .getTaskDocuments(this.task.id)
          .subscribe((res) => {
            this.existingDocument = res.body;

            console.log(this.existingDocument);
          });
      } else {
        // when the detail view is reloaded, you want to get the task detail
        console.log(this.activatedRoute);
        const taskId = this.activatedRoute.snapshot.params["id"]; // number
        this.taskService.getTaskDetail(taskId).subscribe((res) => {
          console.log(res);
          this.task = res;
        });

        this.taskService.getTaskDocuments(taskId).subscribe((res) => {
          this.existingDocument = res.body;

          console.log(this.existingDocument);
        });
      }

      console.log(this.task);

      // get task team members

      if (!isNullOrUndefined(this.task.taskUsers)) {
        this.task.taskUsers.forEach((taskUser) => {
          console.log(this.task);

          let caster: any;
          caster = taskUser;
          let commonUser = new CommonUserModel();
          commonUser = caster;

          this.taskTeamMembers.push(commonUser);

          console.log(this.taskTeamMembers);
        });
      }
    });

    taskStore
      .pipe(select(fromTask.completeTaskSuccess))
      .subscribe((res) => {
        if (!isNullOrUndefined(res)) {
          this.taskCheckSuccess.emit(this.task);
        }
      });
  }

  ngOnInit() {
    this.errorText = "";
    this.showError = false;
    this.fileProgressArray = [];
    this.currentDocument = [];
  }

  getviewattachedObject(uploadedObject: any) {
    this.currentDocument = [];
    this.errorText = uploadedObject.errorText;
    this.showError = uploadedObject.showError;
    this.fileProgressArray = uploadedObject.fileProgressArray;
    this.currentDocument = uploadedObject.docs;
    console.log(uploadedObject);
    console.log(this.currentDocument);

    let files: any[] = [];

    this.currentDocument.forEach((item) => {
      let formData: any = {};
      formData.fileModel = item;
      formData.fileType = this.entityId;
      formData.resourceId = this.task.id;

      files.push(formData);
    });

    if (!this.showError || isNullOrUndefined(this.showError)) {
      this._fileUploadService
        .uploadNonAsync(files)
        .toPromise()
        .then((res) => {
          this.currentDocument.forEach((file) => {
            const doc = this._modelFactory.Create(file);

            this.existingDocument.push(doc);
          });
        });
    }
  }

  closeDialog() {
    this.closeTask.emit("true");
    this.closeDashboardTask.emit("true");
    console.log("close ");

    this.router.navigate(["/firm/tasks"]);
  }

  show() {
    console.log(this.task);
  }

  ngOnChanges(changes) {
    console.log(changes);

    console.log(this.taskTeamMembers$);
    console.log(this.task);

    if (this.taskTeamMembers$) {
      this.taskTeamMembers$.subscribe((users) => {
        // list of users of new users to send to the API

        const updatedtaskmemebers: any[] = [];

        // for each task users after change
        users.forEach((member) => {
          // check if the user exist in the list of currently displayed users

          if (
            isNullOrUndefined(
              this.taskTeamMembers.find((c) => c.id === member.id)
            )
          ) {
            const taskMember: any = {
              firmUserId: member.id,
              taskId: this.task.id
            };

            updatedtaskmemebers.push(taskMember);
          }
        });

        if (updatedtaskmemebers.length > 0) {
          // send task memebers to endpoint
          this.taskService
            .assignTaskToUser(updatedtaskmemebers)
            .toPromise()
            .then((res) => {
              users.forEach((u) => {
                if (
                  isNullOrUndefined(
                    this.taskTeamMembers.find(
                      (c) => c.id === u.id
                    )
                  )
                ) {
                  this.taskTeamMembers.push(u);
                }
              });
            })
            .catch((err) => {
              // this.pushMessage(`Error assigning users to <strong>${this.createNewTask.name}</strong> task,  Failed`);
            });
        }
      });
    }
  }

  completeTask() {
    console.log(this.task);
    this.taskStore.dispatch(new TaskActions.CheckTask(this.task));
    this.closeTask.emit("true");
    this.closeDashboardTask.emit("true");
    this.myMessgae.emit(true);
  }

  removeMember(removedUser) {
    // invert the original selection
    // this.matterTeamMembers.find(data => data.userId === userId).selected =
    // !this.matterTeamMembers.find(data => data.userId === userId).selected;
    this.taskTeamMembers.splice(
      this.taskTeamMembers.indexOf(
        this.taskTeamMembers.find((data) => data.id === removedUser.id)
      ),
      1
    );

    this.updateUser.emit(this.taskTeamMembers);
  }
}
