import { UserDetailLiteModel } from "./user-detail-lite";

export class UserTask {
  id: number;
  name: string;
  dueDate: any;
  dueDateInNumber?: number;
  resourceId: number;
  resourceName: string;
  resourceType: string;
  isCompleted: boolean;
  tag: string;
  taskUsersCount: number;
  taskUsers: UserDetailLiteModel[];
  description: string;
}

export const userTaskListSample: UserTask[] = [];
