import { Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from "@angular/router";
import { Store } from "@ngrx/store";
import { fromEvent, Observable, of, Subscription } from "rxjs";
import * as emailClientActions from "./core/store/actions/email-client.actions";

import * as fromEmailClientStore from "./core/store/reducers/email-client.reducer";
import * as fromRoot from "./core/store/reducers/internet-connection.reducer";
import * as fromUserHeaderStore from "./core/store/reducers/user-header.reducer";
import { Notifier } from "./views/shared/base-class/notifier";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends Notifier implements OnInit, OnDestroy {
  pageTitle = "LawPavilion Court manager";
  showpageLoader$: Observable<boolean> = of(true);

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  @ViewChild("spinnerElement")
  spinnerElement: ElementRef;
  isOnline$: Observable<boolean>;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private store: Store<fromRoot.ConnectionState>,
    private emailClientStore: Store<fromEmailClientStore.State>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  clearRightClicks() {
    this.emailClientStore.dispatch(
      new emailClientActions.HideEmailAccountMenu()
    );
  }

  ngOnInit(): void {
    this.onlineEvent = fromEvent(window, "online");
    this.offlineEvent = fromEvent(window, "offline");

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.connectionStatusMessage = "You are Back online";
        this.connectionStatus = "online";
        console.log("Online...");
        this.setInternetAvailable(this.connectionStatusMessage);
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.connectionStatusMessage =
          "Connection lost! You are not connected to internet";
        this.connectionStatus = "offline";
        console.log("Offline...");
        this.setInternetNotAvailable(this.connectionStatusMessage);
      })
    );
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all subscriptions to avoid memory leak
     */
    this.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // We wanna run this function outside of Angular's zone to
      // bypass change detection
      this.ngZone.runOutsideAngular(() => {
        // For simplicity we are going to turn opacity on / off
        // you could add/remove a class for more advanced styling
        // and enter/leave animation of the spinner
        // this.renderer.setStyle(
        //   this.spinnerElement.nativeElement,
        //   'opacity',
        //   '1'
        // );
        this.showpageLoader$ = of(true);
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
      // setTimeout(() => this._hideSpinner(), 2000);
    }
    // Set loading state to false in both of the below requestEvents to
    // hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      // For simplicity we are going to turn opacity on / off
      // you could add/remove a class for more advanced styling
      // and enter/leave animation of the spinner
      this.showpageLoader$ = of(false);
    });
  }
}
