import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { GenericListViewModel } from "../../../core/models/generic-model.ts/generic-list";
import { FirmDetailViewModel } from "../../../core/models/view-model/firm-detail";
import { PermissionViewModel } from "../../../core/models/view-model/permission";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterService } from "../../../core/services/matter.service";
import { UserManagementService } from "../../../core/services/user-management.service";
import * as fromStore from "../../../core/store";

import * as emailClientActions from "../../../core/store/actions/email-client.actions";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromEmailClientStore from "../../../core/store/reducers/email-client.reducer";

@Component({
  selector: "app-primary-sidebar",
  templateUrl: "./primary-sidebar.component.html",
  styleUrls: ["./primary-sidebar.component.scss"]
})
export class PrimarySidebarComponent implements OnInit {
  showChildren: boolean;
  user = new UserDetail();
  userEmail: string;
  userRole: string;
  userProfilePicture = new Observable<string>();
  firstName: Observable<string>;
  lastName: Observable<string>;
  matterCategories: GenericListViewModel[] = [];
  theFirm = new FirmDetailViewModel();

  canViewInvoice = false;

  firmLogo = new Observable<string>();

  constructor(
    private store: Store<fromStore.State>,
    private emailClientStore: Store<fromEmailClientStore.State>,
    private matterService: MatterService,
    private editFirmService: UserManagementService
  ) {
    console.log("sdds");

    console.log("here");
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      console.log(data);
      this.user = data;
      console.log(this.user);
      this.firstName = of(this.user.firstName);
      this.lastName = of(this.user.lastName);
      if (
        !isNullOrUndefined(this.user.accountPermissions) &&
        this.user.accountPermissions.length > 0
      ) {
        const invoiceView: PermissionViewModel = this.user.accountPermissions.find(
          (c) => c.permissionEntityId === 1
        );
        if (!isNullOrUndefined(invoiceView)) {
          this.canViewInvoice = invoiceView.permissionAction.canView;
        }
      }
    });

    this.store
      .pipe(select(fromStore.getCurrentProfileImage))
      .subscribe((pic) => {
        if (!isNullOrUndefined(pic)) {
          console.log(pic);
          this.userProfilePicture = of(pic);
        }
      });

    this.store.pipe(select(fromStore.getFirmDetail)).subscribe((data) => {
      console.log(data);
      // check if firm detail ngrx state dat is not null

      this.theFirm = data;
      // check if the firm country is not null
    });

    this.store.pipe(select(fromStore.getUserRole)).subscribe((data) => {
      this.userRole = data;
    });

    this.store.pipe(select(fromStore.getUserEmail)).subscribe((data) => {
      this.userEmail = data;
    });

    this.store.pipe(select(fromStore.getFirmLogo)).subscribe((data) => {
      console.log(data);
      // check if firm detail ngrx state dat is not null
      if (!isNullOrUndefined(data)) {
        this.firmLogo = of(data);
      }

      // check if the firm country is not null
    });
  }

  ngOnInit() {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.showChildren = false;

    this.getMatterCategories();

    if (!isNullOrUndefined(this.user.userId)) {
      this.store.dispatch(
        new LoginActions.LoadProfilePicture(this.user.userId)
      );
    }

    // load firm detail
    this.store.dispatch(new LoginActions.Load(this.user.firmId));

    this.store.dispatch(new LoginActions.LoadFirmLogo());
  }

  getMatterCategories() {
    this.matterService.getMatterCategory().subscribe((data) => {
      console.log(data);
      this.matterCategories = data;
    });
  }

  clearRightClicks() {
    this.emailClientStore.dispatch(
      new emailClientActions.HideEmailAccountMenu()
    );
  }
}
