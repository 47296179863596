import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-page-loader",
  templateUrl: "./page-loader.component.html",
  styleUrls: ["./page-loader.component.scss"]
})
export class PageLoaderComponent implements OnInit {
  @Input() showLoader$: Observable<boolean>;

  constructor() {
  }

  ngOnInit() {
  }
}
