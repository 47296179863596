export class EmailClientTokenGenericModel {
  Token: string;
  ExpiresIn: string;
  ConfigId: number;
  Email: string;
}

export class EmailAccountTokenModel {
  unreadCount: number;
  token: EmailClientTokenGenericModel;
}
