import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { CurrentUserService } from "../services/factories/current-user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  mytoken: string;
  authReq: any;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private injector: Injector
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.mytoken = this.currentUserService.getToken();

    console.log(this.mytoken);
    console.log("request interceptor is ON");
    console.log(req); // you have to use http
    // this.mytoken = this.currentUserService.getToken();

    if (req.url.indexOf("login") > 0) {
      const loginHeader = new HttpHeaders({
        "Content-Type": "application/json"
      });

      // 'Content-Type':
      // 'application/x-www-form-urlencoded',

      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, OPTIONS'
      const requ = req.clone({headers: loginHeader});
      return next.handle(requ);
    } else {
      // console.log(this.mytoken);
      const auth = "Bearer " + this.mytoken; // get the token from the cookie coming from the cookie service
      const authRequest = req.clone({
        headers: req.headers
          .set("Authorization", auth)
          .set("Cache-Control", "no-cache") // to solve IE issue with caching get Requests
          .set("Pragma", "no-cache") // to solve IE issue with caching get Requests
      });
      return next.handle(authRequest).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.router.navigate(["logout"]);
              }
            }
          }
        )
      );
      // .pipe(
      //   catchError(error => {
      //     let router = this.injector.get(Router);
      //     // let _common = this.injector.get(CommonService);
      //     let url = router.url
      //     console.log('URL: ' + router.url);
      //     // Backend returns unsuccessful response codes such as 404, 500 etc
      //     if (error instanceof ErrorEvent) {
      //       console.log('entring here');
      //       // A client-side or network error occurred. Handle it accordingly.
      //       console.error('An error occurred:', error.error.message);
      //     } else {
      //       // The backend returned an unsuccessful response code.
      //       // The response body may contain clues as to what went wrong,
      //       let data = {};
      //       data = {
      //         url: router.url,
      //         reason: error && error.error.reason ? error.error.reason : '',
      //         status: error.status
      //       };
      //       console.error(data);
      //     }
      //     // return an observable with a user-facing error message
      //     return throwError(
      //       'Something bad happened; please try again later.');
      //   }))
    }

    // if (req.url.indexOf('/try') > 0) {
    //   const tryHeader = new HttpHeaders({'Accept': 'application/json'});
    //   console.log('skipped interceptor');
    //   const requ = req.clone({headers: tryHeader});
    //   return next.handle(requ);
    // }
  }
}
