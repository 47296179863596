import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CalendarViewModel } from "../../../core/models/view-model/calendar-event";

@Component({
  selector: "app-calendar-modal",
  templateUrl: "./calendar-modal.component.html",
  styleUrls: ["./calendar-modal.component.scss"]
})
export class CalendarModalComponent implements OnInit, OnChanges {
  @Input() clickedEvents: CalendarViewModel[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clickedEvents = changes.clickedEvents.currentValue;
  }
}
