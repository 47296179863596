import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Token } from "../models/generic-model.ts/token";
import { UserLoginDetail } from "../models/parse-model/login";
import { Register } from "../models/parse-model/register";
import { RegisterInvitedUser } from "../models/parse-model/register-invited-user";
import { Endpoints } from "../models/shared-model/endpoint";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  public signIn(user: UserLoginDetail): Observable<any> {
    console.log(user);
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.loginEndPoint;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.post<any>(requestUrl, user, {
      observe: "response"
    });
  }

  public signOut(email: string): Observable<any> {
    console.log(email);
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.config.getConfig().serverEndpoint + `account/${email}/logout`;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.get<any>(requestUrl);
  }

  public validateEmail(email) {
    // yet to complete endpoint url
    const requestUrl =
      this.config.getConfig().serverEndpoint +
      `account/validate-email/${email}`;
    console.log(requestUrl);
    return this.httpClient.get<any>(requestUrl);
  }

  public register(user: Register) {
    // yet to complete endpoint url
    console.log(user);
    const requestUrl =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.registerEndpoint;
    return this.httpClient
      .post(requestUrl, user)
      .pipe(catchError(this.handleError));
  }

  public confirmEmail(token: string) {
    const requestUrl =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.confirmEmailEndPoint +
      "?token=" +
      token;
    return this.httpClient.get<Observable<any>>(requestUrl);
  }

  public resendConfirmation(email: string) {
    const requestUrl =
      this.config.getConfig().serverEndpoint + "firm/ResendComfirmation";
    return this.httpClient.post<Observable<any>>(requestUrl, {
      email: email
    });
  }

  public inviteeRegister(user: RegisterInvitedUser) {
    console.log(user);
    // yet to complete endpoint url
    const requestUrl =
      this.config.getConfig().serverEndpoint + this.Endpoints.addfirmUser;
    return this.httpClient.post(requestUrl, user);
  }

  public forgotPassword(email: string): Observable<any> {
    const requestUrl =
      this.config.getConfig().serverEndpoint + "account/forgot-password";
    return this.httpClient.post<Token>(requestUrl, {email: email});
  }

  public resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
    email: string
  ): Observable<boolean> {
    const requestUrl =
      this.config.getConfig().serverEndpoint + "account/reset-password";
    return this.httpClient.post<boolean>(requestUrl, {
      email: email,
      password: password,
      token: token,
      confirmPassword: confirmPassword
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
