import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { LicensingService } from "../../../core/services/licensing.service";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromStore from "../../../core/store/reducers/calendar-lander.reducer";

import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../../shared/base-class/notifier";
import { CompletePayment } from "../helpers/complete-payment";
import { PaystackOptions, PlanDetails } from "../helpers/paystack-options";

interface MyWindow extends Window {
  PaystackPop: any;
}

declare let window: MyWindow;

@Component({
  selector: "app-money-transfer-option",
  templateUrl: "./money-transfer-option.component.html",
  styleUrls: ["./money-transfer-option.component.scss"]
})
export class MoneyTransferOptionComponent extends Notifier implements OnInit {
  banks: any[] = [];

  btnSpinner: boolean;

  bankTranseferForm: FormGroup;

  selectedTransactionType: number;
  paymentDetails = new PlanDetails();
  userData = new UserDetail();
  private paystackOptions: PaystackOptions;
  private isPaying = false;

  constructor(
    private license: LicensingService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rootStore: Store<fromStore.State>,
    public service: LicensingService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);

    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
    });
  }

  get bankId() {
    return this.bankTranseferForm.get("bankId");
  }

  get reference() {
    return this.bankTranseferForm.get("reference");
  }

  async pay() {
    await this.loadScript();
    this.setUp();
    if (this.isPaying) {
      return;
    }

    const payment = window.PaystackPop.setup(this.paystackOptions);
    payment.openIframe();
    this.isPaying = true;
  }

  ngOnInit() {
    this.btnSpinner = false;

    let param = this.activatedRoute.snapshot.queryParams["plan"];

    console.log(param);

    param = decodeURIComponent(param);

    this.paymentDetails = JSON.parse(param);

    console.log(this.paymentDetails);

    this.btnSpinner = false;

    this.selectedTransactionType = 0;

    this.createbankTranseferForm();

    this.license
      .getBanks()
      .toPromise()
      .then((res) => {
        console.log(res);
        this.banks = res;
      });
  }

  createbankTranseferForm() {
    this.bankTranseferForm = this.fb.group({
      bankId: ["0"],
      reference: ["", Validators.required]
    });
  }

  selectType(event) {
    this.selectedTransactionType = +event.target.value;
  }

  setUp() {
    const dateTimeSent = (+new Date()).toString();
    this.paystackOptions = {
      //  key: 'pk_live_eefcb9a565ffe71ef28d3a2ce05e6edb4bbb7500' ,
      key: "pk_test_a720b607052806bbc2d3f05adcf3970684aa4e56",
      email: this.userData.email,
      amount: this.paymentDetails.amount * 100,
      ref: "" + Math.floor(Math.random() * 1000000000 + 1),
      metadata: {
        custom_fields: []
      },
      currency: "NGN",
      plan: "",
      channels: ["card"],
      quantity: "",
      // subaccount: this.subaccount || '' ,
      // transaction_charge: this.transaction_charge || 0 ,
      // bearer: this.bearer || '' ,
      callback: (res) => {
        this.isPaying = false;
        console.log(res);
        this.completePayment(res, dateTimeSent);
      },
      onClose: () => {
        this.isPaying = false;

        this.sendErrorMessage("Transaction Failed");
      }
    };
  }

  loadScript(): Promise<void> {
    return new Promise((resolve) => {
      if (
        window.PaystackPop &&
        typeof window.PaystackPop.setup === "function"
      ) {
        resolve();
        return;
      }
      const script = window.document.createElement("script");
      window.document.head.appendChild(script);
      const onLoadFunc = () => {
        script.removeEventListener("load", onLoadFunc);
        resolve();
      };
      script.addEventListener("load", onLoadFunc);
      script.setAttribute("src", "https://js.paystack.co/v1/inline.js");
    });
  }

  completePayment(res?: any, dateTimeSent?: string) {
    this.btnSpinner = true;

    console.log("here");

    if (this.selectedTransactionType === 1) {
      this.sendErrorMessage("Select a payment type");
      return;
    }

    const completePayment = new CompletePayment();
    if (!isNullOrUndefined(res)) {
      if (res.status === "success") {
        completePayment.reference = res.transaction;
        completePayment.timeTransactionSentToGateway = dateTimeSent;
        completePayment.timeTransactionValidatedByGateway = (+new Date()).toString();
        completePayment.transactionStatus = true;
        completePayment.isPaystack = true;
        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
        completePayment.paymentgatewayCharges = 0;
      } else {
        this.sendErrorMessage("The Transaction failed");
        return;
      }
    } else {
      if (this.selectedTransactionType === 2) {
        if (
          isNullOrUndefined(this.bankId.value) ||
          +this.bankId.value < 1
        ) {
          this.sendErrorMessage("please select a bank");
          return;
        }

        completePayment.transactionStatus = true;
        completePayment.reference = this.reference.value;
        completePayment.BankId = this.bankId.value;
        completePayment.isBankDeposit = true;
        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      } else if (this.selectedTransactionType === 3) {
        // if((isNullOrUndefined(this.bankId.value)) || ((+this.bankId.value) <  1)) {
        //   this.sendErrorMessage('please select a bank');
        //   return;

        // }

        completePayment.transactionStatus = true;
        completePayment.isCash = true;
        completePayment.reference = this.reference.value;

        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      } else if (this.selectedTransactionType === 4) {
        if (
          isNullOrUndefined(this.bankId.value) ||
          +this.bankId.value < 1
        ) {
          this.sendErrorMessage("please select a bank");
          return;
        }

        completePayment.transactionStatus = true;
        completePayment.isCash = true;
        completePayment.reference = this.reference.value;

        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      } else if (this.selectedTransactionType === 5) {
        if (
          isNullOrUndefined(this.bankId.value) ||
          +this.bankId.value < 1
        ) {
          this.sendErrorMessage("please select a bank");
          return;
        }

        completePayment.transactionStatus = true;
        completePayment.reference = this.reference.value;
        completePayment.BankId = this.bankId.value;

        completePayment.isInternetTransfer = true;
        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      } else if (this.selectedTransactionType === 6) {
        completePayment.transactionStatus = true;
        completePayment.reference = this.reference.value;

        completePayment.isCheque = true;
        completePayment.crmPlanId = this.paymentDetails.id;
        completePayment.licensePaymentId = this.paymentDetails.licensePayementId;
      }
    }

    console.log(completePayment);

    this.service
      .completePayment(completePayment)
      .toPromise()
      .then((res) => {
        if (this.selectedTransactionType === 4) {
          this.router.navigate(["/payment-success"], {
            queryParams: {card: true}
          });
        } else {
          this.router.navigate(["/payment-success"]);
        }
      })
      .catch((err) => {
        this.sendErrorMessage(
          "The transaction Failed caused by " + err.message
        );
        this.btnSpinner = false;
      });
  }

  //  payWithPaystack(){
  //   let handler = PaystackPop.setup({
  //     key: 'pk_test_86d32aa1nV4l1da7120ce530f0b221c3cb97cbcc',
  //     email: 'customer@email.com',
  //     amount: 10000,
  //     currency: "NGN",
  //     ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
  //     metadata: {
  //        custom_fields: [
  //           {
  //               display_name: "Mobile Number",
  //               variable_name: "mobile_number",
  //               value: "+2348012345678"
  //           }
  //        ]
  //     },
  //     callback: function(response){
  //         alert('success. transaction ref is ' + response.reference);
  //     },
  //     onClose: function(){
  //         alert('window closed');
  //     }
  //   });
  //   handler.openIframe();
  // }
}
