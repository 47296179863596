import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Register } from "../../../core/models/parse-model/register";
import { StateViewModel } from "../../../core/models/view-model/States.model";
// import { firmRegistrationService } from './firm-registration.service';
import { AuthService } from "../../../core/services/auth.service";
import { GenericService } from "../../../core/services/generic.service";
import { AppConfigService } from "../../shared/helpers/app-config.service";
import { PasswordValidation } from "../../shared/helpers/password-matcher";

@Component({
  selector: "app-firm-registration",
  templateUrl: "./firm-registration.component.html",
  styleUrls: ["./firm-registration.component.scss"]
})
export class FirmRegistrationComponent implements OnInit {
  invalidEmail: boolean;
  error: string;
  firmReg: FormGroup;
  allFieldNotField = false;
  // while page is loading first, before interaction, dont show notification
  onLoadHide: boolean;
  showError: boolean;
  btnSpinner: boolean;
  emailCheckSpinner: boolean;
  firmUserTitle: any[] = [];
  firmUserGender = [
    {
      id: 1,
      name: "Male"
    },
    {
      id: 2,
      name: "Female"
    }
  ];
  // An id for a particular country to be used to populate state of that country
  countryId = 1;
  states: StateViewModel[] = [];

  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private config: AppConfigService,
    private genericService: GenericService
  ) {
    this.firmReg = fb.group(
      {
        email: ["", [Validators.required, Validators.email]],
        userName: [""],
        password: ["", [Validators.required]],
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        judgeName: ["", Validators.required],
        courtName: ["", Validators.required],
        courtNumber: ["", Validators.required],
        // address: ['', Validators.required],
        // city: ['', Validators.required],
        // stateId: ['', Validators.required],
        // gender: ['', Validators.required],
        // titleId: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        alternatephoneNumber: [''],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }

  // gain access to each form field for validation
  get firstName() {
    return this.firmReg.get("firstName");
  }

  get lastName() {
    return this.firmReg.get("lastName");
  }

  get courtName() {
    return this.firmReg.get("courtName");
  }

  get courtNumber() {
    return this.firmReg.get("courtNumber");
  }

  get judgeName() {
    return this.firmReg.get("judgeName");
  }

  get phoneNumber() {
    return this.firmReg.get("phoneNumber");
  }

  get alternatephoneNumber() {
    return this.firmReg.get("alternatephoneNumber");
  }

  get email() {
    return this.firmReg.get("email");
  }

  get firmPhoneNo() {
    return this.firmReg.get("firmPhoneNo");
  }

  get password() {
    return this.firmReg.get("password");
  }

  get confirmPassword() {
    return this.firmReg.get("confirmPassword");
  }

  ngOnInit() {
    this.invalidEmail = false;
    this.onLoadHide = true;
    this.btnSpinner = false;
    this.emailCheckSpinner = false;

    this.firmUserTitle = this.config.getConfig().firmUserTitle;
    // this.genericService.getStates(this.countryId).subscribe(res => {
    //   console.log(res);
    //   res.forEach(data => {
    //     this.states.push(data);
    //   });
    // });
  }

  // display form data
  regFirm(data) {
    if (this.firmReg.invalid) {
      this.allFieldNotField = true;
      return;
    } else {
      this.allFieldNotField = false;
      this.btnSpinner = true;
      data.value.userName = data.value.email;
      console.log(data.value);
      let user: Register;
      user = data.value as Register;
      console.log(user);

      const userRegInfo = {
        email: user.email,
        userName: user.userName,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
      };

      this.authService
        .register(userRegInfo)
        .toPromise()
        .then((response) => {
          this.router.navigate(["/confirm-email", user.email]);
        })
        .catch((error) => {
          this.btnSpinner = false;
          this.showError = true;
        });
    }
  }

  // get address() {
  //   return this.firmReg.get('address');
  // }
  // get city() {
  //   return this.firmReg.get('city');
  // }
  // get stateId() {
  //   return this.firmReg.get('stateId');
  // }
  // get gender() {
  //   return this.firmReg.get('gender');
  // }
  // get titleId() {
  //   return this.firmReg.get('titleId');
  // }
  // get phoneNumber() {
  //   return this.firmReg.get('phoneNumber');
  // }

  validateEmail() {
    console.log("validating email");
    this.emailCheckSpinner = true;
    this.authService
      .validateEmail(this.email.value)
      .toPromise()
      .then(
        (data) => {
          console.log("<<<<<<<------>>>>>>", data);
          if (data) {
            this.invalidEmail = false;
            this.emailCheckSpinner = false;
            console.log(data);
          } else {
            this.invalidEmail = true;
            this.messageBox.nativeElement.focus();
            // this.btnSpinner = false;
          }
        } // success path
      )
      .catch((error) => {
        console.log("threw error", error);
        this.error = error; // error path
        this.emailCheckSpinner = false;
        this.invalidEmail = true;
        this.messageBox.nativeElement.focus();
      });
  }
}
