import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import * as fromTask from "../../../core/store/reducers/task.reducer";

@Component({
  selector: "app-due-task",
  templateUrl: "./due-task.component.html",
  styleUrls: ["./due-task.component.scss"]
})
export class DueTaskComponent implements OnInit {
  @Input() tasks: UserTask[];
  _tasks: UserTask[] = [];
  currentTask: any;
  showCurrentTask: boolean;

  constructor(private taskStore: Store<fromTask.State>) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (!isNullOrUndefined(this.tasks)) {
      this._tasks = this.tasks;

      this._tasks = this._tasks.sort((c) => +c.dueDate);
    }
  }

  setCurrentTask(task) {
    this.currentTask = task;
    console.log(task);
  }
}
