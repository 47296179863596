import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

@Injectable({providedIn: "root"})
export class HomeGuard implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    console.log("here in home guard");
    console.log(this.currentUserService.isLoggedIn());
    if (!this.currentUserService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(["/firm"]);
  }
}
