// tslint:disable-next-line: class-name
export interface Endorsement {
  proceedingId: number;
  hearingDate: string;
  nextHearingDate: string;
  nextAction: string;
  nextStage: string;
  summary: string;
  matterId: number;
  commencementDate: string;
  lastHearingDate: string;
  dateCreated: string;
  tag: string;
  tagId: number;
}

export class ProceedingViewModel {
  proceedingId: number;
  hearingDate: string;
  nextHearingDate: string[];
  nextAction: string;
  nextStage: string;
  summary: string;
  matterId: number;
  commencementDate: string;
  lastHearingDate: string;
  dateCreated: string;
  tagId: number;
  tag: string;
  author: string;
}

export const endorsementListSample: Endorsement[] = [
  {
    proceedingId: 1,
    hearingDate: `${new Date("02-19-2019").getTime()}`,
    nextHearingDate: `${new Date("03-31-2019").getTime()}`,
    nextAction:
      "the next action we would take would involve pleadings, which entails making our voices heard by the jury",
    nextStage: "Hearing 2",
    // tslint:disable-next-line: max-line-length
    summary: `The oncologist struggled to find the right words.
        The scientific publication upon which she based her most recent treatment
        recommendation for the patient sitting in front of her had just been retracted from a prestigious journal.
        She reflected on a lengthy discussion with this patient six months
         prior considering the trade-offs between treatment options. When
         based on accurate and reliable research. The retracted comparative-effectiveness study
        that had once embodied so much promise now brought bitterness and confusion.`,
    matterId: 3,
    commencementDate: `${new Date("02-19-2019").getTime()}`,
    lastHearingDate: `${new Date("01-19-2019").getTime()}`,
    dateCreated: `${new Date("01-23-2019").getTime()}`,
    tag: "NORMAL",
    tagId: 3
  },
  {
    proceedingId: 2,
    hearingDate: `${new Date("02-19-2019").getTime()}`,
    nextHearingDate: `${new Date("03-31-2019").getTime()}`,
    nextAction: "pleadings",
    nextStage: "Hearing 2",
    summary: "try to plead our case to the jury",
    matterId: 3,
    commencementDate: `${new Date("02-19-2019").getTime()}`,
    lastHearingDate: `${new Date("01-19-2019").getTime()}`,
    dateCreated: `${new Date("01-23-2019").getTime()}`,
    tag: "NORMAL",
    tagId: 3
  }
];
