import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { FileUploadModel } from "../../../core/models/generic-model.ts/file-upload";
import { IAttachFile } from "../interfaces/attach-file";

@Component({
  selector: "app-attach-file",
  templateUrl: "./attach-file.component.html",
  styleUrls: ["./attach-file.component.scss"]
})
export class AttachFileComponent implements OnInit, IAttachFile {
  errorText: string;
  showError: boolean;
  fileProgressArray: number[];
  currentDocument: FileUploadModel[];

  @Output() uploaderObject = new EventEmitter<any>();
  @Input() resourceId = 0;

  constructor() {
  }

  ngOnInit() {
    this.fileProgressArray = [];
    this.currentDocument = [];
  }

  closeFileItem(index) {
    this.fileProgressArray.splice(index, 1);
    this.currentDocument.splice(index, 1);
  }

  // #region File attachment
  /**
   * when file is changed
   * @param event;
   */
  onFileChange(event) {
    this.set_upReader(event.target.files, 0);
    console.log(this.currentDocument);
    this.uploaderObject.emit({
      showError: this.showError,
      errorText: this.errorText,
      currentDocument: this.currentDocument
    });
  }

  set_upReader(files, i) {
    // check if it has file
    if (files && files[i]) {
      const file = files[i];
      const fileName = file.name;
      const fileSize = file.size;
      let fileType = '';
      // file.type
      console.log(file);
      console.log(file.size);
      if (file.size > 2097152) {
        this.errorText =
          "File attached " +
          file.name +
          "  with file size greater than 10MB is not allowed" +
          // tslint:disable-next-line: no-unused-expression
          "Error! ";
      } else {
        if(file.type === 'application/pdf') {
          fileType = 'pdf';
        } else if(file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          fileType = 'docx';
        }
        console.log(file);
        const previewReader = new FileReader();

        //
        previewReader.onprogress = (e) => {
          this.fileProgressArray.push((e.loaded / e.total) * 100);
        };

        previewReader.readAsDataURL(file);
        previewReader.onload = (e: any) => {
          this.readerLoaded(
            e,
            files,
            i,
            fileName,
            fileSize,
            fileType
          );
        };

        // /
      }
    }
  }

  readerLoaded(e, files, i, fileName, fileSize, fileType) {
    // called once readAsDataURL is completed
    console.log(e);
    // this is correct , ignore errors
    const FILE = new FileReader();

    const taskUpload = new FileUploadModel();

    taskUpload.fileBinary = (<string>e.target.result).split(",")[1];

    taskUpload.fileExtension = fileType;
    taskUpload.fileType = fileType;
    taskUpload.fileName = fileName;
    taskUpload.fileSize = fileSize;
    taskUpload.resourceId = this.resourceId;
    taskUpload.documentType = fileType;
    taskUpload.description = '';

    this.currentDocument.push(taskUpload);
    console.log(this.currentDocument);
    // call service

    // If there's a file left to load
    if (i < files.length - 1) {
      // Load the next file
      this.set_upReader(files, i + 1);
    }
  }

  // #endregion
}
