import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PermissionControlComponent } from "./permission-control/permission-control.component";

@NgModule({
  declarations: [PermissionControlComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [PermissionControlComponent]
})
export class ReusablesModule {
}
