import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { isNullOrUndefined } from "util";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-generate-default-picture",
  templateUrl: "./generate-default-picture.component.html",
  styleUrls: ["./generate-default-picture.component.scss"]
})
export class GenerateDefaultPictureComponent implements OnInit {
  color: string;
  alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];

  @Input() firstName = new Observable<string>();
  @Input() lastName = new Observable<string>();
  @Input() theLetter: string;
  @Input() newMatterActive: boolean;

  constructor(private config: AppConfigService) {
  }

  // ngOnChanges(): void {
  //   if (this.newMatterActive === true) {
  //     this.color = '#2865AC';
  //   }
  //   console.log('--->>>>>>>>', this.newMatterActive);
  // }

  ngOnInit() {
    console.log(this.lastName);

    if (this.newMatterActive) {
      console.log("---->>>>", this.newMatterActive);
      this.color = "#2865AC";
    } else {
      console.log("---->>>>", this.newMatterActive);
      this.color = "#222753"; // set the color
    }

    this.lastName.subscribe((value) => {
      if (!isNullOrUndefined(value)) {
        console.log(value);
        this.firstName.subscribe((_firstName) => {
          if (!isNullOrUndefined(_firstName)) {
            this.theLetter =
              _firstName[0].toUpperCase() +
              "" +
              value[0].toUpperCase();
            console.log(this.theLetter);
            const index = this.alphabet.indexOf(
              this.theLetter[this.theLetter.length - 1]
            ); // find the alphabet index
            const bgColors = this.config.getConfig().colors; // get all the colors from config
            const colorIndex = index % bgColors.length; // pick the color
            // this.color = bgColors[colorIndex]; // set the color

            if (this.newMatterActive) {
              console.log("---->>>>", this.newMatterActive);
              this.color = "#2865AC";
            } else {
              console.log("---->>>>", this.newMatterActive);
              this.color = "#222753"; // set the color
            }
          }
        });
      }
    });
  }
}
