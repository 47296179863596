import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ApplicationViewModel } from "../../models/view-model/application-list";
import { ProceedingViewModel } from "../../models/view-model/endorsement-list";
import { MatterExpensesModel } from "../../models/view-model/matter-expenses";
import { ContactList, MatterDetail } from "../../models/view-model/matter-list";
import { MatterTaskModel } from "../../models/view-model/matter-tasks";
import { MatterTeamMember } from "../../models/view-model/team-list";
import { MatterTimeSheetModel } from "../../models/view-model/time-sheet";
import { MatterService } from "../../services/matter.service";
import * as mattersActions from "../actions/matters.action";
import { NewMatterService } from "../../services/new-matter.service";

@Injectable()
export class MattersEffects {
  @Effect()
  loadMattersList$ = this.actions$.pipe(
    ofType<mattersActions.LoadMatterList>(
      mattersActions.MattersActionTypes.loadMatterList
    ),
    mergeMap((action) =>
      this.service
        .getMatterList(
          action.payload.firmId,
          action.payload.searchQuery,
          ""
        )
        .pipe(
          map(
            (matterList: any) =>
              new mattersActions.LoadMatterListSuccess(matterList)
          ),
          catchError((err) =>
            of(new mattersActions.LoadMatterListFail(err))
          )
        )
    )
  );
  @Effect()
  loadMattersListByCategory$ = this.actions$.pipe(
    ofType<mattersActions.LoadMatterListByCategory>(
      mattersActions.MattersActionTypes.loadMatterListByCategory
    ),
    mergeMap((action) =>
      this.newService
        .getMatterListByCatgory(
          action.payload.ongoing,
          action.payload.archived,
          action.payload.completed,
          action.payload.categoryType,
          action.payload.categorySubType,
          action.payload.clientId,
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.firmId,
          action.payload.matterCategoryId,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map(
            (matterList: any) =>
              new mattersActions.LoadMatterListByCategorySuccess(
                matterList
              )
          ),
          catchError((err) =>
            of(new mattersActions.LoadMatterListByCategoryFail(err))
          )
        )
    )
  );
  @Effect()
  loadMatterDocuments$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.LoadMatterDocuments),
    mergeMap((action: mattersActions.LoadMatterDocuments) =>
      this.service.getMatterDocument(action.payload).pipe(
        map(
          (matterDocuments: Document[]) =>
            new mattersActions.LoadMatterDocumentSuccess(
              matterDocuments
            )
        ),
        catchError((err) =>
          of(new mattersActions.LoadMatterDocumentFail(err))
        )
      )
    )
  );
  @Effect()
  loadMatterTimeSheet$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.loadMatterTimeSheet),
    mergeMap((action: mattersActions.LoadMatterTimeSheet) =>
      this.service
        .getMatterTimes(
          action.payload.matterId,
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map(
            (timeSheet: any) =>
              new mattersActions.LoadMatterTimeSheetSuccess(
                timeSheet
              )
          ),
          catchError((err) =>
            of(new mattersActions.LoadMatterTimeSheetFail(err))
          )
        )
    )
  );
  @Effect()
  loadCurrentMatterContacts$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.loadCurrentMatterContacts),
    mergeMap((action: mattersActions.LoadCurrentMatterContacts) =>
      this.service.getContacts(action.payload).pipe(
        map(
          (list: ContactList[]) =>
            new mattersActions.LoadCurrentMatterContactsSuccess(
              list
            )
        ),
        catchError((err) =>
          of(new mattersActions.LoadMatterListFail(err))
        )
      )
    )
  );
  @Effect()
  loadCurrentMatter$ = this.actions$.pipe(
    ofType<mattersActions.LoadCurrentMatter>(
      mattersActions.MattersActionTypes.loadCurrentMatter
    ),
    //     ofType(mattersActions.MattersActionTypes.loadCurrentMatterTeam),
    mergeMap((action) =>
      this.service.getCurrentMatter(action.payload).pipe(
        // this.service.getTeam(matterId)
        // .pipe(
        // change type here once model is provided
        map(
          (matter: MatterDetail) =>
            new mattersActions.LoadCurrentMatterSuccess(matter)
        ),
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterFail(err))
        )
      )
    )
  );
  @Effect()
  loadCurrentMatterJudges$ = this.actions$.pipe(
    ofType<mattersActions.LoadCurrentMatterJudges>(
      mattersActions.MattersActionTypes.loadCurrentMatterJudge
    ),
    //     ofType(mattersActions.MattersActionTypes.loadCurrentMatterTeam),
    mergeMap((action) =>
      this.service.getCurrentMatterJudges(action.payload).pipe(
        // this.service.getTeam(matterId)
        // .pipe(
        // change type here once model is provided
        map(
          (matterjudges) =>
            new mattersActions.LoadCurrentMatterJudgesSuccess(
              matterjudges
            )
        ),
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterJudgesFail(err))
        )
      )
    )
  );
  @Effect()
  loadMatterEndorsement$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.LoadMatterEndorsement),
    mergeMap((action: mattersActions.LoadMatterEndorsement) =>
      this.service
        .getMatterEndorsement(
          action.payload.matterId,
          action.payload.isAdmin,
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map(
            (endorse: ProceedingViewModel[]) =>
              new mattersActions.LoadMatterEndorsementSuccess(
                endorse
              )
          ),
          catchError((err) =>
            of(new mattersActions.LoadMatterEndorsementFail(err))
          )
        )
    )
  );
  @Effect()
  loadMatterApplication$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.LoadMatterApplication),
    mergeMap((action: mattersActions.LoadMatterApplication) =>
      this.service
        .getMatterApplication(
          action.payload.matterId,
          action.payload.isAdmin,
          action.payload.searchQuery,
          action.payload.filter,
          action.payload.sortDirection,
          action.payload.pageIndex,
          action.payload.pageSize
        )
        .pipe(
          map(
            (applic: ApplicationViewModel[]) =>
              new mattersActions.LoadMatterApplicationSuccess(
                applic
              )
          ),
          catchError((err) =>
            of(new mattersActions.LoadMatterApplicationFail(err))
          )
        )
    )
  );
  @Effect()
  loadCurrentMatterTeam$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.loadCurrentMatterTeam),
    map((action: mattersActions.LoadCurrentMatterTeam) => action.payload),
    mergeMap((matterId: number) =>
      this.service.getTeam(matterId).pipe(
        // change type here once model is provided
        map(
          (team: MatterTeamMember[]) =>
            new mattersActions.LoadCurrentMatterTeamSuccess(team)
        ),
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterTeamFail(err))
        )
      )
    )
  );
  @Effect()
  addNewParty$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.addNewParty),
    mergeMap((action: mattersActions.AddNewParty) =>
      this.service
        .addNewParty({
          matterId: action.payload.matterId,
          contactId: action.payload.contactId,
          isClient: action.payload.isClient,
          matterContactStatusId: action.payload.matterContactStatusId
        })
        .pipe(
          // change type here once model is provided
          map(
            (contactId: number) =>
              new mattersActions.AddNewPartySuccess(contactId)
          ),
          catchError((err) =>
            of(new mattersActions.AddNewPartyFail(err))
          )
        )
    )
  );
  @Effect()
  addNewTeamMember$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.AddTeamMember),
    mergeMap((action: mattersActions.AddTeamMember) =>
      this.service.addNewTeamMember(action.payload).pipe(
        // change type here once model is provided
        map(
          (teamMember: number) =>
            new mattersActions.AddTeamMemberSuccess(teamMember)
        ),
        catchError((err) =>
          of(new mattersActions.AddTeamMemberFail(err))
        )
      )
    )
  );
  @Effect()
  addMatterApplication$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.AddApplication),
    mergeMap((action: mattersActions.AddApplication) =>
      this.service.addNewApplication(action.payload).pipe(
        map(
          (applic: number) =>
            new mattersActions.AddApplicationSuccess(applic)
        ),
        catchError((err) =>
          of(new mattersActions.AddApplicationFail(err))
        )
      )
    )
  );
  @Effect()
  loadCurrentMatterTasks$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.loadCurrentMatterTasks),
    map((action: mattersActions.LoadCurrentMatterTasks) => action.payload),
    mergeMap((matterId: number) =>
      this.service.getMatterTasks(matterId).pipe(
        // change type here once model is provided
        map(
          (task: MatterTaskModel[]) =>
            new mattersActions.LoadCurrentMatterTasksSuccess(task)
        ),
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterTasksFail(err))
        )
      )
    )
  );
  @Effect()
  loadCurrentMatterExpenses$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.loadCurrentMatterExpenses),
    map(
      (action: mattersActions.LoadCurrentMatterExpenses) => action.payload
    ),
    mergeMap((matterId: number) =>
      this.service.getMatterExpenses(matterId).pipe(
        // change type here once model is provided
        map(
          (expense: MatterExpensesModel[]) =>
            new mattersActions.LoadCurrentMatterExpensesSuccess(
              expense
            )
        ),
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterExpensesFail(err))
        )
      )
    )
  );
  @Effect()
  loadCurrentMatterTimes$ = this.actions$.pipe(
    ofType<mattersActions.LoadCurrentMatterTimes>(
      mattersActions.MattersActionTypes.loadCurrentMatterTimes
    ),
    mergeMap((action) =>
      this.service.getMatterTimes(action.payload).pipe(
        // If successful, dispatch success action with result
        map(
          (time: MatterTimeSheetModel[]) =>
            new mattersActions.LoadCurrentMatterTimesSuccess(time)
        ),
        // If request fails, dispatch failed action
        catchError((err) =>
          of(new mattersActions.LoadCurrentMatterTimesFail(err))
        )
      )
    )
  );
  @Effect()
  addMatterTimes$ = this.actions$.pipe(
    ofType<mattersActions.AddMatterTimes>(
      mattersActions.MattersActionTypes.ADD_MATTER_TIME
    ),
    mergeMap((action) =>
      this.service.saveTimeEntry(action.payload).pipe(
        // If successful, dispatch success action with result
        map(
          (id: number) => new mattersActions.AddMatterTimesSuccess(id)
        ),
        // If request fails, dispatch failed action
        catchError((err) =>
          of(new mattersActions.AddMatterTimesFail(err))
        )
      )
    )
  );
  // catchError(err => of({type: mattersActions.MattersActionTypes.loadCurrentMatterTimesFail(err)})))));
  @Effect()
  deleteTeamMember$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.DeleteTeamMember),
    mergeMap((action: mattersActions.DeleteTeamMember) =>
      this.service.deleteTeamMember(action.payload).pipe(
        // change type here once model is provided
        map((bool) => new mattersActions.DeleteTeamMemberSuccess()),
        catchError((err) =>
          of(new mattersActions.DeleteTeamMemberFail(err))
        )
      )
    )
  );
  // catchError(err => of({type: mattersActions.MattersActionTypes.loadCurrentMatterTimesFail(err)})))));

  // .pipe(
  //   ofType(mattersActions.MattersActionTypes.loadCurrentMatterTimes),
  //   map((action: mattersActions.loadCurrentMatterTimes) => action.payload),
  //   mergeMap((matterId: number) =>
  //     this.service.getMatterTimes(matterId)
  //       .pipe(
  //         // change type here once model is provided
  //         map((time: MatterTimeSheetModel[]) => new mattersActions.loadCurrentMatterTimesSuccess(time)),
  //         catchError(err => of(new mattersActions.loadCurrentMatterTimesFail(err)))
  //       )
  //   )
  // );
  @Effect()
  updateMatterApplication$ = this.actions$.pipe(
    ofType(mattersActions.MattersActionTypes.UpdateApplication),
    map((action: mattersActions.UpdateApplication) => action.payload),
    mergeMap((arg: any) =>
      this.service.updateMatterApplication(arg).pipe(
        // change type here once model is provided
        map((res) => new mattersActions.UpdateApplicationSuccess(res)),
        catchError((err) =>
          of(new mattersActions.UpdateApplicationFail(err))
        )
      )
    )
  );

  constructor(private actions$: Actions, private service: MatterService, private newService: NewMatterService) {
  }
}
