import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { isNullOrUndefined } from "util";
import { FilterGenericModel } from "../models/generic-model.ts/filter-model";
import { GenericListViewModel } from "../models/generic-model.ts/generic-list";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class NewMatterService {
  Endpoints = new Endpoints(this.config);
  filter = new FilterGenericModel();
  matterCategory = new GenericListViewModel();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.filter = new FilterGenericModel();

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.matterCategory.id = +params.get('categoryId');
      this.matterCategory.name = params.get('categoryName');

      console.log('Query params ', this.matterCategory);

      if (!isNullOrUndefined(params.get('ongoing'))) {
        // tslint:disable-next-line:triple-equals
        this.filter.ongoing =
          params.get('ongoing').toLowerCase() === 'true' ? true : false;
      }

      if (!isNullOrUndefined(params.get('archived'))) {
        // tslint:disable-next-line:triple-equals
        this.filter.archived =
          params.get('archived').toLowerCase() === 'true' ? true : false;
      }

      if (!isNullOrUndefined(params.get('closed'))) {
        // tslint:disable-next-line:triple-equals
        this.filter.completed =
          params.get('closed').toLowerCase() === 'true' ? true : false;
      }

      if (!isNullOrUndefined(params.get('categoryType'))) {
        // tslint:disable-next-line:triple-equals
        this.filter.categoryType = JSON.parse(
          '[' + params.getAll('categoryType') + ']'
        );

        // this.filter.categoryType.forEach(c => {
        //   // tslint:disable-next-line:triple-equals
        //   this.practiceAreas.find(p => p.id == c).checked = true;
        // });
      }
      if (!isNullOrUndefined(params.get('categorySubType'))) {
        this.filter.categorySubType = JSON.parse(
          '[' + params.getAll('categorySubType') + ']'
        );
      }

      if (!isNullOrUndefined(params.get('clientId'))) {
        // tslint:disable-next-line:triple-equals
        this.filter.clientId = Number.parseInt(params.get('clientId'), 10);
        console.log(this.filter.clientId);
      }

      console.log(this.matterCategory);
      console.log(this.filter);
    });
  }

  addMatterDetails(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter";
    return this.httpClient.post<any>(url, payload);
  }


  updateMatter(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/update";
    return this.httpClient.put<any>(url, payload);
  }


  addMatterConfig(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Config";
    return this.httpClient.post<any>(url, payload);
  }

  getMatterTypes(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/MatterType";
    return this.httpClient.get<any[]>(url);
  }
  getCounselRoles(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/CounselRole";
    return this.httpClient.get<any[]>(url);
  }

  getMatters(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Firmmatterlist";
    return this.httpClient.get<any[]>(url);
  }

  getSubjectMatter(matterTypeId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/SubjectMatter/" + matterTypeId;
    return this.httpClient.get<any[]>(url);
  }

  getMatterLegalHeads(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Legalhead";
    return this.httpClient.get<any[]>(url);
  }

  getPresidingJudges(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "/Judges";
    return this.httpClient.get<any[]>(url);
  }


  getCourts(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "/Court";
    return this.httpClient.get<any[]>(url);
  }

  // let's party
  getMatterParties(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/party";
    return this.httpClient.get<any[]>(url);
  }

  getAMatterParty(partyId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/party/" + partyId;
    return this.httpClient.get<any[]>(url);
  }

  addParty(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/party";
    return this.httpClient.post(url, payload);
  }

  updateMatterParties(id, payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/party";
    return this.httpClient.put<any[]>(url, payload);
  }

  addMatterParties(payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/matterparty";
    return this.httpClient.post<any[]>(url, payload);
  }

  deleteMatterParties(partyId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/party/" + partyId;
    return this.httpClient.delete<any[]>(url);
  }

  // counsel

  getAMatterCounsel(counselId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/counsel/" + counselId;
    return this.httpClient.get<any[]>(url);
  }

  updateMatterCounsels(id, payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/counsel";
    return this.httpClient.put<any[]>(url, payload);
  }

  addMatterCounsels(payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/counsel";
    return this.httpClient.post<any[]>(url, payload);
  }

  deleteMatterCounsels(counselId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/counsel/" + counselId;
    return this.httpClient.delete<any[]>(url);
  }

  CounselData():Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/CounselRole`;
    return this.httpClient.get<any>(url);
  }

  // witness

  getAMatterWitness(witnessId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness/" + witnessId;
    return this.httpClient.get<any[]>(url);
  }

  updateMatterWitnesses(id, payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness";
    return this.httpClient.put<any[]>(url, payload);
  }

  addMatterWitnesses(payload): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness";
    return this.httpClient.post<any[]>(url, payload);
  }

  deleteMatterWitnesses(witnessId): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness/" + witnessId;
    return this.httpClient.delete<any[]>(url);
  }


  // end of filter calls
  // ongoing: boolean;
  // archived: boolean;
  // completed: boolean;
  // categoryType: number[];
  // categorySubType: number[];
  // clientId: number;
  // http://fbnlegal.com/courtmanagerapi/api/v1/matter/Ongoingmatters?Sort=s&Order=as&SearchQuery=dff&CategoryType=2&CategoryType=1&CategorySubType=3&CategorySubType=3&ClientId=1&AmountIndex=233&EndAmountVal=500000&StartAmountVal=23&usePageSize=true&PageSize=10&Page=1
  public getMatterListByCatgory(ongoing?: boolean, archived?: boolean,
    completed?: boolean, categoryType = [0],
    categorySubType = [0], clientId = 0, searchQuery = '',
    sort = '', firmId = 0, matterCategoryId?: number, order = 'asc',
    page?: number, pageSize = 0, usePageSize?: boolean): Observable<any> {
      console.log(categorySubType);
      console.log(categoryType);
    if (usePageSize !== false) {
      usePageSize = true;
    }
    console.log(categoryType);
    console.log(categorySubType);
    let categoryTypeQueryString: string;
    let categorySubTypeQueryString: string;
    let clientQueryNumber: string;

    let ongoingQueryString: string;
    let archivedQueryString: string;
    let completedQueryString: string;


    categoryTypeQueryString = '';
    categorySubTypeQueryString = '';
    clientQueryNumber ='';
    if (isNullOrUndefined(ongoing)) {
      ongoingQueryString = `ongoing=${false}&`;
    } else {
      ongoingQueryString = `ongoing=${ongoing}`;
    }
    if (isNullOrUndefined(completed)) {
      completedQueryString = `completed=${false}&`;
    } else  {
      completedQueryString = `&completed=${completed}`;
    }
    if (isNullOrUndefined(archived)) {
      archivedQueryString = `archived=${false}&`;
    } else {
      archivedQueryString = `&archived=${archived}`;
    }
    if (archived || completed || ongoing || categorySubType[0] > 0 || categoryType[0] > 0 || clientId > 0) {
      if (isNullOrUndefined(ongoing)) {
        ongoingQueryString = `ongoing=${false}&`;
      } else {
        ongoingQueryString = `ongoing=${ongoing}&`;
      }

      if (isNullOrUndefined(archived)) {
        archivedQueryString = `archived=${false}&`;
      } else {
        archivedQueryString = `archived=${archived}&`;
      }

      if (isNullOrUndefined(completed)) {
        completedQueryString = `completed=${false}&`;
      } else {
        completedQueryString = `completed=${completed}&`;
      }
      if (isNullOrUndefined (categorySubType === null)) {
        console.log(categorySubType);
        categorySubTypeQueryString = `&`;
        // categoryTypeQueryString = `&CategoryType=${[0]}`;
      }

      if (isNullOrUndefined (categoryType === null)) {
        console.log(categoryType);
        // categorySubTypeQueryString = `&CategorySubType=${[0]}`;
      categoryTypeQueryString = `&`;
      }

      if (!isNullOrUndefined(clientId == null)) {
        clientQueryNumber = `&clientId=${clientId}`;
      } else {
        clientQueryNumber = ``;
      }
    }
    if (categorySubType.length > 1 || categorySubType[0] > 0) {

      categorySubType.forEach(c => {

        categorySubTypeQueryString = categorySubTypeQueryString + `&CategorySubType=${c}`;

      });

    } else {
      // if the query string is default or one query param
      categorySubTypeQueryString = ``;
    }

    if (categoryType.length > 1  || categoryType[0] > 0) {

      categoryType.forEach(c => {

        categoryTypeQueryString = categoryTypeQueryString + `&CategoryType=${c}`;

      });
    } else {
      // if the query string is default or one query param
      categoryTypeQueryString = ``;
    }

    if (!isNullOrUndefined(clientId == null) && clientId !== 0) {
      clientQueryNumber = `&clientId=${clientId}`;
    } else {
      clientQueryNumber = ``;
    }

    // console.log(firmId);
    console.log(matterCategoryId);
    console.log(this.filter);
      let url = ``;
      const allUrl = this.config.getConfig().serverEndpoint + `matter/Ongoingmatters`;
      const ongoingUrl = this.config.getConfig().serverEndpoint + `matter/Ongoingmatters`;
      const archievedUrl = this.config.getConfig().serverEndpoint + `matter/Closedmatters`;
      const completedUrl = this.config.getConfig().serverEndpoint + `matter/Archivedmatters`;
      if ( !isNullOrUndefined(ongoing) && ongoing) {
        url = ongoingUrl;
      } else if (!isNullOrUndefined(completed) && completed ) {
        url = completedUrl;
      } else if (!isNullOrUndefined(archived) && archived ) {
        url = archievedUrl;
      }
      else {
        url = allUrl;
      }

    // tslint:disable-next-line:max-line-length
    const api = `${url}?${ongoingQueryString}${archivedQueryString}${completedQueryString}${clientQueryNumber}&searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}${categoryTypeQueryString}${categorySubTypeQueryString}`;
    console.log(api);
    return this.httpClient.get<any>(api, { observe: 'response' });
    // return of(matterListSample);
    // return of(dashboardMatterListSample);
  }

  // matters list
  getOngoingMatters(searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Ongoingmatters";
    const api = `${url}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(api, { observe: 'response' });
  }

  getClosedMatters(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Closedmatters";
    return this.httpClient.get<any[]>(url);
  }

  getArchivedMatters(): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/Archivedmatters";
    return this.httpClient.get<any[]>(url);
  }

  addMatterAppearanceRecording(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/ProceedingAppearance";
    return this.httpClient.post<any>(url, payload);
  }

  // matters overview apis
  getMatterProceedingList(matterId: number, searchQuery = '', sort = '', order = 'asc', page = 0, pageSize = 10, usePageSize = true): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/" + matterId + "/proceeding";
    const api = `${url}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, { observe: 'response' });
  }

  getMatterPartyList(matterId: number): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/MatterParty/" + matterId;
    return this.httpClient.get<any[]>(url);
  }

  getMatterWitnessList(matterId: number): Observable<any[]> {
    const url = this.config.getConfig().serverEndpoint + "matter/MatterWitness/" + matterId;
    return this.httpClient.get<any[]>(url);
  }

  deletePartyCounsel(partyId: number, counselId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/party/" + partyId + "/counsel/" + counselId;
    return this.httpClient.delete<any>(url);
  }

  addMatterParty(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/matterparty";
    return this.httpClient.post<any>(url, payload);
  }

  deleteMatterParty(partyId: number, matterId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `matter/${matterId}/party/${partyId}`;
    return this.httpClient.delete<any>(url);
  }

  deleteMatterWitness(witnessId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness/" + witnessId;
    return this.httpClient.delete<any>(url);
  }

  deleteMatterEvidence(evidenceId: number): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Evidence/" + evidenceId;
    return this.httpClient.delete<any>(url);
  }


  updateMatterParty(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/party";
    return this.httpClient.put<any>(url, payload);
  }
  updateMatterCounsel(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/counsel";
    return this.httpClient.put<any>(url, payload);
  }

  addPartyCounsel(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/mattercounsel";
    return this.httpClient.post<any>(url, payload);
  }


  addMatterWitness(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/MatterWitness";
    return this.httpClient.post<any>(url, payload);
  }

  addMatterEvidence(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Evidence";
    return this.httpClient.post<any>(url, payload);
  }

  updateMatterWitness(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Witness";
    return this.httpClient.put<any>(url, payload);
  }
  CreateMatterWitness(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/MatterWitness";
    return this.httpClient.post<any>(url, payload);
  }
  CreateMatterEvidence(payload): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/WitnessEvidence";
    return this.httpClient.post<any>(url, payload);
  }

}
