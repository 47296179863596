import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

import * as fromStore from "../../../core/store";

import * as LoginActions from "../../../core/store/actions/login.actions";

@Component({
  selector: "app-force-logout",
  templateUrl: "./force-logout.component.html",
  styleUrls: ["./force-logout.component.scss"]
})
export class ForceLogoutComponent implements OnInit {
  authState: any;
  email: string;

  userData = new UserDetail();

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>,
    private activatedRoute: ActivatedRoute
  ) {
    //  isLoggingOut errorMsg
  }

  ngOnInit() {
    console.log(this.activatedRoute.snapshot.params["email"]);

    if (!isNullOrUndefined(this.activatedRoute.snapshot.params["email"])) {
      this.email = this.activatedRoute.snapshot.params["email"];
      this.store.dispatch(new LoginActions.Logout(this.email));
      console.log(this.email);
    } else {
      console.log(this.email);
      this.email = this.userData.email;
      this.router.navigate(["/login"]);
    }
  }
}
