import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import {
  LicenseEndPointModel,
  MonthLicenseCost,
  PeriodDiscount,
  PlanGroup
} from "../../../core/models/view-model/license-endpoint.model";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { LicensingService } from "../../../core/services/licensing.service";
import { State } from "../../../core/store";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-license-payment",
  templateUrl: "./license-payment.component.html",
  styleUrls: ["./license-payment.component.scss"]
})
export class LicensePaymentComponent extends Notifier implements OnInit {
  userData = new UserDetail();
  licensingForm: FormGroup;
  numberOfUser: number;
  duration: number;
  getAllLicense: PlanGroup[] = [];
  agree: boolean;
  select: boolean;
  btnSpinner: boolean;
  discountPercentaage: number;
  plans: MonthLicenseCost[] = this.config.getConfig().monthLicenseCost;
  periodDiscount: PeriodDiscount[] = this.config.getConfig().periodDiscount;
  monthlyValue: number = this.config.getConfig().monthlyValue;
  price: number;
  selectId: number;
  totalPrice: number;
  discount: number;
  planName: string;
  periodLable = "Please select a plan";
  periodType = "";

  // the day is the secret key for the encryption

  selectedDuration = new LicenseEndPointModel();

  @ViewChild("focusBox") focusBox: ElementRef;

  constructor(
    public fb: FormBuilder,
    public service: LicensingService,
    public store: Store<State>,
    public config: AppConfigService,
    public activatedRoute: ActivatedRoute,
    // private encryptionService: RsaService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    public router: Router
  ) {
    super(headerStore);

    this.store.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
    });

    this.licensingForm = fb.group({
      terms_agreement: ["", Validators.required]
      // noOfUsers: ['', Validators.required],
      // durationCount: ['', Validators.required],
      // planSelect : ['']
    });
    //  super(fb, service, config, activatedRoute, router);
  }

  // }
  get noOfUsers() {
    return this.licensingForm.get("noOfUsers");
  }

  // get planSelect() {
  //   return this.licensingForm.get("planSelect");

  get terms_agreement() {
    return this.licensingForm.get("terms_agreement");
  }

  get durationCount() {
    return this.licensingForm.get("durationCount");
  }

  ngOnInit() {
    this.selectedDuration.id = 0;

    this.returnOnClick();
    this.getEndPointLicensing();

    this.agree = false;
  }

  getUserDetails() {
    if (!isNullOrUndefined(this.licensingForm.get("noOfUsers").value)) {
      this.numberOfUser = this.licensingForm.get("noOfUsers").value;
      return this.numberOfUser;
    } else {
      return 0;
    }
  }

  // getPlan() {
  //   this.planName = this.licensingForm.get('planSelect').value;
  // }

  // getDuration() {
  //   this.duration = this.licensingForm.get("durationCount").value;
  //   console.log(this.duration);
  // }

  // getPrice(amount) {
  //   const rate = this.getDiscountRate();
  //   const discount = amount * +rate;
  //   this.price = +amount - discount;
  // }

  // getDiscountAlgorithm(id) {
  //   if (id === 1) {
  //     this.periodLable = 'How many Months?';
  //     this.periodType = 'month(s)';
  //     this.getPrice(+this.monthlyValue);

  //   }
  //   if (id === 2) {
  //     this.periodLable = 'How many Years?';
  //     this.periodType = 'year(s)';
  //     const annualAmount = +this.monthlyValue - (+this.monthlyValue * (this.periodDiscount[1].discountPercentage / 100));
  //     this.getPrice(annualAmount);

  //   }

  // }

  getEndPointLicensing() {
    this.service.getLicensingList().subscribe(
      (data) => {
        if (!isNullOrUndefined(data)) {
          this.getAllLicense = data;
          this.showLoader = false;
          // tslint:disable-next-line: no-unused-expression
          // this.showNoResult;
          // console.log(data);
        } else {
          // say something
        }
      },
      (error) => {
        this.showLoader = false;
        // tslint:disable-next-line: no-unused-expression
        // this.showNoResult;
      }
    );
    // tslint:disable-next-line: no-unused-expression
  }

  selectPlan(plan: LicenseEndPointModel) {
    console.log(plan);

    this.selectedDuration = plan;
  }

  // private getDiscountRate(): number {
  //   let discounted = 0;
  //   this.getAllLicense.forEach(item => {
  //     if (this.numberOfUser >= item.minimumUserCap && this.numberOfUser <= item.maximumUserCap) {
  //       discounted = item.discountedPercentagePerUser * 0.01;
  //     }
  //   });
  //   return discounted;
  // }

  onstage(discount) {
    const inv = discount;
  }

  onClickChanges($event) {
    console.log("am here");
    this.select = true;
    this.selectId = $event;
    console.log(this.select);
    console.log(this.getAllLicense);

    console.log($event);
  }

  onProceedToPayment() {
    if (this.selectedDuration.id === 0) {
      this.sendErrorMessage("Select a plan");

      this.focusBox.nativeElement.focus();

      return;
    }

    this.btnSpinner = true;
    // console.log(this.noOfUsers);
    // console.log(this.durationCount);
    // console.log(this.totalPrice);

    const plan = {
      licenseTypeId: this.selectedDuration.id, // BucketId
      licenseName: "Bronzze", // BucketName
      numberOfusers: this.selectedDuration.maximumUserCap, // BucketmaximumUserCap
      licenseuserCharge: this.selectedDuration.amount,
      numberOfDays: this.selectedDuration.valueDays,
      currency: "NGN"
    };

    this.service
      .saveLicense(plan)
      .toPromise()
      .then((res) => {
        this.btnSpinner = false;

        const id = res;

        const planDetails = {
          id: this.selectedDuration.crmPlanId,
          amount: this.selectedDuration.amount,
          licensePayementId: id
        };

        // stringify the object
        const planString = JSON.stringify(planDetails);

        // encypt plan object
        //  planString = this.encryptionService.encrypt(planString);

        this.router.navigate(["/pay"], {
          queryParams: {plan: encodeURIComponent(planString)}
        });
      })
      .catch((error) => {
        this.btnSpinner = false;
        this.sendErrorMessage(
          "An error occurred while trying to create plan"
        );
      });

    // call service to save the licence info
  }

  returnOnClick() {
    this.agree = true;
    // return this.licensingForm.get('terms_agreement') =  this.agree;
  }
}
