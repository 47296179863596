import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { MatDatepickerInputEvent, MatTableDataSource, throwMatDialogContentAlreadyAttachedError } from "@angular/material";
import { MatCalendar, MatCalendarCellCssClasses } from "@angular/material/datepicker";
import { CalendarViewModel } from "../../../core/models/view-model/calendar-event";
// import { DocketService } from "../../core/services/docket.service";
import { isNullOrUndefined } from "util";
import { CalendarService } from "src/app/core/services/calendar.service";

@Component({
  selector: "app-calender-widget",
  templateUrl: "./calender-widget.component.html",
  styleUrls: ["./calender-widget.component.scss"]
})
export class CalenderWidgetComponent implements OnInit, OnChanges {
  @Output() dateChange = new EventEmitter<MatDatepickerInputEvent<any>>();
  @Input() upcomingCalendarEvents: CalendarViewModel[] = [];
  selectedDate: any;
  // CalendarView = CalendarView;
  currentYear: number;
  currentMonth: number;
  todaysDate: number;
  viewDate: Date = new Date();

  // viewDate: Date = new Date();
  @ViewChild("calendar") calendar: MatCalendar<Date>;

  constructor(
    private resolver : ComponentFactoryResolver,
    private calendarService: CalendarService) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

    this.upcomingCalendarEvents =
      changes.upcomingCalendarEvents.currentValue;
    console.log(this.upcomingCalendarEvents);
    this.calendar.updateTodaysDate();
  }

  // ngAfterContentInit(){
  //   const sampleFactory = this.resolver.resolveComponentFactory(SampleComponent);
  //   this.container.createComponent(sampleFactory);
  // }

  prevMonth() {
    this.viewDate = new Date(
      this.viewDate.setMonth(this.viewDate.getMonth() - 1)
    );

    this.calendar.activeDate = this.viewDate; // set the active date to the selected date
    this.calendar.updateTodaysDate(); // update calendar state



    const month = this.getActualMonth(this.viewDate);
    const year = this.getActualYear(this.viewDate);
    console.log(month);
    console.log(year);
    console.log(this.viewDate);

    this.calendarService.loadCourtProceedingCalendar(month, year).subscribe(
      (calendarEventData) => {
        console.log(calendarEventData);
        if (!isNullOrUndefined(calendarEventData)) {
          if (calendarEventData.length > 0) {
            this.upcomingCalendarEvents = this.upcomingCalendarEvents.concat(
              calendarEventData
            );
            console.log(this.upcomingCalendarEvents )
            this.dateNewClass(this.viewDate);
          }
        }
      },
      (err) => console.log("======>", err)
    );
  }

  nextMonth() {
    this.viewDate = new Date(
      this.viewDate.setMonth(this.viewDate.getMonth() + 1)
    );

    this.calendar.activeDate = this.viewDate; // set the active date to the selected date
    this.calendar.updateTodaysDate(); // update calendar state


    const month = this.getActualMonth(this.viewDate);
    const year = this.getActualYear(this.viewDate);
    console.log(this.viewDate);
    console.log(month);
    console.log(year);
    this.calendarService.loadCourtProceedingCalendar(month, year).subscribe(
      (calendarEventData) => {
        console.log(calendarEventData);
        if (!isNullOrUndefined(calendarEventData)) {
          if (calendarEventData.length > 0) {
            this.upcomingCalendarEvents = this.upcomingCalendarEvents.concat(
              calendarEventData
            );
            console.log(this.upcomingCalendarEvents )
            this.dateNewClass(this.viewDate);
          }
        }
      },
      (err) => console.log("======>", err)
    );
  }

  getCurrentYear(): number {
    let currentMonth: number;
    currentMonth = new Date().getFullYear();

    return currentMonth;
  }

  getCurrentMonth(): number {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = new Date().getMonth() + 1;
    const date = new Date();
    console.log(date.getMonth());

    return currentMonth;
  }

  getActualMonth(data: Date) {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = data.getMonth() + 1;
    return currentMonth;
  }

  getActualYear(data: Date) {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = data.getFullYear();
    return currentMonth;
  }
  dateClass() {
    const currentDate = new Date();

    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.upcomingCalendarEvents
        .map((strDate) => new Date(+strDate.date))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );

      const highlightCurrentDate = this.upcomingCalendarEvents
        .map((strDate) => new Date(+strDate.date))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            currentDate.getDate() === d.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );
      // console.log(highlightDate);
      if (highlightCurrentDate) {
        return "highlight-current-date-class";
      } else if (highlightDate) {
        return "highlight-date-class";
      } else {
        return "";
      }
    };
  }

  dateNewClass(currentDate: Date) {
    // const currentDate = new Date();
     console.log( this.upcomingCalendarEvents    )
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.upcomingCalendarEvents
        .map((strDate) => new Date(+strDate.date))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()

        );

      const highlightCurrentDate = this.upcomingCalendarEvents
        .map((strDate) => new Date(+strDate.date))
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            currentDate.getDate() === d.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );
       console.log(highlightDate);
       console.log(highlightCurrentDate);
      if (highlightCurrentDate) {
        return "highlight-current-date-class";
      } else if (highlightDate) {
        return "highlight-date-class";
      } else {
        return "";
      }
    };
  }

  onDateChange($event) {
    this.dateChange.emit($event);
  }
}
