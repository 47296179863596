import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";

@Component({
  selector: "app-error-notifier",
  templateUrl: "./error-notifier.component.html",
  styleUrls: ["./error-notifier.component.scss"]
})
export class ErrorNotifierComponent extends Notifier implements OnInit {
  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>) {
    super(headerStore);
  }

  ngOnInit() {
  }
}
