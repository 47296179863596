import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import * as fromStore from "../../../core/store";

@Component({
  selector: "app-timi-widget",
  templateUrl: "./timi-widget.component.html",
  styleUrls: ["./timi-widget.component.scss"]
})
export class TimiWidgetComponent implements OnInit {
  user = new UserDetail();
  isShowIntro: boolean;
  isShowchatBaloon: boolean;
  timiLink: string;

  constructor(private store: Store<fromStore.State>) {
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      console.log(data);
      this.user = data;
    });
  }

  ngOnInit() {
    this.isShowIntro = true;
    this.isShowchatBaloon = false;
    this.timiLink =
      "  https://timi-staging.lawpavilion.com/?pleaseallow=true&host=aPXNxphB8b3hOOM&name=Hfally&client=1";
    // this.timiLink = `https://timi-staging.lawpavilion.com/?pleaseallow=true&host=aPXNxphB8b3hOOM&name=${this.user.firstName}&client=${this.user.userId}`
  }

  openChatBot() {
    this.isShowchatBaloon = true;
    console.log("calling timi");
  }
}
