import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";

@Component({
  selector: "app-connection-global-alert",
  templateUrl: "./connection-global-alert.component.html",
  styleUrls: ["./connection-global-alert.component.scss"]
})
export class ConnectionGlobalAlertComponent extends Notifier implements OnInit {
  // notificationMessages: string[] = [
  //     'you edited succesfully', 'you created a brief succesfully'
  //   ];
  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>) {
    super(headerStore);
  }

  ngOnInit() {
    // this.notificationCount = this.notificationMessages.length;
  }
}
