import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../core/auth-guard.service";

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: '../search-results/search-results.module#SearchResultsModule',
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'manage-user/:userId/firm/:firmId',
  //   loadChildren:
  //     '../user-management/user-management.module#UserManagementModule ../search-results/search-results.module#SearchResultsModule'
  //   // canActivate: [AuthGuard]
  // },
  // {
  //   path: 'contact/:userId/firm/:firmId',
  //   loadChildren: '../contact/contact.module#ContactModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'firm',
  //   loadChildren: '../user-dashboard/user-dashboard.module#UserDashboardModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'matter/:userId/firm/:firmId',
  //   loadChildren: '../matter/matter.module#MatterModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'invoices',
  //   loadChildren: '../invoices/invoices.module#InvoicesModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'documents',
  //   loadChildren: '../documents/documents.module#DocumentsModule'
  //   // canLoad: [AuthGuard]
  // },
  // { path: 'license-payment', component: LicensePaymentComponent },
  // {
  //   path: 'tasks/:userId/firm/:firmId',
  //   loadChildren: '../tasks/tasks.module#TasksModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'calendar',
  //   loadChildren: '../my-calendar/my-calendar.module#MyCalendarModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'form-widgets',
  //   loadChildren: '../form-widgets/form-widgets.module#FormWidgetsModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'mailbox',
  //   loadChildren: '../mailbox/mailbox.module#MailboxModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'new-brief',
  //   loadChildren: '../form-widgets/form-widgets.module#FormWidgetsModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'search',
  //   loadChildren: '../search-results/search-results.module#SearchResultsModule',
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'all-notifications',
  //   loadChildren: '../notifications/notifications.module#NotificationsModule'
  //   // canLoad: [AuthGuard]
  // },
  // {
  //   path: 'ng',
  //   loadChildren: '../home/home.module#HomeModule',
  //   canActivate: [HomeGuard]
  // },
  // { path: 'draft', loadChildren: '../lp-doc/lp-doc.module#LpDocModule' }
  //   {
  //     path: '',
  //     loadChildren: '../authentication/authentication.module#AuthenticationModule',
  //     // data: { preload: true }
  //   },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuard]
})
export class SharedRoutingModule {
}
