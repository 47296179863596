import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { ExpenseParseModel } from "../models/parse-model/add-expense";
import { InvoiceParseModel } from "../models/parse-model/add-invoice";
import { InvoiceItemParseModel } from "../models/parse-model/add-invoice-item";
import { InvoiceMailParseModel } from "../models/parse-model/add-invoice-mail";
import { PaymentParseModel } from "../models/parse-model/add-payment";
import { MailReceiptParseModel } from "../models/parse-model/mail-reciept";
import { Endpoints } from "../models/shared-model/endpoint";
import { InvoiceViewModel, PayableModel } from "../models/view-model/invoice";
import { PaymentListViewModel } from "../models/view-model/payment-list";

@Injectable({
  providedIn: "root"
})
export class InvoiceService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  public getClientExpenses(
    clientId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number,
    pageSize,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `invoice/${clientId}/ClientExpenses`;
    // tslint:disable-next-line:max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    console.log(url);

    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getClientTimeSheets(
    clientId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number,
    pageSize,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `invoice/${clientId}/ClientTimeSheets`;
    // tslint:disable-next-line:max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    console.log(url);

    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getInvoiceList(
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number,
    pageSize,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "invoice/GetFirmInvoice";
    // tslint:disable-next-line:max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    console.log(url);

    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getUnpaidInvoiceList(
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number,
    pageSize,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "invoice/GetFirmunpaidInvoice";
    // tslint:disable-next-line:max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    console.log(url);
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getFirmunpaidInvoice(
    searchQuery = "",
    sort = "",
    order = "asc",
    page: number,
    pageSize,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "invoice/GetFirmunpaidInvoice";
    // tslint:disable-next-line:max-line-length
    const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;

    console.log(url);

    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getInvoiceSummary(): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "invoice/invoicesummary";
    return this.httpClient.get<any>(endpoint);
  }

  public saveInvoice(invoice: InvoiceParseModel): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + "invoice";
    return this.httpClient.post<number>(endpoint, invoice);
  }

  public updateInvoice(
    invoice: InvoiceItemParseModel,
    invoiceId: number
  ): Observable<number> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "invoice/item";
    return this.httpClient.put<number>(endpoint, invoice);
  }

  public editInvoice(model: any): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + "invoice";
    return this.httpClient.put<number>(endpoint, model);
  }

  public getInvoiceDetails(invoiceId: number): Observable<InvoiceViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `invoice/${invoiceId}/Details`;
    return this.httpClient.get<InvoiceViewModel>(endpoint);
  }

  public saveExpense(expense: ExpenseParseModel): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + "expense";
    return this.httpClient.post<number>(endpoint, expense);
  }

  public mailReceipt(receipt: MailReceiptParseModel): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + "expense";
    return this.httpClient.post<number>(endpoint, receipt);
  }

  public mailInvoice(invoice: InvoiceMailParseModel): Observable<number> {
    const endpoint = this.config.getConfig().serverEndpoint + "expense";
    return this.httpClient.post<number>(endpoint, invoice);
  }

  getPayments(invoiceId: number): Observable<PaymentListViewModel[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `invoice/${invoiceId}/Payments`;
    return this.httpClient.get<PaymentListViewModel[]>(endpoint);
  }

  public savePayment(payment: PaymentParseModel) {
    const endpoint =
      this.config.getConfig().serverEndpoint + "invoice/payments";
    return this.httpClient.post<number>(endpoint, payment);
  }

  public updatePayment(payment: PaymentListViewModel) {
    const endpoint =
      this.config.getConfig().serverEndpoint + "invoice/payments";
    return this.httpClient.put<PaymentListViewModel>(endpoint, payment);
  }

  public removePayment(paymentId: number) {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `invoice/payments/${paymentId}`;
    return this.httpClient.delete<PaymentListViewModel>(endpoint);
  }

  public removeInvoice(invoiceId: number) {
    const endpoint =
      this.config.getConfig().serverEndpoint + `invoice/${invoiceId}`;
    return this.httpClient.delete<any>(endpoint);
  }

  public invoicePayable(): Observable<PayableModel[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Dashboard/invoicepayable";
    return this.httpClient.get<PayableModel[]>(endpoint);
  }

  public sendInvoiceMail(model: any) {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "Notification/sendInvoiceMail";
    return this.httpClient.post<any>(endpoint, model);
  }

  public sendInvoicReceipt(model: any) {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "Notification/sendreceiptmail";
    return this.httpClient.post<any>(endpoint, model);
  }
}
