import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { GenericListViewModel } from "../models/generic-model.ts/generic-list";
import { Endpoints } from "../models/shared-model/endpoint";

@Injectable({
  providedIn: "root"
})
export class GenericService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  getPermissions(): Observable<GenericListViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint + "permissions/Account";
    return this.httpClient.get<GenericListViewModel[]>(url);
  }

  getMatterPermissions(): Observable<GenericListViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint + "permissions/Matter";
    return this.httpClient.get<GenericListViewModel[]>(url);
  }

  getCountries(): Observable<GenericListViewModel[]> {
    // const url = this.config.getConfig().serverEndpoint + 'permissions';

    //  return this.httpClient.get<GenericListViewModel []>(url);

    const res: GenericListViewModel[] = [{id: 1, name: "Nigeria"}];

    return of(res);
  }

  getActivities(): Observable<GenericListViewModel[]> {
    // const url = this.config.getConfig().serverEndpoint + 'permissions';

    // return this.httpClient.get<GenericListViewModel []>(url);

    return of([]);
  }

  getCountryState(countryId = 1): Observable<GenericListViewModel[]> {
    // const url = this.config.getConfig().serverEndpoint + 'permissions';

    //  return this.httpClient.get<GenericListViewModel []>(url);

    const res: GenericListViewModel[] = [{id: 1, name: "Lagos"}];

    return of(res);
  }

  getStates(countryId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint + "/states/" + countryId;
    return this.httpClient.get(url);
  }
}
