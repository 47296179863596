import { Component, OnInit, Output, ViewChild, ElementRef, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProceedingsService } from 'src/app/core/services/proceedings.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-end-proceedings',
  templateUrl: './end-proceedings.component.html',
  styleUrls: ['./end-proceedings.component.scss']
})
export class EndProceedingsComponent implements OnInit, OnChanges {

  endProceedingsForm: FormGroup;
  stages: any[] = [];
  currentMatterId: number;
  isLoading= false;
  @Output('OnCompletion') OnCompletion = new EventEmitter();
  @ViewChild('close') close:ElementRef
  @Input() timeSpent: number;
  @Input() proceedingId: number;
  mindate: string;
  constructor(private fb:FormBuilder, private proceedingsService:ProceedingsService, private route:ActivatedRoute) { 
    // SAVE PROCEEDINGS FORM BUILDER
    this.currentMatterId = +this.route.parent.snapshot.paramMap.get('id')
    this.endProceedingsForm = this.fb.group({
      appreanceDate:["", [Validators.required, this.isMindate]],
      appreanceTime:["", Validators.required],
      summary:["", Validators.required],
      matterId:[this.currentMatterId, Validators.required],
      isRemote:["", Validators.required],
      nextStageTypeId:["", Validators.required],
      estimatedTime:["", Validators.required]
    })
  }
  
  ngOnChanges(){
  }
  ngOnInit() {

    // SET MIN OF FORM TO TODAYS DATE
    this.mindate = new Date().toISOString().split("T")[0];
    console.log(this.mindate)
    
    // SERVICE TO GET STAGE TYPES
    this.proceedingsService.getProceedingsStageType()
    .subscribe(data=>{
      console.log(data)
      data.forEach(element => {
        this.stages.push(element);
      });
      // this.stages = data;

      console.log(typeof this.stages);
    },
    err=>{
      console.log(err);
    })



  }
  
  // VALIDATOR FUNCTION
  private isMindate(control:FormControl):{[key:string]:any}{
    let val = new Date(control.value).getTime();
    let min = new Date(new Date().toISOString().split("T")[0]).getTime();
    return (val < min)? {'isLessThanMin':`${control.value}`}: null
  }

  //PROCEEDING FORM GETTERS
  get appreanceDate() {
    return this.endProceedingsForm.get("appreanceDate");
  }
  get appreanceTime() {
    return this.endProceedingsForm.get("appreanceTime");
  }
  get summary() {
    return this.endProceedingsForm.get("summary");
  }
  get matterId() {
    return this.endProceedingsForm.get("matterId");
  }
  get isRemote() {
    return this.endProceedingsForm.get("isRemote");
  }
  get nextStageTypeId() {
    return this.endProceedingsForm.get("appreanceDate");
  }
  get estimatedTime() {
    return this.endProceedingsForm.get("estimatedTime");
  }

  // SAVE PROCEEDING FUNCTION
  endProceedings(value){
    this.isLoading = true;
    console.log(value)
    let obj = {
      summary:value.summary,
      matterId:value.matterId,
      estimatedTime:value.estimatedTime,
      isRemote: !!(+value.isRemote),
      nextStageTypeId: +value.nextStageTypeId,
      timeSpent: this.timeSpent,
      proceedingId:this.proceedingId,
      appreanceDate: `${new Date(`${value.appreanceDate} ${value.appreanceTime}`).getTime()}`
    }
    console.log(obj)
    this.proceedingsService.saveProceedings(obj).subscribe(
      data=>{
        if(data === true){
          console.log(data)
          this.isLoading = false;
          this.OnCompletion.emit(data);
          this.close.nativeElement.click();
        }
      },err=>{
        this.isLoading = false;
        console.log(err)
      }
    )
  }
}

export interface proceeding{
  appreanceDate: string,
  summary: string,
  matterId: number,
  isRemote: boolean,
  nextStageTypeId: number,
  estimatedTime: number
}