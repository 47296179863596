import { Location } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {
  constructor(private _location: Location) {
  }

  goBack() {
    this._location.back();
  }

  // goto(location: string) {
  //     }

  // format date
  formatDate(date) {
    const newDate = new Date(date);
    return `${
      newDate.getMonth() + 1
    }/${newDate.getDate()}/${newDate.getFullYear().toString().slice(2)}`;
  }
}
