import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { RenameDocumentModel } from "../../../core/models/view-model/document-rename";

@Component({
  selector: "app-rename-item",
  templateUrl: "./rename-item.component.html",
  styleUrls: ["./rename-item.component.scss"]
})
export class RenameItemComponent implements OnInit {
  renameModalForm: FormGroup;
  newName = new RenameDocumentModel();
  @Input() btnSpinner$: Observable<boolean> = of(false);
  @Input() oldName: string;
  @Output() emitNewName = new EventEmitter();
  btnSpinner: boolean;
  @ViewChild("close_modal_editFirm") close_modal_editFirm: ElementRef;

  constructor(private fb: FormBuilder, private router: Router) {
    this.btnSpinner$.subscribe((result) => {
      this.btnSpinner = result;
    });

    this.renameModalForm = fb.group({
      documentNewName: ["", Validators.required]
    });
  }

  get documentNewName() {
    return this.renameModalForm.get("documentNewName");
  }

  ngOnInit() {
  }

  renameDocument() {
    this.close_modal_editFirm.nativeElement.click();
    this.newName.name = this.renameModalForm.value.documentNewName;

    this.emitNewName.emit(this.newName.name);
  }
}
