import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";

import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { FirmRole } from "../../../core/models/view-model/role-list";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import * as fromStore from "../../../core/store";
import * as FirmRoleActions from "../../../core/store/actions/firm-roles.action";
import * as fromFirmRoles from "../../../core/store/reducers/firm-roles.reducer";

@Component({
  selector: "app-change-role-modal",
  templateUrl: "./change-role-modal.component.html",
  styleUrls: ["./change-role-modal.component.scss"]
})
export class ChangeRoleModalComponent implements OnInit, OnChanges {
  rolePermission: any[] = [];
  currentRole: FirmRole;
  roleControl = new FormControl("");
  @Input() selectedRoleId: number;

  @Output() emitRoleSelected = new EventEmitter();
  @ViewChild("closeChooseRoleModal") closeChooseRoleModal: ElementRef;

  firmRoles: FirmRole[] = [];

  roleId: number;
  @Input() btnSpinner$: Observable<boolean> = of(false);

  user = new UserDetail();

  constructor(
    public roleStore: Store<fromFirmRoles.FirmRoleState>,
    private store: Store<fromStore.State>
  ) {
    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      // / save token and user detail to local storage
      this.user = data;
    });

    this.store
      .pipe(select(fromFirmRoles.getFirmRoles))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.firmRoles = data;
          console.log(this.firmRoles);
        }
      });
  }

  ngOnInit() {
    this.roleStore.dispatch(
      new FirmRoleActions.LoadRoles(this.user.firmId)
    );

    // this.roleStore.pipe(select(fromFirmRoles.getFirmRoles)).subscribe(data => {
    //   this.firmRoles = data;
    //   console.log(this.firmRoles);
    // });
  }

  ngOnChanges(changes) {
    // if the Id selected to change role is changed

    console.log(changes);

    console.log(this.selectedRoleId);

    console.log(this.firmRoles);

    if (!isNullOrUndefined(this.selectedRoleId)) {
      const found = this.firmRoles.find(
        (c) => c.id === this.selectedRoleId
      );

      console.log(found);

      if (!isNullOrUndefined(found)) {
        this.currentRole = found;
        this.roleControl.patchValue(this.currentRole.id);

        this.rolePermission = this.currentRole.firmRolePermissions;
      }
    }

    // watch btn spinner if the old value is true and the new value is false, then close the modal
  }

  OnRoleSelected(selectedRoleId: number) {
    console.log(selectedRoleId);

    console.log(this.firmRoles);

    if (!isNullOrUndefined(selectedRoleId)) {
      const found = this.firmRoles.find((c) => c.id === selectedRoleId);

      console.log(found);

      if (!isNullOrUndefined(found)) {
        this.currentRole = found;
        this.rolePermission = this.currentRole.firmRolePermissions;
      }
    }

    // emit selected
  }

  saveRoleChange(data) {
    console.log(data);
    // this.btnSpinner$ = of(true);
    this.emitRoleSelected.emit(this.currentRole);
    this.closeChooseRoleModal.nativeElement.click();
    // button should be passed with of
  }
}
