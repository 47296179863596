import { ChangeDetectionStrategy, Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";

import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from "angular-calendar";

import { endOfDay, isSameDay, isSameMonth, startOfDay } from "date-fns";
import { Subject } from "rxjs";
import { isNullOrUndefined } from "util";
import { CalendarViewModel, EventHelper } from "../../../core/models/view-model/calendar-event";
import { TaskDetailViewModel } from "../../../core/models/view-model/task-detail";
import { CalendarService } from "../../../core/services/calendar.service";
import * as CalendarActions from "../../../core/store/actions/calendar-lander.actions";
import * as fromStore from "../../../core/store/reducers/calendar-lander.reducer";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../../shared/base-class/notifier";

const eventType: any = {
  endorsement: {
    primary: "#D21F3C",
    secondary: "#D21F3C"
  },
  reminder: {
    primary: "#1C59A0",
    secondary: "#1C59A0"
  },
  application: {
    primary: "#ff9000",
    secondary: "#ff9000"
  },

  task: {
    primary: "#ADCF1A",
    secondary: "#ADCF1A"
  }
};

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class EventComponent extends Notifier implements OnInit {
  viewDate: Date = new Date();
  eventHelper = new EventHelper();
  isError: boolean;

  // start

  detailTitle: string;
  detailType: string;
  detailDate: any;
  emittedUsersByChoseUser: any;

  startTime = new FormControl();

  previewEvent: any;

  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;

  // stop

  @ViewChild("clickViewDetail")
  clickViewDetail: ElementRef;
  @ViewChild("modalContent")
  modalContent: TemplateRef<any>;

  @ViewChild("detailModal")
  detailModal: ElementRef;

  CalendarView = CalendarView;
  curremtYear: number;
  currentMonth: number;

  view: CalendarView;
  todayDate = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarViewModel[] = [];

  todayEvents: CalendarViewModel[] = [];
  public editModal = new CalendarViewModel();

  newEventForm: FormGroup; // just a reminder for now
  editEventForm: FormGroup;

  activeDayIsOpen: boolean;

  btnSpinner: boolean;

  // successText: string;
  // errorText: string;
  // showSuccessMessage: boolean;
  // showErrorMessage: boolean;
  showTodayPane: boolean;

  @ViewChild("messageBox")
  messageBox: ElementRef;
  @ViewChild("errorBox") errorBox: ElementRef;
  @ViewChild("closeNewEventModal")
  closeNewEventModal: ElementRef;

  constructor(
    private router: Router,
    private modal: NgbModal,
    private calendarStore: Store<fromStore.CalendarState>,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>,
    private fb: FormBuilder,
    private calendarService: CalendarService
  ) {
    super(headerStore);
    this.newEventForm = this.fb.group({
      title: ["", Validators.required],
      description: [""],
      start: ["", Validators.required]
    });
    this.editEventForm = this.fb.group({
      title: ["", Validators.required],
      description: [""],
      start: ["", Validators.required]
    });

    this.LoadCalendarData();

  }

  LoadCalendarData() {

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {
          this.view = _calendarState.currentCalendarView;
        }
      });

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {

          _calendarState.calendarEvents.forEach(
            data => {
              let index =  this.events.findIndex(x=>x.resourceId === data.resourceId);
              if(index < 0){
                this.events.push(data)
              }
              else{}
            }
          )
          this.refresh.next();
        }
      });

      this.LoadCourtProceedingData();

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {
          this.curremtYear = _calendarState.calendarYear;
          this.currentMonth = _calendarState.calendarMonth;
           this.refresh.next();
        }
      });

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {
          this.todayEvents = Array.from(new Set(_calendarState.todayCalendarEvent));
        }
      });

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {
          this.btnSpinner = _calendarState.isAddingCalendar;
        }
      });
    // this.calendarStore.pipe(select('calendar-reducer')).subscribe( (_calendarState: fromStore.CalendarState)  => {
    //   if (!isNullOrUndefined(_calendarState)) {
    //      this.showSuccessMessage = _calendarState.isAddedCalendar;
    //      this.messageBox.nativeElement.focus();
    //   }
    // });

    this.calendarStore
      .pipe(select("calendar-reducer"))
      .subscribe((_calendarState: fromStore.CalendarState) => {
        if (!isNullOrUndefined(_calendarState)) {
          // this.errorText = _calendarState.calendarError;

          // tslint:disable-next-line:triple-equals
          if (_calendarState.calendarError !== "") {
            // this.showErrorMessage = true;
            this.sendErrorMessage(_calendarState.calendarError);
            // this.errorBox.nativeElement.focus();
          } else if (_calendarState.isAddedCalendar) {
            this.closeNewEventModal.nativeElement.click();
            // this.sendErrorMessage(_calendarState.isAddedCalendar);
            // this.showSuccessMessage = _calendarState.isAddedCalendar;
            this.messageBox.nativeElement.focus();
          }
        }
      });

  }

  get title() {
    return this.newEventForm.get("title");
  }

  get description() {
    return this.newEventForm.get("description");
  }

  get start() {
    return this.newEventForm.get("start");
  }

  get editTitle() {
    return this.editEventForm.get("title");
  }

  get editDescription() {
    return this.editEventForm.get("description");
  }

  get editStart() {
    return this.editEventForm.get("start");
  }

  ngOnInit() {
    const a = "judge";
    this.activeDayIsOpen = false;
    this.btnSpinner = false;
    this.isError = false;
    // this.showErrorMessage = false;

    this.showTodayPane = false;

    this.calendarStore.dispatch(
      new CalendarActions.ChangeCalendar(CalendarView.Month)
    );
    const dateMonth = this.viewDate.getMonth() + 1;
    const dateYear = this.viewDate.getFullYear();
    this.calendarStore.dispatch(
      new CalendarActions.LoadCalendarEvents({
        month: dateMonth,
        year: dateYear
      })
    );
    this.calendarStore.dispatch(
      new CalendarActions.LoadCalendarEventsTask({
        month: dateMonth,
        year: dateYear
      })
    );

  }

  LoadCourtProceedingData() {

    const month = this.getActualMonth(this.viewDate);
    const year = this.getActualYear(this.viewDate);

    this.calendarService.loadCourtProceedingCalendar(month, year).subscribe(
      (calendarEventData) => {
        console.log(calendarEventData);
        if (!isNullOrUndefined(calendarEventData)) {
          let newFormedData = [];
          calendarEventData.forEach(calendarData => {
            calendarData = this.eventHelper.shaper(calendarData);
            newFormedData.push(calendarData);
          })
          this.events = [...this.events, ...newFormedData]

          newFormedData.forEach(
            data => {
              let index =  this.events.findIndex(x=>x.resourceId === data.resourceId);
              if(index < 0){
                this.events.push(data)
              }
              else{}
            }
          )

          this.refresh.next();
        }
      },
      (err) => console.log("======>", err)
    );
  }

  getActualMonth(data: Date) {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = data.getMonth() + 1;
    return currentMonth;
  }

  getActualYear(data: Date) {
    let currentMonth: number;
    // the get month method picks january as 0 and febuary as 1 (0-11 (Jan- dec))
    currentMonth = data.getFullYear();
    return currentMonth;
  }

  hideToday() {
    this.showTodayPane = !this.showTodayPane;
  }

  saveEvent() {
    this.btnSpinner = true;
    // starting point
    const SaveEventModel = new CalendarViewModel();
    SaveEventModel.title = this.title.value;
    SaveEventModel.description = this.description.value;


    SaveEventModel.date = (+new Date(
      this.start.value + " " + this.startTime.value
    )).toString();

    if (this.newEventForm.valid && !isNullOrUndefined(SaveEventModel.date) && SaveEventModel.date != 'NaN') {
      this.calendarStore.dispatch(
        new CalendarActions.AddCalendarEvent(SaveEventModel)
      );
      this.newEventForm.reset();
      this.LoadCalendarData();
      this.closeNewEventModal.nativeElement.click();
      this.btnSpinner = false;
    }
    else{
      this.sendErrorMessage('An error occurred, please try again');
      this.closeNewEventModal.nativeElement.click();
      this.btnSpinner = false;
    }
  }

  editEvent() {
    const editEventModel = new CalendarViewModel();
    editEventModel.title = this.editTitle.value;
    editEventModel.desc = this.editDescription.value;
    editEventModel.date = (+new Date(this.editStart.value)).toString();
    this.editEventForm.patchValue({
      title: editEventModel.title,
      description: editEventModel.desc,
      start: editEventModel.date
    });
    console.log(editEventModel);
  }

  editModalValue(item: any) {
    this.editModal.title = item.title;
    this.editModal.start = item.start;
    console.log(this.editModal);
  }

  deleteItemEvent(item) {
    const itemSearch = this.todayEvents.find(
      (ev) => ev.title === item.title
    );
    if (itemSearch) {
      const removeEvent = this.todayEvents.indexOf(itemSearch);
      this.todayEvents.splice(removeEvent, 1);
      console.log(`${item.title} deleted`);
    }
  }

  dateViewChanged(changedDate) {
    console.log(changedDate);
    console.log(this.viewDate);

    // tslint:disable-next-line:triple-equals
    if (
      this.viewDate.getMonth() + 1 !== this.currentMonth ||
      this.viewDate.getFullYear() !== this.curremtYear
    ) {
      // date month changed or year changed, call for new events for the month
      const dateMonth = this.viewDate.getMonth() + 1;
      const dateYear = this.viewDate.getFullYear();
      console.log(dateMonth);
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsApplication({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsProceeding({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsTask({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEvents({
          month: dateMonth,
          year: dateYear
        })
      );
      this.LoadCourtProceedingData();
    }
  }

  todaySelected(changedDate) {
    console.log(changedDate);
    console.log(this.viewDate);

    // tslint:disable-next-line:triple-equals
    if (
      this.viewDate.getMonth() !== this.currentMonth ||
      this.viewDate.getFullYear() !== this.curremtYear
    ) {
      // date month changed or year changed, call for new events for the month
      const dateMonth = new Date().getMonth() + 1; // this is because the getMonth function always return thae actual month -1
      const dateYear = new Date().getFullYear();
      console.log(dateMonth);
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsApplication({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsProceeding({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsTask({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEvents({
          month: dateMonth,
          year: dateYear
        })
      );
    }
  }

  dateDecreased(changedDate) {

    // tslint:disable-next-line:triple-equals
    if (
      this.viewDate.getMonth() - 1 !== this.currentMonth ||
      this.viewDate.getFullYear() !== this.curremtYear
    ) {
      // date month changed or year changed, call for new events for the month
      const dateMonth = this.viewDate.getMonth() + 1; // this is because the getMonth function always return thae actual month -1
      const dateYear = this.viewDate.getFullYear();
      console.log(dateMonth);
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsApplication({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsProceeding({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEventsTask({
          month: dateMonth,
          year: dateYear
        })
      );
      this.calendarStore.dispatch(
        new CalendarActions.LoadCalendarEvents({
          month: dateMonth,
          year: dateYear
        })
      );
      this.LoadCourtProceedingData();
    }
  }

  dayClicked({
               date,
               events
             }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  // closeSuccess() {
  //   this.showSuccessMessage = false;
  // }
  // closeError() {
  //   this.showErrorMessage = false;
  // }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent("Dropped or resized", event);
    this.refresh.next();
  }

  handleEvent(action: string, event: any): void {
    this.modalData = {event, action};
    // this.modal.open(this.modalContent);
    // this.router.navigate(['/tasks/' + event.id + '/firm'])
    if (event.isTask) {
      this.detailType = "Task";
    } else if (event.isRemider) {
      this.detailType = "Reminder";
    } else if (event.isApplication) {
      this.detailType = "Application";
    } else if (event.isEndorsement) {
      this.detailType = "Endorsement";
    }

    this.previewEvent = event;

    this.detailTitle = event.title;
    this.detailDate = event.date;
    this.clickViewDetail.nativeElement.click();
    console.log(event);
    console.log(event.title);
  }

  addEvent(): void {
    this.events.push(
      this.eventHelper.shaper({
        title: "New event",
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: eventType.judgement,
        draggable: true,
        isRemider: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      })
    );
    this.refresh.next();
  }

  sendUserUpdate(event) {
    console.log(event);
    this.emittedUsersByChoseUser = event;
  }

  confirmDelete(data, opt: any) {
    console.log(data);
    if (opt === "deleteReminder") {
      this.detailModal.nativeElement.click();

      this.confirmType = "deleteReminder";
      this.confirmTitle = "Delete Calendar Reminder";
      this.confirmMessage =
        "Are you sure you want to delete this reminder?";
      this.confirmAction = "Yes, Delete";
      this.confirmElementData = data;
    }
  }

  confirmResponse(data: any) {
    console.log(data);
    if (data.type === "deleteReminder") {
      this.deleteReminder(data.elementData);
    }
  }

  deleteReminder(data: any) {
    let del_data = new CalendarViewModel();
    del_data = data;
    console.log(del_data);

    del_data = this.events.find(
      (c) => c.resourceId === del_data.resourceId && c.isRemider === true
    );
    const del_today = this.todayEvents.find(
      (c) => c.resourceId === del_data.resourceId && c.isRemider === true
    );
    console.log(del_data);

    if (!isNullOrUndefined(del_data)) {
      console.log(del_data);

      this.calendarService
        .deleteCalendarReminder(del_data.resourceId)
        .toPromise()
        .then((res) => {
          // / alert('here')
          console.log(res);

          const index = this.events.indexOf(del_data);

          this.events.splice(index, 1);

          this.refresh.next();

          if (!isNullOrUndefined(del_today)) {
            const index = this.todayEvents.indexOf(del_today);

            this.todayEvents.splice(index, 1);
          }

          this.pushMessage("you deleted a reminder");
        })
        .catch((error) => {
          alert("Deleting Reminder Failed!");
        });
    }
  }

  newTaskEmit(value: TaskDetailViewModel) {
    console.log(value);

    this.events.push(
      this.eventHelper.shaper({
        title: value.name,
        start: startOfDay(new Date(+value.dueDate)),
        end: endOfDay(new Date(+value.dueDate)),
        color: eventType.task,
        isTask: true,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      })
    );

    this.refresh.next();
  }

  pushNewAppearanceToCalendar(event) {
    console.log(event);

    event.date.forEach((element) => {
      this.events.push(
        this.eventHelper.shaper({
          title: "Matter Appearance " + "at " + event.briefStage,
          start: startOfDay(new Date(+element)),
          end: endOfDay(new Date(+element)),
          color: eventType.endorsement,
          isEndorsement: true,
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true
          }
        })
      );
    });

    this.refresh.next();
  }
}
