import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CurrentUserService } from "../../core/services/factories/current-user.service";
import { FileUploadService } from "../../core/services/file-upload.service";
import { NotificationService } from "../../core/services/notification.service";
import { MattersEffects } from "../../core/store/effects/matters.effects";
import { TaskEffects } from "../../core/store/effects/task.effects";
import { UserHeaderEffects } from "../../core/store/effects/user-header.effects";
import { matterReducer } from "../../core/store/reducers/matters.reducer";
import { taskReducer } from "../../core/store/reducers/task.reducer";
import { UserHeaderReducer } from "../../core/store/reducers/user-header.reducer";
import { AngularMaterialModule } from "../angular-material/angular-material.module";
import { ReusablesModule } from "../reusables/reusables.module";
import { AnonymousFooterComponent } from "./anonymous-footer/anonymous-footer.component";
import { AnonymousHeaderComponent } from "./anonymous-header/anonymous-header.component";
import { AttachEntityComponent } from "./attach-entity/attach-entity.component";
import { AttachFileComponent } from "./attach-file/attach-file.component";
import { AttachMatterComponent } from "./attach-matter/attach-matter.component";
import { CalendarModalComponent } from "./calendar-modal/calendar-modal.component";
import { CalenderWidgetComponent } from "./calender-widget/calender-widget.component";
import { ChangeRoleModalComponent } from "./change-role-modal/change-role-modal.component";
import { ChooseUserComponent } from "./choose-user/choose-user.component";
import { CompletedTaskComponent } from "./completed-task/completed-task.component";
import { ConfirmActionComponent } from "./confirm-action/confirm-action.component";
import { ConnectionGlobalAlertComponent } from "./connection-global-alert/connection-global-alert.component";
import { CourtAppearanceComponent } from "./court-appearance/court-appearance.component";
import { DueTaskComponent } from "./due-task/due-task.component";
import { EditEndorsementModalComponent } from "./edit-endorsement-modal/edit-endorsement-modal.component";
import { ErrorNotifierComponent } from "./error-notifier/error-notifier.component";
import { FileListComponent } from "./file-list/file-list.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { FormParentComponent } from "./form-parent/form-parent.component";
// tslint:disable-next-line:max-line-length
import { GenerateDefaultPictureNonObservableComponent } from "./generate-default-picture-non-observable/generate-default-picture-non-observable.component";
// import { LocalStorageService } from 'ngx-webstorage';
import { GenerateDefaultPictureComponent } from "./generate-default-picture/generate-default-picture.component";
import { GlobalAlertComponent } from "./global-alert/global-alert.component";
import { AuthAdminGuard } from "./guards/auth-admin-guard.service";
import { SafePipe } from "./helpers/safe-pipe";
import { LicensePaymentComponent } from "./license-payment/license-payment.component";
import { MatterSearchBoxComponent } from "./matter-search-box/matter-search-box.component";
import { NewContactModalComponent } from "./new-contact-modal/new-contact-modal.component";
import { NewEndorsementModalComponent } from "./new-endorsement-modal/new-endorsement-modal.component";
import { NewExpenseComponent } from "./new-expense/new-expense.component";
import { NewFolderComponent } from "./new-folder/new-folder.component";
import { NewPaymentComponent } from "./new-payment/new-payment.component";
import { NewSubTypeComponent } from "./new-sub-type/new-sub-type.component";
import { NewTaskModalComponent } from "./new-task-modal/new-task-modal.component";
import { NewTypeComponent } from "./new-type/new-type.component";
import { NotificationsListComponent } from "./notifications-list/notifications-list.component";
import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { PaginatorComponent } from "./paginator/paginator.component";
import { PrimarySidebarComponent } from "./primary-sidebar/primary-sidebar.component";
import { RenameItemComponent } from "./rename-item/rename-item.component";
import { UtilsService } from "./services/util.service";
import { SharedRoutingModule } from "./shared-routing.module";
import { StopwatchTimerComponent } from "./stopwatch-timer/stopwatch-timer.component";
import { TaskTagMinComponent } from "./task-tag-min/task-tag-min.component";
import { TaskTagComponent } from "./task-tag/task-tag.component";
import { TimiWidgetComponent } from "./timi-widget/timi-widget.component";
import { UpcomingTaskComponent } from "./upcoming-task/upcoming-task.component";
import { UpdateTaskModalComponent } from "./update-task-modal/update-task-modal.component";
import { UserHeaderComponent } from "./user-header/user-header.component";
import { UserShareComponent } from "./user-share/user-share.component";
import { ViewDetailAttachFileComponent } from "./view-detail-attach-file/view-detail-attach-file.component";
import { ViewFileComponent } from "./view-file/view-file.component";
import { ViewTaskComponent } from "./view-task/view-task.component";
import { SaveProceedingComponent } from './save-proceeding/save-proceeding.component';
import { EndProceedingsComponent } from './end-proceedings/end-proceedings.component';
import { EventComponent } from './event/event.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// import {LocalUserService} from './services/local-user.service';
// import { LocalStorageService } from 'ngx-webstorage';

@NgModule({
  declarations: [
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    GenerateDefaultPictureNonObservableComponent,
    FormParentComponent,
    ChooseUserComponent,
    ConfirmActionComponent,
    StopwatchTimerComponent,
    LicensePaymentComponent,
    ViewFileComponent,
    TaskTagComponent,
    ViewTaskComponent,
    CompletedTaskComponent,
    UpcomingTaskComponent,
    DueTaskComponent,
    NewContactModalComponent,
    PaginatorComponent,
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    TimiWidgetComponent,
    GlobalAlertComponent,
    UserShareComponent,
    FileUploadComponent,
    NewFolderComponent,
    RenameItemComponent,
    NewExpenseComponent,
    NewPaymentComponent,
    NotificationsListComponent,
    TimiWidgetComponent,
    PageLoaderComponent,
    MatterSearchBoxComponent,
    AttachFileComponent,
    AttachMatterComponent,
    ChangeRoleModalComponent,
    NewTaskModalComponent,
    NewEndorsementModalComponent,
    ViewDetailAttachFileComponent,
    UpdateTaskModalComponent,
    TaskTagMinComponent,
    EditEndorsementModalComponent,
    NewTypeComponent,
    NewSubTypeComponent,
    CourtAppearanceComponent,
    AttachEntityComponent,
    FileListComponent,
    ErrorNotifierComponent,
    ConnectionGlobalAlertComponent,
    SafePipe,
    CalenderWidgetComponent,
    CalendarModalComponent,
    SaveProceedingComponent,
    EndProceedingsComponent,
    EventComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ReusablesModule,
    NgxMaterialTimepickerModule,
    SharedRoutingModule,
    MatProgressSpinnerModule,
    StoreModule.forFeature("user-header", UserHeaderReducer),
    EffectsModule.forFeature([UserHeaderEffects]),
    StoreModule.forFeature("tasks", taskReducer),
    EffectsModule.forFeature([TaskEffects]),
    StoreModule.forFeature("matter", matterReducer),
    EffectsModule.forFeature([MattersEffects])
  ],
  exports: [
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AnonymousHeaderComponent,
    AnonymousFooterComponent,
    SharedRoutingModule,
    PrimarySidebarComponent,
    UserHeaderComponent,
    GenerateDefaultPictureComponent,
    GenerateDefaultPictureNonObservableComponent,
    ChooseUserComponent,
    ConfirmActionComponent,
    StopwatchTimerComponent,
    CommonModule,
    FormParentComponent,
    ViewFileComponent,
    TaskTagComponent,
    TaskTagMinComponent,
    CompletedTaskComponent,
    UpcomingTaskComponent,
    DueTaskComponent,
    ViewTaskComponent,
    NewContactModalComponent,
    PaginatorComponent,
    NewPaymentComponent,
    LicensePaymentComponent,
    FileUploadComponent,
    UserShareComponent,
    FileUploadComponent,
    NewFolderComponent,
    RenameItemComponent,
    NewExpenseComponent,
    NotificationsListComponent,
    AttachMatterComponent,
    PageLoaderComponent,
    ChangeRoleModalComponent,
    NewTaskModalComponent,
    MatterSearchBoxComponent,
    AttachFileComponent,
    NewEndorsementModalComponent,
    ViewDetailAttachFileComponent,
    UpdateTaskModalComponent,
    EditEndorsementModalComponent,
    NewTypeComponent,
    NewSubTypeComponent,
    CourtAppearanceComponent,
    AttachEntityComponent,
    FileListComponent,
    ConnectionGlobalAlertComponent,
    ErrorNotifierComponent,
    NgxMaterialTimepickerModule,
    CalenderWidgetComponent,
    CalendarModalComponent,
    SaveProceedingComponent,
    EndProceedingsComponent,
    EventComponent,
    MatProgressSpinnerModule
    // RouterModule
  ],
  providers: [
    CurrentUserService,
    AuthAdminGuard,
    UtilsService,
    FileUploadService,
    NotificationService
    // AuthGuard
  ]
})
export class SharedModule {
}
