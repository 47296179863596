import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { TagId } from "../../../core/models/parse-model/new-endorsement";

import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterService } from "../../../core/services/matter.service";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromStore from "../../../core/store/reducers/calendar-lander.reducer";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-court-appearance",
  templateUrl: "./court-appearance.component.html",
  styleUrls: ["./court-appearance.component.scss"]
})
export class CourtAppearanceComponent implements OnInit {
  tagIdArr: TagId[] = this.config.getConfig().priorities;
  newAppearance: FormGroup;
  @Input() matterIdNo: number;
  @Input() disableSelectOnEndorsementSelect: boolean;
  btnSpinner: boolean;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  @Output() getSavedAppearance = new EventEmitter();
  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;
  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;

  constructor(
    private calendarStore: Store<fromStore.CalendarState>,
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private service: MatterService
  ) {
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    console.log(this.route);
    console.log(this.route.snapshot, "matter id no");
    this.newAppearance = fb.group({
      date: this.fb.array([], Validators.required),
      action: ["", Validators.required],
      briefStage: ["", Validators.required],
      //   matterId: [{value:""
      //   // ,disabled: true
      // }, [Validators.min(1)]],
      note: [""],
      tagId: [""]
    });

    console.log(this.tagId, "this is tag id");
  }

  get date(): FormArray {
    return <FormArray>this.newAppearance.get("date");
  }

  get action() {
    return this.newAppearance.get("action");
  }

  get briefStage() {
    return this.newAppearance.get("briefStage");
  }

  get tagId() {
    return this.newAppearance.get("tagId");
  }

  get note() {
    return this.newAppearance.get("note");
  }

  ngOnInit() {
    this.newAppearance.valueChanges.subscribe((data) => {
    });

    this.showResourceNotSelectedError = false;

    this.addNextHearingDate();
  }

  addNextHearingDate() {
    this.date.push(this.createNextHearing());
  }

  createNextHearing() {
    return this.fb.group({
      date: [{value: ""}, Validators.required]
      // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
    });
  }

  removenextHearingDate(index): void {
    this.date.removeAt(index);
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }

  closeError() {
    this.showErrorMessage = false;
  }

  getSelectedOption(event) {
    if (!isNullOrUndefined(event)) {
      this.matterIdNo = +event;
    }
  }

  addNewAppearance(newCourtAppearance) {
    // if (isNullOrUndefined(this.matterIdNo)) {
    //   this.showResourceNotSelectedError = true;
    //   return;
    // } else if (this.matterIdNo < 1) {
    //   this.showResourceNotSelectedError = true;
    //   return;
    // }

    this.btnSpinner = true;

    // newCourtAppearance.matterId = this.matterIdNo;

    // let dateArray = [];
    // newCourtAppearance.date.forEach((element) => {
    //   element = "" + new Date(element.date).getTime();

    //   dateArray.push(element);
    // });

    // newCourtAppearance.date = dateArray;

    // if (newCourtAppearance.tagId === "") {
    //   newCourtAppearance.tagId = 3;
    // } else {
    //   newCourtAppearance.tagId = Number(newCourtAppearance.tagId);
    // }

    // this.service.addNewCourtAppearance(newCourtAppearance).subscribe(
    //   (data) => {
    //     console.log("successfully added a new endorsement");
    //     this.successText = "endorsement was created successfully";

    //     this.btnSpinner = false;

    //     this.closeEndorsementModal.nativeElement.click();

    //     this.getSavedAppearance.emit(newCourtAppearance);
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.showErrorMessage = true;
    //     this.messageBox.nativeElement.focus();
    //     this.errorText = "creating endorsement failed";
    //     this.btnSpinner = false;
    //   }
    // );

    console.log(newCourtAppearance);
    this.btnSpinner = false;
  }
}
