import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { FirmLabelEditModel } from "../models/edit-model/firm-label";
import { Contact } from "../models/parse-model/add-contact";
import { FirmLabelParseModel } from "../models/parse-model/firm-label";
import { Endpoints } from "../models/shared-model/endpoint";
import { ContactViewModel } from "../models/view-model/contact-list";
import { ContactLabel } from "../models/view-model/label-list";

@Injectable({
  providedIn: "root"
})
export class ContactService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  // public getContactsList(firmId: number, searchQuery= '', filter= '',
  //   sortDirection= '', pageIndex = 0, pageSize= 0): Observable<any> {
  //     console.log(firmId);
  //     const usePageSize = true;
  //   const endpoint = this.config.getConfig().serverEndpoint + `contact/${firmId}/firm`;
  //   // tslint:disable-next-line:max-line-length
  // tslint:disable-next-line: max-line-length
  //   const url = `${endpoint}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${pageIndex + 1}&pageSize=${pageSize}&usePageSize=${usePageSize}`;
  //   return this.httpClient.get<any>(url, {observe: 'response'});
  // }
  getAllContacts(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      firmId +
      "/firm";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getContactLead(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      firmId +
      "/firmlead";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getContactClient(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      firmId +
      "/firmclient";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getOtherContact(
    firmId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      firmId +
      "/Others";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getClientListForInvoice(
    firmId: number,
    searchValue: string
  ): Observable<ContactViewModel[]> {
    const url =
      this.config.getConfig().serverEndpoint +
      `contact/${searchValue}/firm/${firmId}/clients`;
    return this.httpClient.get<ContactViewModel[]>(url);
  }

  public getFirmLabels(firmId: number): Observable<ContactLabel[]> {
    const url =
      this.config.getConfig().serverEndpoint + `firm/${firmId}/label`;
    return this.httpClient.get<ContactLabel[]>(url);
  }

  public getContactDetail(contactId: number): Observable<ContactViewModel> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.contactEndpoint +
      `/${contactId}`;
    return this.httpClient.get<ContactViewModel>(url);
  }

  public updateLabel(data: FirmLabelEditModel): Observable<ContactLabel> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.addFirmLabelEndpoint;
    return this.httpClient.put<any>(url, data);
  }

  public updateContactLabel(contactId, firmLabelId) {
    const url =
      this.config.getConfig().serverEndpoint +
      "contact/attach/firm/label";
    return this.httpClient.post<any>(url, {
      contactId: contactId,
      firmLabelId: firmLabelId
    });
  }

  public updateContactStatus(contactId, statusId): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.updateContactStatus +
      "?contactId=" +
      contactId +
      "&statusId=" +
      statusId;
    return this.httpClient.put<any>(url, {
      contactId: contactId,
      statusId: statusId
    });
  }

  public addFirmLabel(data: FirmLabelParseModel) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.addFirmLabelEndpoint;
    return this.httpClient.post<any>(url, data);
  }

  public addContact(data: Contact) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.contactEndpoint;
    return this.httpClient.post<any>(url, data);
  }

  public updateContact(data: Contact) {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.contactEndpoint;
    return this.httpClient.put<any>(url, data);
  }

  getContactMatters(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      contactId +
      "/matter";
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getClientReports(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      `ClientReport/${contactId}/Client`;
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  getContactInvoice(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      "contact/" +
      contactId +
      "/invoice";
    const usePageSize = true;
    console.log(contactId);
    // tslint:disable-next-line:max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  // public getFirmLabels(firmId: number): Observable<ContactLabel[]> {
  //   const url = this.config.getConfig().serverEndpoint + `firm/${firmId}/label`;
  //   return this.httpClient.get<ContactLabel[]>(url);
  // }
  getContactDocument(
    contactId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    const api =
      this.config.getConfig().serverEndpoint +
      "Document/Contact/" +
      contactId;
    // tslint:disable-next-line: max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  deleteContact(contactId: number): Observable<boolean> {
    const api =
      this.config.getConfig().serverEndpoint + "/contact/" + contactId;
    return this.httpClient.delete<boolean>(api);
  }
}
