import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  NavigationExtras,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { CurrentUserService } from "./services/factories/current-user.service";

@Injectable()
// , CanLoad
// tslint:disable-next-line:one-line
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: CurrentUserService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    console.log("here at auth guard");
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    // Store the attempted URL for redirecting
    // this.authService.redirectUrl = url;

    // Create a dummy session id
    const sessionId = Date.now();

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParams: {session_id: sessionId},
      fragment: "anchor"
    };

    // Navigate to the login page with extras
    this.router.navigate(["/login"], navigationExtras);
    return false;
  }
}
