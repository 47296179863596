import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { TaskParseModel } from "../models/shared-model/newTask";
import { FileViewModel } from "../models/view-model/document-list";
import { UserDetail } from "../models/view-model/user-detail";
import { UserTask } from "../models/view-model/user-tasks";
import * as fromStore from "../store";
import { AnyFn } from "@ngrx/store/src/selector";

@Injectable({
  providedIn: "root"
})
export class TaskService {
  Endpoints = new Endpoints(this.config);
  userData = new UserDetail();

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromStore.State>
  ) {
    this.store.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
    });
  }

  // public loadUsertasks(): Observable<UserTask []> {
  //   const url = this.config.getConfig().serverEndpoint + `Dashboard/Usertasks`;
  //   //  return this.httpClient.get<UserTask []>(url).pipe (
  //   //   catchError(this.handleError)
  //   // );
  //   return of (userTaskListSample);
  // }

  public taskCheckSuccessful(taskId): Observable<boolean> {
    return of(true);
  }

  public loadMatterUsertasks(
    matterId: number,
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const usePageSize = true;
    console.log(matterId);
    const api =
      this.config.getConfig().serverEndpoint + `task/matter/${matterId}`;
    // tslint:disable-next-line: max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getFirmTask(
    searchQuery = "",
    filter = "",
    sortDirection = "",
    pageIndex: number,
    pageSize: number
  ): Observable<any> {
    const api = this.config.getConfig().serverEndpoint + "/task/firmtasks";
    const usePageSize = true;
    // console.log(matterId);
    // tslint:disable-next-line: max-line-length
    const url = `${api}?searchQuery=${searchQuery}&sort=${sortDirection}&order=${filter}&page=${
      pageIndex + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public checkTask(obj: UserTask): Observable<boolean> {
    const url =
      this.config.getConfig().serverEndpoint + "/task/completetask";
    return this.httpClient.put<boolean>(url, obj);
    //  return this.httpClient.put<boolean>(url,{id:obj.id}).pipe (
    //  catchError(this.handleError)
    // );
    // return of (true);
  }

  public addNewTask(model: any) {
    let url = "";

    if (model.tagOption.isUserTask) {
      url = this.config.getConfig().serverEndpoint + "task";
    } else if (model.tagOption.isMatter) {
      url = this.config.getConfig().serverEndpoint + "task/matter";
    } else if (model.tagOption.isEndorsement) {
      url = this.config.getConfig().serverEndpoint + "task/proceeding";
    } else if (model.tagOption.isApplication) {
      url = this.config.getConfig().serverEndpoint + "task/application";
    }

    console.log(url);

    return this.httpClient.post(url, model);
  }

  public updateTask(model: TaskParseModel) {
    let url = "";

    if (model.tagOption.isUserTask) {
      url = this.config.getConfig().serverEndpoint + "task";
    } else if (model.tagOption.isMatter) {
      url = this.config.getConfig().serverEndpoint + "task/matter";
    } else if (model.tagOption.isEndorsement) {
      url = this.config.getConfig().serverEndpoint + "task/proceeding";
    } else if (model.tagOption.isApplication) {
      url = this.config.getConfig().serverEndpoint + "task/application";
    }

    return this.httpClient.put<boolean>(url, model);
  }

  public assignTaskToUser(model: any[]) {
    let url = "";

    url = this.config.getConfig().serverEndpoint + "task/user";

    return this.httpClient.post<any>(url, model);
  }

  public addTaskFiles(model) {
    const url = this.config.getConfig().serverEndpoint + "task/taskfiles";

    return this.httpClient.post<any>(url, model);
  }

  public getTaskDetail(taskId): Observable<UserTask> {
    const url = this.config.getConfig().serverEndpoint + `task/${taskId}`;
    return this.httpClient.get<UserTask>(url);
  }

  public getTaskFiles(taskId): Observable<FileViewModel[]> {
    const fileType = 6;
    const url =
      this.config.getConfig().serverEndpoint +
      `file/resource/${taskId}/filetype/${fileType}`;
    return this.httpClient.get<FileViewModel[]>(url);
  }

  public getTaskDocuments(
    taskId,
    pageSize?: number,
    page?: number,
    searchQuery = "",
    usePageSize = false
  ): Observable<any> {
    const fileType = 6;
    const isAdmin = this.userData.isAdmin;

    const endpoint = this.config.getConfig().serverEndpoint + "file/firm";
    const url = `${endpoint}?searchQuery=${searchQuery}&fileType=${fileType}&id=${taskId}&isAdmin=${isAdmin}&usePageSize=${usePageSize}`;
    console.log(url);

    return this.httpClient.get<any>(url, {observe: "response"});
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
