import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { isNullOrUndefined } from "util";
import { CommonUserModel } from "../../../core/models/generic-model.ts/user-share";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { UserManagementService } from "../../../core/services/user-management.service";
import * as fromStore from "../../../core/store";
import { LoadLocalUserDetail } from "../../../core/store/actions/dashboard.actions";

@Component({
  selector: "app-choose-user",
  templateUrl: "./choose-user.component.html",
  styleUrls: ["./choose-user.component.scss"]
})
export class ChooseUserComponent implements OnInit, OnChanges {
  @Input() selectTitle: string;
  filteredUsers: CommonUserModel[] = [];
  selectedUsers: CommonUserModel[] = [];
  @Input() usersList$: Observable<CommonUserModel[]> = of([]);
  @ViewChild("closeUserModal") closeUserModal: ElementRef;
  @Output() usersUpdate = new EventEmitter<any>();
  successText: string;
  user = new UserDetail();
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;

  constructor(
    private userService: UserManagementService,
    private store: Store<fromStore.State>
  ) {
    this.store.dispatch(new LoadLocalUserDetail());

    this.store.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.user = data.userProfile;
    });
  }

  ngOnChanges(changes) {
    console.log(changes);

    console.log(this.usersList$);
    if (!isNullOrUndefined(this.usersList$)) {
      this.usersList$.subscribe((res) => {
        this.selectedUsers = res;
        console.log(res);
      });
    }
  }

  ngOnInit() {
    this.userService.getUsersList(this.user.firmId).subscribe((res) => {
      this.filteredUsers = res.body;
    });
  }

  searchUsers(term) {
    this.userService
      .getUsersList(this.user.firmId, term)
      .subscribe((res) => {
        this.filteredUsers = res.body;
      });
  }

  selectUser(user: CommonUserModel) {
    // invert the original selection
    const foundUser = this.selectedUsers.find(
      (data) => data.id === user.id
    );
    if (isNullOrUndefined(foundUser)) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(foundUser));
    }
  }

  checkIfUserIsSelected(user: CommonUserModel): boolean {
    const foundUser = this.selectedUsers.find(
      (data) => data.id === user.id
    );
    if (isNullOrUndefined(foundUser)) {
      return false;
    } else {
      return true;
    }
  }

  returnSelectedUsers() {
    this.closeUserModal.nativeElement.click();
    this.usersUpdate.emit(this.selectedUsers);
  }
}
