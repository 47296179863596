import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { MatterList } from "../../../core/models/view-model/matter-list";
import { MatterTimeSheetModel, StopWatchModel } from "../../../core/models/view-model/time-sheet";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { UserProfileViewModelLite } from "../../../core/models/view-model/UserProfileViewModelLite";
import { MatterService } from "../../../core/services/matter.service";
import * as fromStore from "../../../core/store";
import * as fromMatterTimeSheetAction from "../../../core/store/actions/matters.action";
import * as UserHeaderActions from "../../../core/store/actions/user-header.actions";
import * as fromMatterTimeSheet from "../../../core/store/reducers/matters.reducer";
// since it's lazy loaded
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-stopwatch-timer",
  templateUrl: "./stopwatch-timer.component.html",
  styleUrls: ["./stopwatch-timer.component.scss"]
})
export class StopwatchTimerComponent implements OnInit, AfterViewInit {
  @Input() timerActive: boolean;
  @Input() selectedMatterId: number;
  @Input() autoStartTime: boolean;
  stopClock: StopWatchModel = {
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliseconds: "00"
  };
  time: any;
  times: any;
  running = false;
  paused = false;
  timeSnap: StopWatchModel;
  timeEntryForm: FormGroup;
  successText: string;
  errorText: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  btnSpinner: boolean;
  @ViewChild("messageBox") messageBox: ElementRef;
  matterList: MatterList[];
  componentActive: boolean;
  firmId: number;
  user = new UserDetail();
  matterId: number;

  @Output() timeSheetSavingStatus = new EventEmitter();

  currentMatterId: number;
  firmTaskTypes: any[];
  showResourceNotSelectedError: boolean;

  @ViewChild("close_time_entry_modal") close_time_entry_modal: ElementRef;

  constructor(
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private service: MatterService,
    private activatedRoute: ActivatedRoute,
    private config: AppConfigService,
    private matterTimeStore: Store<fromMatterTimeSheet.State>
  ) {
    this.timeEntryForm = this.fb.group({
      taskName: ["", Validators.required],
      duration: [""],
      hourRate: [""],
      description: [""]
      // matterId: ['', Validators.required]
    });

    // this.store.pipe(select(fromMatters.getAllMatters))
    // .subscribe(
    //   (data) => {
    //     console.log(data);
    //    if (!isNullOrUndefined(data)) {
    //     this.matterList = data;

    this.store
      .pipe(select(fromUserHeaderStore.getCurrentMatter))
      .subscribe((cuurentMatterData) => {
        if (!isNullOrUndefined(cuurentMatterData)) {
          console.log(cuurentMatterData);
          this.currentMatterId = cuurentMatterData;
          console.log(this.currentMatterId);
          this.matterId = this.currentMatterId;
          // this.timeEntryForm.patchValue({
          //   matterId: this.currentMatterId
          // });
        }
      });
    this.store
      .pipe(select(fromStore.getUserDetail))
      .subscribe((userData) => {
        if (!isNullOrUndefined(userData)) {
          console.log(userData);
          this.user = userData;
          this.timeEntryForm.patchValue({
            hourRate: this.user.hourlyRate
          });
        }
      });

    // }
    //   }
    // );

    this.store
      .pipe(select(fromUserHeaderStore.getTimerState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          console.log(data);
          this.timerActive = data;
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getIsAddingMatterTime))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          // success
          this.btnSpinner = data;
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getIsAddedMatterTime))
      .subscribe((data) => {
        console.log(data);
        if (!isNullOrUndefined(data) && data) {
          this.showSuccessMessage = true;
          this.successText = "Time Entry was added";
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 10000);

          console.log(data);

          this.close_time_entry_modal.nativeElement.click();
          this.timeSheetSavingStatus.emit(true);
        }
      });

    this.store
      .pipe(select(fromMatterTimeSheet.getaddMatterTimeSheetError))
      .subscribe((data) => {
        // tslint:disable-next-line:triple-equals
        if (data !== "") {
          this.showErrorMessage = true;
          this.btnSpinner = false;
          this.errorText = data;
          this.timeSheetSavingStatus.emit(false);
        } else {
          this.store
            .pipe(select(fromMatterTimeSheet.getIsAddingMatterTime))
            .subscribe((isAddingState) => {
              if (!isNullOrUndefined(isAddingState)) {
                // success
                this.btnSpinner = isAddingState;
              }
            });
        }
      });
  }

  get taskName() {
    return this.timeEntryForm.get("taskName");
  }

  get hourRate() {
    return this.timeEntryForm.get("hourRate");
  }

  get duration() {
    return this.timeEntryForm.get("duration");
  }

  // get matterId() {
  //   return this.timeEntryForm.get('matterId').value;
  // }

  get description() {
    return this.timeEntryForm.get("description");
  }

  ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.autoStartTime)) {
      if (this.autoStartTime) {
        this.startTimer();
      }
    }
  }

  hideTimer() {
    this.store.dispatch(new UserHeaderActions.HideTimer());
    this.reset();
    this.showSuccessMessage = false;
    this.stopClock = {
      hours: "00",
      minutes: "00",
      seconds: "00",
      milliseconds: "00"
    };
  }

  reset() {
    this.times = [0, 0, 0, 0];
  }

  startTimer() {
    if (!this.time) {
      this.time = performance.now();
    }
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
  }

  stopTimer() {
    // this.timerActive = false;
    this.timeSnap = {...this.stopClock};
    this.hideTimer();
    this.running = false;
    this.time = null;
  }

  pauseTimer() {
    this.running = false;
    this.paused = true;
    this.time = null;
  }

  restartTimer() {
    if (!this.time) {
      this.time = performance.now();
    }
    if (!this.running) {
      this.running = true;
      requestAnimationFrame(this.step.bind(this));
    }
    this.reset();
  }

  step(timestamp) {
    if (!this.running) {
      return;
    }
    this.calculate(timestamp);
    this.time = timestamp;
    this.display();
    requestAnimationFrame(this.step.bind(this));
  }

  calculate(timestamp) {
    const diff = timestamp - this.time;
    // Hundredths of a second are 100 ms
    this.times[3] += diff / 10;
    // Seconds are 100 hundredths of a second
    if (this.times[3] >= 100) {
      this.times[2] += 1;
      this.times[3] -= 100;
    }
    // Minutes are 60 seconds
    if (this.times[2] >= 60) {
      this.times[1] += 1;
      this.times[2] -= 60;
    }
    // Hours are 60 minutes
    if (this.times[1] >= 60) {
      this.times[0] += 1;
      this.times[1] -= 60;
    }
  }

  display() {
    this.stopClock = this.format(this.times);
  }

  format(times): StopWatchModel {
    return {
      hours: this.pad0(times[0], 2),
      minutes: this.pad0(times[1], 2),
      seconds: this.pad0(times[2], 2),
      milliseconds: this.pad0(Math.floor(times[3]), 2)
    };
  }

  pad0(value, count) {
    let result = value.toString();
    for (; result.length < count; --count) {
      result = "0" + result;
    }
    return result;
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.reset();
    this.timeSnap = {...this.stopClock};

    this.firmTaskTypes = this.config.getConfig().firmTaskType;

    this.firmId = this.activatedRoute.snapshot.params["firmId"];
    console.log(this.firmId);
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      // this.newTaskForm.patchValue(
      //   {
      //     matterId: matterId
      //   }
      // )

      console.log(resourceId);

      this.matterId = resourceId;
    }

    console.log(resourceId);
  }

  saveTimeEntry() {
    this.btnSpinner = true;

    if (this.matterId > 0) {
    } else {
      this.showResourceNotSelectedError = true;
      return;
    }

    console.log(this.user);

    const payload: MatterTimeSheetModel = {
      description: this.description.value,
      hourRate: this.hourRate.value,
      timeSpent: +(
        Number.parseInt(this.timeSnap.hours.toString(), 10) * 60 +
        Number.parseInt(this.timeSnap.minutes.toString(), 10) +
        Math.round(
          Number.parseInt(this.timeSnap.hours.toString(), 10) + 60
        ) /
        60
      ),
      entryDate: new Date().getTime(),
      matterId: +this.matterId,
      firmTimeTaskTypeId: +this.taskName.value
    };

    payload.cost = (this.user.hourlyRate / 60) * payload.timeSpent;
    // tslint:disable-next-line:triple-equals
    payload.firmTimeTaskType = this.firmTaskTypes.find(
      (t) => t.id === payload.firmTimeTaskTypeId
    ).name;
    payload.user = new UserProfileViewModelLite();
    payload.user.firstName = this.user.firstName;
    payload.user.lastName = this.user.lastName;
    //  payload.user.profileImage = this.user.;
    payload.user.id = this.user.userId;

    console.log(payload);
    console.log("duration", this.duration);
    console.log("matterid", this.matterId);
    console.log(JSON.stringify(this.taskName.value));

    this.store.dispatch(
      new fromMatterTimeSheetAction.AddMatterTimes(payload)
    );
    //  this.service.saveTimeEntry(payload).toPromise().then(data => {
    //   // success
    //   this.btnSpinner = false;
    //   this.showSuccessMessage = true;
    //   this.successText = 'Time Entry was added';
    // payload.duration = this.timeSnap.hours + 'h:' + this.timeSnap.minutes + 'm:' + this.timeSnap.seconds + 's';
    // }).catch(err => {
    //   this.showErrorMessage = true;
    //   this.btnSpinner = false;
    //   this.errorText = 'An error occurred while trying to add time entry';
    // });
    //  this.messageBox.nativeElement.focus();
  }

  closeError() {
    this.showErrorMessage = false;
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }
}
