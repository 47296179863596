import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { GenericListViewModel } from "../../../core/models/generic-model.ts/generic-list";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterConfigService } from "../../../core/services/matter-config.service";
import { MatterService } from "../../../core/services/matter.service";
import * as fromStore from "../../../core/store";

@Component({
  selector: "app-new-type",
  templateUrl: "./new-type.component.html",
  styleUrls: ["./new-type.component.scss"]
})
export class NewTypeComponent implements OnInit {
  errorMessage: string;
  public successText: string;
  public errorText: string;
  public showSuccessMessage: boolean;
  public showErrorMessage: boolean;
  public btnSpinner: boolean;
  newCategoryTypeForm: FormGroup;
  userData = new UserDetail();
  states: any[] = [];
  currentCategoryId: number;
  currentCategoryTypeId: number;

  @Input() categoryTypeId: any;
  @Output() successErrorDisplay = new EventEmitter();

  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("messageBox") messageBox: ElementRef;

  briefCategories: GenericListViewModel[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private matterConfigService: MatterConfigService,
    private matterService: MatterService
  ) {
  }

  get title() {
    return this.newCategoryTypeForm.get("title");
  }

  get categoryID() {
    return this.newCategoryTypeForm.get("categoryID");
  }

  ngOnInit() {
    this.showErrorMessage = false;
    this.btnSpinner = false;
    this.addCategoryTypeForm();
    this.getCategories();
  }

  getCategories() {
    this.matterConfigService.getBriefCategories().subscribe((data) => {
      this.briefCategories = data;
      console.log(this.briefCategories);
    });
  }

  addCategoryTypeForm() {
    this.newCategoryTypeForm = this.fb.group({
      title: ["", Validators.required],
      categoryID: ["", Validators.required]
    });
  }

  onSaveNewCategoryType() {
    const addCat = {
      matterCategoryId: Number(this.categoryID.value),
      name: this.title.value,
      description: ""
    };

    const showSuccessMessage = true;
    const successText = "\"" + this.title.value + " \" Has been added";
    const showErrorMessage = true;
    const errorText = "an error occurred while trying to create practise";

    this.matterService.addSubCategory(addCat).subscribe(
      (data) => {
        console.log(data);
        console.log(addCat);
        this.successErrorDisplay.emit({
          showSuccessMessage,
          successText
        });
        this.newCategoryTypeForm.reset();
        this.btnSpinner = false;
        this.closeModal.nativeElement.click();
      },
      (error) => {
        this.successErrorDisplay.emit({showErrorMessage, errorText});
        this.closeModal.nativeElement.click();
        this.btnSpinner = false;
        console.log(error);
      }
    );

    // if (!this.newCategoryTypeForm.invalid) {
    //   this.btnSpinner = true;
    //   const formValue = this.newCategoryTypeForm.value;
    //   console.log("form will send", formValue);
    //   const categoryType = new MatterCategoryTypeParseModel();
    //   categoryType.name = formValue.title;
    //   categoryType.firmId = this.userData.firmId;
    //   categoryType.description = "";
    //   categoryType.matterCategoryId = this.currentCategoryId;

    //   this.matterConfigService
    //     .postBriefCategoryType(categoryType)
    //     .toPromise()
    //     .then(res => {
    //       this.closeModal.nativeElement.click();
    //       // send notification to the global notification. use ngrx and pass text.
    //     })
    //     .catch(err => {
    //       this.errorText = err.message;
    //       this.showErrorMessage = true;
    //       this.messageBox.nativeElement.focus();
    //       this.btnSpinner = false;
    //     });
    // }
  }
}
