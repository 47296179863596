import { TagViewModel } from "./tag-view";

export class MatterList {
  id: number;
  name: string;
  description: string;
  createdBy: number;
  firmId: number;
  dateCreated: number;
  isCompleted: boolean;
  // client?:
  unCompletedTaskCount?: number;
  tag?: TagViewModel;
  lastHearingDate?: string;
  nextHearingDate?: string;
  suitNumber?: string;
  IsArchieved?: boolean;
  teamId?: number;
  subTypeId?: number;
  matterCategory?: string;
  matterCategoryTypeId?: number;
  matterCategoryId?: number;
  matterCategorySubType?: string;
  matterCategoryType?: string;
  clients?: GenericViewModel[] = [];
  status?: string;

  /**
   *
   */
  constructor() {
    this.matterCategorySubType = "N/A";
    this.matterCategoryType = "N/A";
    this.matterCategory = "N/A";
    this.clients = [];
    this.status = "N/A";
  }
}

export const dashboardMatterListSample: MatterList[] = [
  {
    id: 1,
    name: "Keystone PLC vs Attorney General of Dabo State",
    description: "",
    createdBy: 23,
    firmId: 23,
    dateCreated: 24,
    isCompleted: true,
    unCompletedTaskCount: 2,
    tag: {id: 1, name: "critical"},
    lastHearingDate: `${new Date("02-12-2019").getTime()}`,
    nextHearingDate: `${new Date("04-18-2019").getTime()}`,
    suitNumber: "IKD/2910/2012",
    IsArchieved: true,
    teamId: 2
  },
  {
    id: 1,
    name: "Gloria Nnkechi vs UBA PLC",
    description: "",
    createdBy: 23,
    firmId: 23,
    dateCreated: 24,
    isCompleted: false,
    unCompletedTaskCount: 2,
    tag: {id: 3, name: "normal"},
    lastHearingDate: `${new Date("01-12-2019").getTime()}`,
    nextHearingDate: `${new Date("05-10-2019").getTime()}`,
    suitNumber: "IKD/2914/2012",
    IsArchieved: false,
    teamId: 3
  }
];

// tslint:disable-next-line:class-name
export class MatterDetail {
  id: number;
  name: string;
  description: string;
  createdBy: number;
  firmId: number;
  dateCreated: number;
  isCompleted: boolean;
  currentJudge: GenericViewModel[];
  currentCourt: CourtViewModel;
  proceeding: ProceedingViewModel;
  teamId?: number;
  isArchieved: boolean;
  matterCategory: string;
  categoryId?: number;
}

export class CourtViewModel {
  matterCourtId: number;
  courtTypeId: number;
  courtAddress: string;
  courtNames: string;
  suitNumber: string;
}

export class EditMatterCourt {
  matterId: number;
  courtTypeId: number;
  suitNumber: string;
  dateCommenced: string;
  courtName: string;
  courtAddress: string;
}

export interface ProceedingViewModel {
  id: number;
  hearingDate: string;
  nextHearingDate: string[];
  nextHearingDates: NextHearingDateListViewModel[];
  nextAction: string;
  nextStage: string;
  summary: string;
  matterId: number;
  commencementDate: string;
  lastHearingDate: string;
  dateCreated: string;
}

export interface GenericViewModel {
  id: number; // courtmatter judge Id not the real judge
  name: string;
}

export interface CourtListModel {
  id: number; // courtmatter judge Id not the real judge
  court: string;
}

export class NextHearingDateListViewModel {
  id: number; // courtmatter judge Id not the real judge
  date: string;
}

export class ContactList {
  id?: number;
  matterId: number;
  contactId: number;
  dateAdded?: string;
  isClient: boolean;
  isParty: boolean;
  matterContactStatusId: number;
  matter: string;
  contact: string;
  matterContactStatus: string;
  email?: string;
  phoneNumber?: string;
}

// {
//     public long MatterId { get; set; }
//     public long ContactId { get; set; }
//     public bool IsClient { get; set; }
//     public int MatterContactStatusId { get; set; }
// }

export class ContactListLite {
  matterId: number;
  contactId: number;
  isClient: boolean;
  matterContactStatusId: number;
}

export class BriefEditDetail {
  id: number;
  name: string;
  description: string;
  firmId: number;
  isAcheived?: boolean;
  tagId?: number;
  subTypeId: number;
}
