import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../core/services/auth.service";
import { PasswordValidation } from "../../shared/helpers/password-matcher";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  token: string;
  fb = new FormBuilder();
  resetPasswordForm: FormGroup;
  isError: boolean;
  isSuccess: boolean;
  btnSpinner: boolean;
  email: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        password: ["", [Validators.required]],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }

  get password() {
    return this.resetPasswordForm.get("password");
  }

  get confirmPassword() {
    return this.resetPasswordForm.get("confirmPassword");
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.isSuccess = false;
    this.isError = false;

    this.token = this.activatedRoute.snapshot.queryParams["token"];
    this.email = this.activatedRoute.snapshot.queryParams["email"];
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.btnSpinner = true;
      this.authService
        .resetPassword(
          this.password.value,
          this.confirmPassword.value,
          this.token,
          this.email
        )
        .toPromise()
        .then((response) => {
          this.router.navigate(["/login"]);
        })
        .catch((error) => {
          this.btnSpinner = false;
          this.isError = true;
        });
    }
  }
}
