export class FileViewModel {
  id?: number;
  fileName?: string;
  fileBinary?: string;
  serverFileName?: number;
  directoryId?: number;
  directory?: string;
  dateCreated?: string;
  fileType?: string;
  fileSize?: string;
  format?: string;
  itemName?: string;
  modDate?: string;
  memberCount?: number;
  itemTag?: number;
}

export class GeneralMatterFile {
  id?: number;
  resourceDirectoryId: number;
  resourceId: number;
  fileType: number;
  fileModel: FileLiteParseModel;
}

export class FileLiteParseModel {
  fileName?: string;
  fileBinary?: string;
  fileExtension?: string;
}

export enum FileType {
  application = 1,
  firm = 2,
  judgement = 3,
  matter = 4,
  proceeding = 5,
  task = 6,
}

export class Folder implements DocumentList {
  id: number;
  matterId: number;
  folderName: string;
  format: string;
  itemName: string;
  modDate: string;
  memberCount: number;
  itemTag: number;
  fileType?: string;
  resourceId: number;
  folderType: number;
}

export class DocumentMatterList {
  id: number;
  documentName: string;
  documentType: string;
  dateModified: string;
  docCount: number;
}

export class DownloadModel {
  fileType: string;
  name: string;
   value: string;
   url: string;
}

export interface DocumentList {
  id?: number;
  format?: string;
  itemName?: string;
  modDate?: string;
  memberCount?: number;
  itemTag?: number;
  resourceId: number;
  folderName: string;
  folderType: number;
}

export class DocumentListViewModel implements DocumentList {
  id?: number;
  format?: string;
  itemName?: string;
  modDate?: string;
  memberCount?: number;
  itemTag?: number;
  resourceId: number;
  folderName: string;
  folderType: number;
}

export const folderSampleList: Folder[] = [
  // {
  //   id: 1,
  //   matterId: 6,
  //   folderName: `Root/`,
  //   itemName: "test",
  //   modDate: "23",
  //   memberCount: 2,
  //   itemTagId: 2,
  //   format: "pdf"
  // },
  // {
  //   id: 2,
  //   matterId: 6,
  //   folderName: `ProgramFiles/`,
  //   itemName: "test",
  //   modDate: "23",
  //   memberCount: 2,
  //   itemTagId: 2,
  //   format: "pdf"
  // }
];

export class File {
  id: number;
  fileName: number;
  serverFileName: number;
  fileType: string;
  fileSize: string;
  directoryId?: number;
  directory: string;
  dateCreated: string;
}

// public long Id { get; set; }
// public string FileName { get; set; }
// public string ServerFileName { get; set; }
// public string FileType { get; set; }
// public string FileSize { get; set; }
// public long? DirectoryId { get; set; }
// public string Directory { get; set; }
// public string DateCreated { get; set; }

export const documentListSample: FileViewModel[] = [
  {
    fileName: "writ of summons",
    fileBinary: "",
    directoryId: 2,
    directory: "documents/",
    dateCreated: `${new Date("02-19-2019").getTime()}`,
    fileType: "Doc",
    fileSize: "128KB",
    itemName: "test",
    modDate: "23",
    memberCount: 2,
    itemTag: 2,
    format: "pdf",
    id: 2
  },
  {
    fileName: "affidavits",
    fileBinary: "",
    directoryId: 2,
    directory: "documents/",
    dateCreated: `${new Date("09-13-2019").getTime()}`,
    fileType: "PDF",
    fileSize: "1.8MB",
    itemName: "test",
    modDate: "23",
    memberCount: 2,
    itemTag: 2,
    format: "pdf",
    id: 2
  },
  {
    fileName: "plea deal contract",
    fileBinary: "",
    directoryId: 2,
    directory: "documents/",
    dateCreated: `${new Date("06-21-2019").getTime()}`,
    fileType: "xls",
    fileSize: "128KB",
    itemName: "test",
    modDate: "23",
    memberCount: 2,
    itemTag: 2,
    format: "pdf",
    id: 2
  }
];
