import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { CommonUserModel } from "../../../core/models/generic-model.ts/user-share";
import { IUser } from "../../../core/models/interfaces/user";
import { UserLite } from "../../../core/models/view-model/user-lite";

import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";

@Component({
  selector: "app-user-share",
  templateUrl: "./user-share.component.html",
  styleUrls: ["./user-share.component.scss"]
})
export class UserShareComponent extends Notifier implements OnInit, OnChanges {
  @Input() shareTitle: string;
  permissionId = new FormControl("1");

  @Input() userList$: Observable<UserLite[]> = of([]);
  selectedUser = new UserLite();
  @Input() throwError: boolean;
  @Input() permittedUsers: Observable<CommonUserModel[]> = of([]);

  @Output() emitDeletedSharedUser = new EventEmitter();
  @Output() emitPermissionUpdate = new EventEmitter();

  @ViewChild("searchbox") searchbox: ElementRef;
  users: CommonUserModel[] = [];
  userList: UserLite[] = [];

  permissionList: any[] = [];

  filteredUsers: CommonUserModel[];
  selectedUsers: CommonUserModel[];
  @Output() usersUpdate = new EventEmitter<any>();
  @Output() searchText = new EventEmitter<any>();
  hideResult: boolean;

  @Input() btnSpinner$: Observable<boolean> = of(false);
  btnSpinner: boolean;
  @ViewChild("closeUserModal") closeUserModal: ElementRef;

  constructor(protected headerStore: Store<fromUserHeaderStore.HeaderState>) {
    super(headerStore);
  }

  ngOnChanges(changes) {
    this.btnSpinner$ = this.btnSpinner$;
    console.log(changes);
    this.btnSpinner$.subscribe((result) => {
      this.btnSpinner = result;
    });

    this.permittedUsers.subscribe((data) => {
      console.log(data);
      this.users = data;
    });

    this.userList$.subscribe((data) => {
      this.userList = data;
    });
  }

  ngOnInit() {
    this.hideResult = true;

    this.permissionList = [
      {
        id: 1,
        name: "Can View"
      },
      {
        id: 2,
        name: "Can Comment"
      },
      {
        id: 3,
        name: "Can Edit"
      }
    ];
  }

  selectUser(_user: UserLite) {
    console.log(_user);
    this.selectedUser = _user;
    this.hideResult = true;
    this.searchbox.nativeElement.value =
      _user.firstName + " " + _user.lastName;
  }

  shareWithUser() {
    console.log(this.selectedUser);
    this.btnSpinner$ = of(true);
    const _share: IUser = this.selectedUser;
    let myUser = new CommonUserModel();
    myUser = <CommonUserModel>_share;
    myUser.isOwner = false;
    myUser.accessPermissionId = this.permissionId.value;

    this.usersUpdate.emit(myUser);
  }

  closeShareModal() {
    this.selectedUser = new UserLite();
    this.searchbox.nativeElement.value = "";
  }

  searchUsers(term) {
    this.hideResult = false;
    console.log(term.target.value);
    const theTerm = term.target.value.toLowerCase();
    this.searchText.emit(theTerm);
  }

  changePermission(user: CommonUserModel, permissionId) {
    console.log(permissionId.target.value);

    const updatedUserPermission = user;
    updatedUserPermission.accessPermissionId = permissionId.target.value;
    this.emitPermissionUpdate.emit(updatedUserPermission);
  }

  removeUser(permitteduser) {
    this.emitDeletedSharedUser.emit(permitteduser);
  }
}
