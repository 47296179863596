import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";
import * as UserHeaderActions from "../actions/user-header.actions";

@Injectable()
export class UserHeaderEffects {
  @Effect()
  showTimer$: Observable<Action> = this.actions$.pipe(
    ofType<UserHeaderActions.ShowTimer>(
      UserHeaderActions.UserHeaderActionTypes.SHOW_TIMER
    ),
    // mergeMap(action => this.authService.signIn(action.payload)
    // .pipe(
    // If successful, dispatch success action with result
    map(() => new UserHeaderActions.ShowTimerSuccess()),
    // If request fails, dispatch failed action
    catchError(() =>
      of({
        type: UserHeaderActions.UserHeaderActionTypes.SHOW_TIMER_FAIL
      })
    )
  );

  @Effect()
  hideTimer$: Observable<Action> = this.actions$.pipe(
    ofType<UserHeaderActions.HideTimer>(
      UserHeaderActions.UserHeaderActionTypes.HIDE_TIMER
    ),
    // mergeMap(action => this.authService.signIn(action.payload)
    // .pipe(
    // If successful, dispatch success action with result
    map(() => new UserHeaderActions.HideTimerSuccess()),
    // If request fails, dispatch failed action
    catchError(() =>
      of({
        type: UserHeaderActions.UserHeaderActionTypes.HIDE_TIMER_FAIL
      })
    )
  );

  @Effect()
  pushNotification$: Observable<Action> = this.actions$.pipe(
    ofType<UserHeaderActions.PushNotification>(
      UserHeaderActions.UserHeaderActionTypes.PUSH_NOTIFICATION
    ),
    // mergeMap(action => this.authService.signIn(action.payload)
    // .pipe(
    // If successful, dispatch success action with result
    map(() => new UserHeaderActions.PushNotificationSuccess()),
    // If request fails, dispatch failed action
    catchError(() =>
      of({
        type:
        UserHeaderActions.UserHeaderActionTypes
          .PUSH_NOTIFICATION_FAIL
      })
    )
  );

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {
  }
}
