import { FileUploadModel } from "../generic-model.ts/file-upload";
import { TagOptionGenericModel } from "../generic-model.ts/tag-option";
import { Reminder } from "../view-model/matter-tasks";

// a model used for creatingTask
export class TaskParseModel {
  name?: any;
  featureId?: number;
  startDate?: any;
  dueDate?: any;
  isReoccurringTask?: boolean = false;
  resourceId?: number;
  tagId?: number;
  description?: string;
  reminders?: Reminder[];
  reoccurringInterval: any;
  tagOption: TagOptionGenericModel;
  files: FileUploadModel[] = [];
}
