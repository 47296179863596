import { IUser } from "../interfaces/user";

/**
 * // existing user on permission
 */
export class CommonUserModel implements IUser {
  profileImage?: string;
  profileImageFileBinary?: string;
  profileImageFileType?: string;
  firstName: string;
  lastName: string;
  firmRoleId?: number;
  firmRoleName?: string;
  userId?: number;
  id?: number;
  accessPermissionId: number;
  selected?: any;
  isOwner: boolean;
  // permissionId = 'Can View';

  // [key: string]: any; //

  // [key: string]: any; // so i can dynamicaLLY ADD THE SElected key to the object
}

export class UserSharePermission {
  canView: boolean;
  canEdit: boolean;
  canComment: boolean;
}
