import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { isNullOrUndefined } from "util";
import { IPayment } from "../../../core/models/interfaces/payment";
import { PaymentParseModel } from "../../../core/models/parse-model/add-payment";
import { PaymentListViewModel } from "../../../core/models/view-model/payment-list";
import { InvoiceService } from "../../../core/services/invoice.service";

@Component({
  selector: "app-new-payment",
  templateUrl: "./new-payment.component.html",
  styleUrls: ["./new-payment.component.scss"]
})
export class NewPaymentComponent implements OnInit {
  newPaymentForm: FormGroup;
  btnSpinner: boolean;
  invoiceId: number;
  @Output() recordPayment = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;

  constructor(
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.newPaymentForm = fb.group({
      date: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      message: [""]
    });
  }

  get date() {
    return this.newPaymentForm.get("date");
  }

  get amount() {
    return this.newPaymentForm.get("amount");
  }

  get message() {
    return this.newPaymentForm.get("message");
  }

  ngOnInit() {
    this.btnSpinner = false;
    this.invoiceId = this.activatedRoute.snapshot.params["id"];
    console.log(this.invoiceId);
  }

  savePayment() {
    this.btnSpinner = true;
    let payment = new PaymentParseModel();
    payment.amount = this.amount.value;
    payment.datePaid = (+new Date(this.date.value)).toString();
    payment.note = this.message.value;
    if (!isNullOrUndefined(this.invoiceId)) {
      payment.clientInvoiceId = this.invoiceId;
    }

    this.invoiceService
      .savePayment(payment)
      .toPromise()
      .then((res) => {
        let pay = new PaymentListViewModel();
        // convert to interface
        const _pay: IPayment = payment;

        // convert to class that implements the interface
        pay = <PaymentListViewModel>_pay;
        pay.id = res;

        this.recordPayment.emit(pay);
        this.closeModal.nativeElement.click();
        this.newPaymentForm.reset();
      })
      .catch((err) => {
        console.log(err);
      })
    // .finally(() => {this.btnSpinner = false});
  }
}
