import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/views/shared/helpers/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProceedingsService {

  constructor(private httpClient:HttpClient, private config: AppConfigService) { }

  public getProceedingsStageType(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/Proceeding/StageType";
    return this.httpClient.get(url);
  }
  public saveProceedings(data): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "matter/proceeding";
    return this.httpClient.post(url,data);
  }
}
