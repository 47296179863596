import { PermissionViewModel } from "./permission";

export class UserDetail {
  public id: number;
  public userId: number;
  public firstName: string;
  public lastName: string;
  public otherName: string;
  public gender: string;
  public nationality: string;
  public stateId: number;
  public state: string;
  public aspUserId: string;
  public userName: string;
  public email: string;
  public emailConfirmed: boolean;
  public phoneNumber: string;
  public twoFactorEnabled: boolean;
  public firmId: number;
  public firm: string;
  public countryId: number;
  public country: string;
  public subscriptionEndDate: string;
  public role: string;
  public birthDate: string;
  public hourlyRate: number;
  public isAdmin: boolean;
  public isLicensed: boolean;
  public accountPermissions: PermissionViewModel[];
}
