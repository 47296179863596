import { Action } from "@ngrx/store";
import { ProceedingViewModel } from "../../models/view-model/endorsement-list";
import { MatterExpensesModel } from "../../models/view-model/matter-expenses";
import { ContactList, GenericViewModel, MatterDetail } from "../../models/view-model/matter-list";
import { MatterTaskModel } from "../../models/view-model/matter-tasks";
import { MatterTeamMember } from "../../models/view-model/team-list";
import { MatterTimeSheetModel } from "../../models/view-model/time-sheet";

export enum MattersActionTypes {
  loadMatterList = "[matter] Load Matter List",
  loadMatterListSuccess = "[matter] Load Matter List Success",
  loadMatterListFail = "[matter] Load Matter List Fail",

  loadMatterListByCategory = "[matter] Load Matter List By Category",
  loadMatterListByCategorySuccess = "[matter] Load Matter List By Category Success",
  loadMatterListByCategoryFail = "[matter] Load Matter List By Category Fail",

  setCurrentMatter = "[matter] set Current Matter",
  loadCurrentMatter = "[matter] load Current Matter",
  loadCurrentMatterSuccess = "[matter] load Current Matter Success",
  loadCurrentMatterFail = "[matter] load Current Matter Fail ",
  loadCurrentMatterContacts = "[matter] load Current Matter Contacts",
  loadCurrentMatterContactsSuccess = "[matter] load Current Matter Contacts Success",
  loadCurrentMatterContactsFail = "[matter] load Current Matter Contacts Fail",
  loadCurrentMatterTeam = "[matter] load Current Matter Team",
  loadCurrentMatterTeamSuccess = "[matter] load Current Matter Team Success",
  loadCurrentMatterTeamFail = "[matter] load Current Matter Team Fail",
  loadCurrentMatterTasks = "[matter] load Current Matter Tasks",
  loadCurrentMatterTasksSuccess = "[matter] load Current Matter Tasks Success",
  loadCurrentMatterTasksFail = "[matter] load Current Matter Tasks Fail",
  loadCurrentMatterExpenses = "[matter] load Current Matter Expenses",
  loadCurrentMatterExpensesSuccess = "[matter] load Current Matter Expenses Success",
  loadCurrentMatterExpensesFail = "[matter] load Current Matter Expenses Fail",
  loadCurrentMatterTimes = "[matter] load Current Matter Times",
  loadCurrentMatterTimesSuccess = "[matter] load Current Matter Times Success",
  loadCurrentMatterTimesFail = "[matter] load Current Matter Times Fail",
  loadMatterTimeSheet = "[Matter TimeSheet] Load Matter Time Sheet",
  loadMatterTimeSheetSuccess = "[Matter Time Sheet] Load Matter Time Sheet Success",
  loadMatterTimeSheetFail = "[Matter Time Sheet] Load Matter Time Sheet Fail",
  LoadMatterEndorsement = "[Matter] Load  Matter Endorsement",
  LoadMatterEndorsementSuccess = "[Matter] Load  Matter Endorsement Success",
  LoadMatterEndorsementFail = "[Matter] Load Matter Endorsement Fail ",

  LoadCurrentEndorsement = "[Matter] Load Current Matter Endorsement",
  LoadCurrentEndorsementSuccess = "[Matter] Load Current Matter Endorsement Success ",
  LoadCurrentEndorsementFail = "[Matter] Load Current Matter Endorsement Fail",

  LoadMatterApplication = "[Matter] Load Matter Application ",
  LoadMatterApplicationSuccess = "[Matter] Load Matter Application Success",
  LoadMatterApplicationFail = "[Matter] Load Matter Application Fail",

  AddTeamMember = "[Team] Add Team Member",
  AddTeamMemberSuccess = "[Team] Add Team Member Success",
  AddTeamMemberFail = "[Team] Add Team Member Fail",
  AddApplication = "[Matter] Add Matter Application",
  AddApplicationSuccess = "[Matter] Add Matter application Success",
  AddApplicationFail = "[Matter] Add Matter Application Fail",
  DeleteTeamMember = "[Team] Delete Team Member",
  DeleteTeamMemberSuccess = "[Team] Delete Team Member Success",
  DeleteTeamMemberFail = "[Team] Delete Team Member Fail",
  filterMatterState = "[matter] Filter Matter State",
  addNewParty = "[matter] Add New Party",
  addNewPartySuccess = "[matter] Add New Party Success",
  addNewPartyFail = "[matter] Add New Party Fail",
  UpdateApplication = "[Matter] Update Matter Application",
  UpdateApplicationSuccess = "[Matter] Update Matter Application Success",
  UpdateApplicationFail = "[Matter] Update Matter Application Fail",
  LoadMatterDocuments = "[Matter] Load Matter Documents ",
  LoadMatterDocumentSuccess = "[Matter] Load Matter Documents Successful",
  LoadMatterDocumentFail = "[Matter] Load Matter Documents Fail",
  ADD_MATTER_TIME = "[matter] ADD_MATTER_TIME",
  ADD_MATTER_TIME_SUCCESS = "[matter] ADD_MATTER_TIME_SUCCESS",
  ADD_MATTER_TIME_FAIL = "[matter] ADD_MATTER_TIME_FAIL",

  loadCurrentMatterJudge = "[matter] load Current Matter Judge",
  loadCurrentMatterJudgeSuccess = "[matter] load Current Matter Judge Success",
  loadCurrentMatterJudgeFail = "[matter] load Current Matter judge Fail ",
}

export class LoadMatterList implements Action {
  readonly type = MattersActionTypes.loadMatterList;

  constructor(
    public payload: {
      ongoing: boolean;
      archived: boolean;
      completed: boolean;
      categoryType: number[];
      categorySubType: number[];
      clientId: number;
      firmId: number;
      matterCategoryId: number;
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
      usePageSize?: boolean;
    }
  ) {
  }
}

export class LoadMatterListSuccess implements Action {
  readonly type = MattersActionTypes.loadMatterListSuccess;

  constructor(public payload: any) {
  }
}

export class LoadMatterListFail implements Action {
  readonly type = MattersActionTypes.loadMatterListFail;

  constructor(public payload: string) {
  }
}

export class LoadMatterTimeSheet implements Action {
  readonly type = MattersActionTypes.loadMatterTimeSheet;

  constructor(
    public payload: {
      matterId: number;
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
      usePageSize?: boolean;
    }
  ) {
  }
}

export class LoadMatterTimeSheetSuccess implements Action {
  readonly type = MattersActionTypes.loadMatterTimeSheetSuccess;

  constructor(public payload: any) {
  }
}

export class LoadMatterTimeSheetFail implements Action {
  readonly type = MattersActionTypes.loadMatterTimeSheetFail;

  constructor(public payload: string) {
  }
}

export class LoadCurrentEndorsement implements Action {
  readonly type = MattersActionTypes.LoadCurrentEndorsement;

  constructor(public payload: ProceedingViewModel) {
  }
}

export class LoadCurrentEndorsementSuccess implements Action {
  readonly type = MattersActionTypes.LoadCurrentEndorsementSuccess;

  constructor(public payload: boolean) {
  }
}

export class LoadCurrentEndorsementFail implements Action {
  readonly type = MattersActionTypes.LoadCurrentEndorsementFail;

  constructor(public payload: any) {
  }
}

export class LoadMatterListByCategory implements Action {
  readonly type = MattersActionTypes.loadMatterListByCategory;

  constructor(
    public payload: {
      ongoing: boolean;
      archived: boolean;
      completed: boolean;
      categoryType: number[];
      categorySubType: number[];
      clientId: number;
      firmId: number;
      matterCategoryId: number;
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
      usePageSize?: boolean;
    }
  ) {
  }
}

export class LoadMatterListByCategorySuccess implements Action {
  readonly type = MattersActionTypes.loadMatterListByCategorySuccess;

  constructor(public payload: any) {
  }
}

export class LoadMatterListByCategoryFail implements Action {
  readonly type = MattersActionTypes.loadMatterListByCategoryFail;

  constructor(public payload: string) {
  }
}

export class LoadMatterEndorsement implements Action {
  readonly type = MattersActionTypes.LoadMatterEndorsement;

  constructor(
    public payload: {
      matterId: number;
      isAdmin: boolean;
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
      usePageSize?: boolean;
    }
  ) {
  }
}

export class LoadMatterEndorsementSuccess implements Action {
  readonly type = MattersActionTypes.LoadMatterEndorsementSuccess;

  constructor(public payload: any) {
  }
}

export class LoadMatterEndorsementFail implements Action {
  readonly type = MattersActionTypes.LoadMatterEndorsementFail;

  constructor(public payload: string) {
  }
}

export class LoadMatterApplication implements Action {
  readonly type = MattersActionTypes.LoadMatterApplication;

  constructor(
    public payload: {
      matterId: number;
      isAdmin: boolean;
      searchQuery: string;
      filter: string;
      sortDirection: string;
      pageIndex: number;
      pageSize: number;
      usePageSize?: boolean;
    }
  ) {
  }
}

export class LoadMatterApplicationSuccess implements Action {
  readonly type = MattersActionTypes.LoadMatterApplicationSuccess;

  constructor(public payload: any) {
  }
}

export class LoadMatterApplicationFail implements Action {
  readonly type = MattersActionTypes.LoadMatterApplicationFail;

  constructor(public payload: string) {
  }
}

export class SetCurrentMatter implements Action {
  readonly type = MattersActionTypes.setCurrentMatter;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatter implements Action {
  readonly type = MattersActionTypes.loadCurrentMatter;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterSuccess;

  // typehint it to actual model
  constructor(public payload: MatterDetail) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterContacts implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterContacts;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterContactsSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterContactsSuccess;

  // typehint it to actual model
  constructor(public payload: ContactList[]) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterContactsFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterContactsFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTeam implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTeam;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTeamSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTeamSuccess;

  // typehint it to actual model
  constructor(public payload: MatterTeamMember[]) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTeamFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTeamFail;

  constructor(public payload: string) {
  }
}

export class AddTeamMember implements Action {
  readonly type = MattersActionTypes.AddTeamMember;

  constructor(public payload: MatterTeamMember[]) {
  }
}

export class AddTeamMemberSuccess implements Action {
  readonly type = MattersActionTypes.AddTeamMemberSuccess;

  constructor(public payload: number) {
  }
}

export class AddTeamMemberFail implements Action {
  readonly type = MattersActionTypes.AddTeamMemberFail;

  constructor(public payload: string) {
  }
}

export class AddApplication implements Action {
  readonly type = MattersActionTypes.AddApplication;

  constructor(public payload: any) {
  }
}

export class AddApplicationSuccess implements Action {
  readonly type = MattersActionTypes.AddApplicationSuccess;

  constructor(public payload: number) {
  }
}

export class AddApplicationFail implements Action {
  readonly type = MattersActionTypes.AddApplicationFail;

  constructor(public payload: any) {
  }
}

export class UpdateApplication implements Action {
  readonly type = MattersActionTypes.UpdateApplication;

  constructor(public payload: any) {
  }
}

export class UpdateApplicationSuccess implements Action {
  readonly type = MattersActionTypes.UpdateApplicationSuccess;

  constructor(public payload: boolean) {
  }
}

export class UpdateApplicationFail implements Action {
  readonly type = MattersActionTypes.UpdateApplicationFail;

  constructor(public payload: any) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTasks implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTasks;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTasksSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTasksSuccess;

  // typehint it to actual model
  constructor(public payload: MatterTaskModel[]) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTasksFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTasksFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterExpenses implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterExpenses;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterExpensesSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterExpensesSuccess;

  // typehint it to actual model
  constructor(public payload: MatterExpensesModel[]) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterExpensesFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterExpensesFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTimes implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTimes;

  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTimesSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTimesSuccess;

  // typehint it to actual model
  constructor(public payload: MatterTimeSheetModel[]) {
  }
}

// tslint:disable-next-line:class-name
export class LoadCurrentMatterTimesFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterTimesFail;

  constructor(public payload: string) {
  }
}

export class DeleteTeamMember implements Action {
  readonly type = MattersActionTypes.DeleteTeamMember;

  constructor(public payload: number) {
  }
}

export class DeleteTeamMemberSuccess implements Action {
  readonly type = MattersActionTypes.DeleteTeamMemberSuccess;
  // constructor(public payload: number) {}
}

export class DeleteTeamMemberFail implements Action {
  readonly type = MattersActionTypes.DeleteTeamMemberFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class AddNewParty implements Action {
  readonly type = MattersActionTypes.addNewParty;

  // create model for adding party
  constructor(public payload: ContactList) {
  }
}

// tslint:disable-next-line:class-name
export class AddNewPartySuccess implements Action {
  readonly type = MattersActionTypes.addNewPartySuccess;

  // typehint it to actual model
  constructor(public payload: number) {
  }
}

// tslint:disable-next-line:class-name
export class AddNewPartyFail implements Action {
  readonly type = MattersActionTypes.addNewPartyFail;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class FilterMatterState implements Action {
  readonly type = MattersActionTypes.filterMatterState;

  constructor(public payload: string) {
  }
}

export class LoadMatterDocuments implements Action {
  readonly type = MattersActionTypes.LoadMatterDocuments;

  constructor(public payload: number) {
  }
}

export class LoadMatterDocumentSuccess implements Action {
  readonly type = MattersActionTypes.LoadMatterDocumentSuccess;

  constructor(public payload: Document[]) {
  }
}

export class LoadMatterDocumentFail implements Action {
  readonly type = MattersActionTypes.LoadMatterDocumentFail;

  constructor(public payload: string) {
  }
}

// add matter time sheet
// tslint:disable-next-line:class-name
export class AddMatterTimesFail implements Action {
  readonly type = MattersActionTypes.ADD_MATTER_TIME_FAIL;

  constructor(public payload: string) {
  }
}

// tslint:disable-next-line:class-name
export class AddMatterTimes implements Action {
  readonly type = MattersActionTypes.ADD_MATTER_TIME;

  constructor(public payload: MatterTimeSheetModel) {
  }
}

// tslint:disable-next-line:class-name
export class AddMatterTimesSuccess implements Action {
  readonly type = MattersActionTypes.ADD_MATTER_TIME_SUCCESS;

  // typehint it to actual model
  constructor(public payload: number) {
  }
}

export class LoadCurrentMatterJudges implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterJudge;

  constructor(public payload: number) {
  }
}

export class LoadCurrentMatterJudgesSuccess implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterJudgeSuccess;

  // typehint it to actual model
  constructor(public payload: GenericViewModel[]) {
  }
}

export class LoadCurrentMatterJudgesFail implements Action {
  readonly type = MattersActionTypes.loadCurrentMatterJudgeFail;

  // typehint it to actual model
  constructor(public payload: any) {
  }
}

export type MatterActions =
  | LoadMatterList
  | LoadMatterListByCategory
  | LoadMatterListByCategorySuccess
  | LoadMatterListByCategoryFail
  | LoadMatterListSuccess
  | LoadMatterListFail
  | LoadCurrentMatter
  | LoadCurrentMatterSuccess
  | SetCurrentMatter
  | LoadCurrentMatterFail
  | LoadCurrentMatterContacts
  | LoadCurrentMatterContactsSuccess
  | LoadCurrentMatterContactsFail
  | LoadCurrentMatterTeam
  | LoadCurrentMatterTeamSuccess
  | LoadCurrentMatterTeamFail
  | LoadCurrentMatterTasks
  | LoadCurrentMatterTasksSuccess
  | LoadCurrentMatterTasksFail
  | LoadCurrentMatterExpenses
  | LoadCurrentMatterExpensesSuccess
  | LoadCurrentMatterExpensesFail
  | LoadCurrentMatterTimes
  | LoadCurrentMatterTimesSuccess
  | LoadCurrentMatterTimesFail
  | LoadMatterEndorsement
  | LoadMatterEndorsementSuccess
  | LoadMatterEndorsementFail
  | LoadMatterApplication
  | LoadMatterApplicationSuccess
  | LoadMatterApplicationFail
  | AddTeamMember
  | AddTeamMemberSuccess
  | AddTeamMemberFail
  | AddApplication
  | AddApplicationSuccess
  | AddApplicationFail
  | UpdateApplication
  | UpdateApplicationSuccess
  | UpdateApplicationFail
  | DeleteTeamMember
  | DeleteTeamMemberSuccess
  | DeleteTeamMemberFail
  | AddNewParty
  | AddNewPartySuccess
  | AddNewPartyFail
  | FilterMatterState
  | LoadMatterDocuments
  | LoadMatterDocumentSuccess
  | LoadMatterDocumentFail
  | AddMatterTimes
  | AddMatterTimesSuccess
  | AddMatterTimesFail
  | LoadCurrentEndorsement
  | LoadCurrentEndorsementSuccess
  | LoadMatterTimeSheet
  | LoadMatterTimeSheetSuccess
  | LoadMatterTimeSheetFail
  | LoadCurrentEndorsementFail
  | LoadCurrentMatterJudges
  | LoadCurrentMatterJudgesSuccess
  | LoadCurrentMatterJudgesFail;
