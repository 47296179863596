import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import { TaskService } from "../../../core/services/task.service";
import * as TaskActions from "../../../core/store/actions/task.action";
import * as fromTask from "../../../core/store/reducers/task.reducer";
import { IConfirm } from "../interfaces/confirm";

@Component({
  selector: "app-task-tag",
  templateUrl: "./task-tag.component.html",
  styleUrls: ["./task-tag.component.scss"]
})
export class TaskTagComponent implements OnInit, IConfirm {
  confirmType: string;
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  confirmElementData: any;
  matterId: number;

  todayDate: number;
  @Input() task: UserTask;
  @Output() currentTask = new EventEmitter();
  @Output() myShowTask = new EventEmitter();

  showCurrentTask: boolean;
  checkSpinner: boolean;

  // @Output() isClosed: Observable<boolean>;

  constructor(
    private taskStore: Store<fromTask.State>,
    private taskService: TaskService,
    private route: ActivatedRoute
  ) {
    this.todayDate = new Date().getTime();
  }

  ngOnInit() {
    console.log("matterr id");
    this.matterId = Number(
      this.route.parent.snapshot.paramMap.get("matterId")
    );
    console.log(this.matterId);

    if (!isNullOrUndefined(this.task)) {
      this.taskService.getTaskDetail(this.task.id).subscribe((res) => {
        console.log(res);
        this.task = res;
      });
    }

    this.taskStore
      .pipe(select(fromTask.completeTaskSuccess))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          if (data) {
            // this.myMessgae.emit(true);
          }
        }
      });
  }

  setCurrentTask(file) {
    // this.currentTask.emit(file)
    this.taskStore.dispatch(new TaskActions.SetCurrentTask(file));
    console.log(file);
    this.taskStore.dispatch(new TaskActions.ViewTask(true));
    this.showCurrentTask = true;
    this.currentTask.emit(file);
    this.myShowTask.emit(file);
  }

  confirmDelete(id: any) {
    console.log("about to delete");
    console.log(id);
    this.confirmType = "deleteTask";
    this.confirmTitle = "Delete Task";
    this.confirmMessage = "Are you sure you want to delete this task ?";
    this.confirmAction = "Yes, Delete Task";
    this.confirmElementData = id;
  }

  confirmResponse(data: any) {
    if (data.type === "deleteTask") {
      this.deleteTask(data.elementData);
    }
  }

  deleteTask(task: UserTask) {
    console.log(task);
    // call delete task from store
    // this.taskStore.dispatch(new TaskActions.);
  }

  onChange(task: UserTask) {
    console.log("clicked!!!!");

    // const test: UserTask = {
    //   dueDate: "1569180840000"
    //   dueDateInNumber: 1569180840000
    //   id: 1
    //   iscompleted: true
    //   name: "FAUA"
    //   resourceId: 3
    //   resourceName: "Assasin Creed Version 2"
    //   resourceType: "Application"
    //   tag: "Critical"
    //   teamMemberCount: 0
    //   teamMembers: null
    // }
    // const payload: UserTask = {
    //   // id: this.editTask !== undefined ? this.editTask.id : 0,
    //   name: this.taskName.value,
    //   // description: this.description.value,
    //   startDate: `${new Date().getTime()}`,
    //   dueDate: `${new Date(this.dueDate.value).getTime()}`,
    //   tagId: Number(this.taskPriorityId.value),
    //   reminders: reminderDates,
    //   isReoccurringTask: this.isReoccurringTask.value,
    //   reoccurringInterval: Number(this.reoccurringInterval.value),
    //   // taskUsers: this.taskTeamMembers,
    //   // files: this.selectedTaskFiles,
    //   // matterId: Number(this.matterId.value),
    //   resourceId: Number(this.matterId.value)
    // };

    console.log(task);
    this.checkSpinner = true;
    this.taskStore.dispatch(new TaskActions.CheckTask(task));

    // the service to update the task to complete should be written here
  }
}
