import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { StatusViewModel } from "../../../core/models/view-model/connection-view-model";
import * as headertActions from "../../../core/store/actions/user-header.actions";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Subscription, TeardownLogic } from "rxjs";

export class Notifier {
  notificationMessages: string[] = [];
  notificationCount: number;
  connectionState = new StatusViewModel();
  errorState = new StatusViewModel();

  showLoader: boolean;
  showNoResult: boolean;
  public subscription = new Subscription();

  constructor(
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) //   protected userManagementService: UserManagementService
  {
    this.showLoader = true;
    this.showNoResult = false;

    this.headerStore
      .pipe(select(fromUserHeaderStore.getNotificationMessages))
      .subscribe((data) => {
        this.notificationMessages = data;
        console.log(data);
      });

    this.headerStore
      .pipe(select(fromUserHeaderStore.getConnectionState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.connectionState = data;
          console.log(data);
        }
      });

    this.headerStore
      .pipe(select(fromUserHeaderStore.getErrorState))
      .subscribe((data) => {
        if (!isNullOrUndefined(data)) {
          this.errorState = data;
          console.log(data);
        }
      });
  }

  pushMessage(successHtmlString: string) {
    this.headerStore.dispatch(
      new headertActions.PushNotification(successHtmlString)
    );
  }

  removeMessage() {
    console.log(this.notificationMessages);
    this.notificationMessages.pop();
  }

  sendErrorMessage(errorMsg: string) {
    this.headerStore.dispatch(new headertActions.SendError(errorMsg));
  }

  clearErrorMessage() {
    this.errorState.status = false;
  }

  setInternetAvailable(message: string) {
    this.headerStore.dispatch(
      new headertActions.SetInternetAvailable(message)
    );
  }

  setInternetNotAvailable(message: string) {
    this.headerStore.dispatch(
      new headertActions.SetInternetAvailable(message)
    );
  }

  clearInternetAvailabilityState() {
    this.connectionState.status = false;
    this.connectionState.text = "";
  }

  clearInternetAvailableState(errorMsg: string) {
    this.headerStore.dispatch(
      new headertActions.SetInternetAvailable(errorMsg)
    );
  }

  addSubscription(logic: TeardownLogic): void {
    this.subscription.add(logic);
  }
  clearSubscription(): void {
    this.subscription.unsubscribe();
  }


}
