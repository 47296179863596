import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeGuard } from "../shared/guards/home-guard.service";
import { FaqsComponent } from "./faqs/faqs.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { LayoutComponent } from "./layout/layout.component";
import { MoneyTransferOptionComponent } from "./money-transfer-option/money-transfer-option.component";
import { PaymentSuccesfulComponent } from "./payment-succesful/payment-succesful.component";
import { PolicyComponent } from "./policy/policy.component";
import { PricingComponent } from "./pricing/pricing.component";
import { TermsComponent } from "./terms/terms.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: HomepageComponent,
        pathMatch: "full",
        canActivate: [HomeGuard]
      },
      {path: "help", component: FaqsComponent},
      {path: "pricing", component: PricingComponent},
      {path: "pay", component: MoneyTransferOptionComponent},
      {path: "terms", component: TermsComponent},
      {path: "userterms", component: TermsComponent},
      {path: "payment-success", component: PaymentSuccesfulComponent},
      {path: "policies", component: PolicyComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
