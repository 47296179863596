import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { FileUploadModel } from "../../../core/models/generic-model.ts/file-upload";
import { TagOptionGenericModel } from "../../../core/models/generic-model.ts/tag-option";
import { CommonUserModel } from "../../../core/models/generic-model.ts/user-share";
import { TaskParseModel } from "../../../core/models/shared-model/newTask";
import { Reminder } from "../../../core/models/view-model/matter-tasks";
import { TaskDetailViewModel } from "../../../core/models/view-model/task-detail";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { UserTask } from "../../../core/models/view-model/user-tasks";
import { TaskService } from "../../../core/services/task.service";

import * as fromStore from "../../../core/store";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as TaskActions from "../../../core/store/actions/task.action";
import * as fromMatters from "../../../core/store/reducers/matters.reducer";
import * as fromTask from "../../../core/store/reducers/task.reducer";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";
import { Notifier } from "../base-class/notifier";

@Component({
  selector: "app-update-task-modal",
  templateUrl: "./update-task-modal.component.html",
  styleUrls: ["./update-task-modal.component.scss"]
})
export class UpdateTaskModalComponent extends Notifier
  implements OnInit, OnChanges {
  btnSpinner: boolean;
  componentActive: boolean;

  @Output() newTaskEmit = new EventEmitter<TaskDetailViewModel>();
  showChildModal: boolean;

  @Output() updateUser = new EventEmitter<CommonUserModel[]>();

  @Input() taskTeamMembers: CommonUserModel[] = [];

  @Input() editTask: UserTask;

  @ViewChild("closeTaskModal") closeTaskModal: ElementRef;
  @ViewChild("closeNewEventModal") closeNewEventModal: ElementRef;
  reminders: any = {};
  editTaskForm: FormGroup;
  user = new UserDetail();
  firmId: any;
  resourceId: number;
  showResourceNotSelectedError: boolean;
  createNewTask = new TaskParseModel();
  errorText: string;
  showError: boolean;
  fileProgressArray: number[] = [];
  currentDocument: FileUploadModel[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private taskStore: Store<fromTask.State>,
    private matterStore: Store<fromMatters.State>,
    private store: Store<fromStore.State>,
    private taskService: TaskService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
    super(headerStore);
    this.componentActive = true;

    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      console.log(data);
      this.user = data;
    });

    this.taskStore
      .pipe(select(fromTask.updateTaskSuccess))
      .subscribe((success) => {
        if (success) {
          this.btnSpinner = false;
          this.pushMessage("Updating task, Successful");
          this.closeTaskModal.nativeElement.click();
        }
      });

    this.taskStore
      .pipe(select(fromTask.updateTaskError))
      .subscribe((error) => {
        if (error.length > 2) {
          this.btnSpinner = false;
          this.sendErrorMessage("Adding task, Failed");
        }
      });

    // new task form builder
    this.editTaskForm = this.fb.group({
      taskName: ["", Validators.required],
      // description: ['', Validators.required],
      taskPriorityId: ["", Validators.required],
      dueDate: ["", Validators.required],
      reminders: this.fb.array([], Validators.required),
      isReoccurringTask: [false],
      reoccurringInterval: [""],
      taskTag: [0, Validators.required]
    });

    // this.matterStore
    //   .pipe(
    //     select(fromMatters.getMatterList),
    //     takeWhile(() => this.componentActive)
    //   )
    //   .subscribe(data => {
    //     this.matterList = data;
    //     console.log('****------here is the tag team details****------');
    //     console.log(data);
    //   });
  }

  public _taskTag = new TagOptionGenericModel();

  // tagArray: any[] = []

  get taskTag() {
    return this.editTaskForm.get("taskTag");
  }

  // for new task form control
  get taskName() {
    return this.editTaskForm.get("taskName");
  }

  get description() {
    return this.editTaskForm.get("description");
  }

  get taskPriorityId() {
    return this.editTaskForm.get("taskPriorityId");
  }

  // enableShowChildModal() {
  //   this.showChildModal = true;
  // }

  // disableShowChildModal() {
  //   this.showChildModal = true;
  // }

  get dueDate() {
    return this.editTaskForm.get("dueDate");
  }

  get isReoccurringTask() {
    return this.editTaskForm.get("isReoccurringTask");
  }

  get reoccurringInterval() {
    return this.editTaskForm.get("reoccurringInterval");
  }

  ngOnInit() {
    this.showResourceNotSelectedError = false;

    this.resourceId = 0;

    this.btnSpinner = false;

    this.showError = false;
    // convert object to array
    // for (const [key, value] of Object.entries(this.TaskTag)) {
    //     this.tagArray.push(value);
    // }

    // console.log(this.tagArray);
  }

  ngOnChanges(changes) {
    console.log(changes);
    console.log(this.editTask);
    if (this.editTask !== undefined) {
      this.editTaskMode();
    }
  }

  editTaskMode() {
    console.log("this is", this.editTask);

    this.editTaskForm.reset();
    this.reminders = [];

    // this.editTask.reminders.forEach(data => {
    //   const hold = data.message.split('#');
    //   this.addReminder({
    //     value: Number(hold[1]),
    //     interval: hold[0]
    //   });
    // });
    this.editTaskForm.patchValue({
      taskName: this.editTask.name
      // description: this.editTask.description,
      // taskPriorityId: this.editTask.tagId,
      //  dueDate: (new Date(this.editTask.dueDate)).toISOString().substring(0, 16),
      // isReoccurringTask: this.editTask.,
      // reoccurringInterval: this.editTask.reoccurringInterval
    });

    // console.log((new Date(this.editTask.dueDate)).toISOString());
  }

  // from emitter
  getattachedObject(output) {
    this.errorText = output.errorText;
    this.showError = output.showError;
    this.fileProgressArray = output.fileProgressArray;
    this.currentDocument = output.currentDocument;
  }

  getSelectedOption(resourceId) {
    if (Number.parseInt(resourceId, 10) > 0) {
      // this.editTaskForm.patchValue(
      //   {
      //     matterId: matterId
      //   }
      // )

      console.log(resourceId);

      this.resourceId = resourceId;
    }

    console.log(resourceId);
  }

  saveTask() {
    const reminderDates: Reminder[] = [];

    this.reminders.controls.forEach((controlData) => {
      const data = controlData.value;
      const interval = data.interval;
      let i = "";
      if (interval.toLowerCase().includes("minute")) {
        i = "m";
      }
      if (interval.toLowerCase().includes("hour")) {
        i = "h";
      }
      if (interval.toLowerCase().includes("day")) {
        i = "d";
      }
      if (interval.toLowerCase().includes("week")) {
        i = "w";
      }
      if (interval.toLowerCase().includes("month")) {
        i = "M";
      }
      if (interval.toLowerCase().includes("year")) {
        i = "y";
      }

      const value = data.value;
      const newDate: number = moment(this.dueDate.value)
        .subtract(value, i)
        .unix();
      reminderDates.push({
        reminderTime: newDate,
        message: interval + "#" + value
      });
    });

    if (+this.taskTag.value > 0 && +this.taskTag.value !== 4) {
      // if no matter or application or resource is selected

      if (this.resourceId > 0) {
      } else {
        this.showResourceNotSelectedError = true;
        return;
      }
    }

    /*
    {
      "name": "string",
      "startDate": "string",
      "dueDate": "string",
      "isReoccurringTask": true,
      "reoccurringInterval": 0,
      "resourceId": 0,
      "tagId": 0,
      "description": "string",
      "reminders": [
        {
          "taskId": 0,
          "reminderTime": "string",
          "message": "string"
        }
      ]
    }

    */

    //  let result = {};
    //  for (let i=0; i<arr.length; i++) {
    //    result[arr[i].key] = arr[i].value;
    //  }

    this.createNewTask.name = this.taskName.value;
    this.createNewTask.startDate = `${new Date().getTime()}`;
    this.createNewTask.dueDate = `${new Date(
      this.dueDate.value
    ).getTime()}`;
    this.createNewTask.isReoccurringTask = this.isReoccurringTask.value;
    this.createNewTask.reoccurringInterval = Number(
      this.reoccurringInterval.value
    );

    this.createNewTask.tagId = Number(this.taskPriorityId.value);
    this.createNewTask.reminders = reminderDates;

    /**
     * this numbers are independent of the Backend
     */
    this.createNewTask.tagOption = new TagOptionGenericModel();
    if (+this.taskTag.value === 4) {
      this.createNewTask.tagOption.isUserTask = true;
    } else if (+this.taskTag.value === 3) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isApplication = true;
    } else if (+this.taskTag.value === 2) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isEndorsement = true;
    } else if (+this.taskTag.value === 1) {
      this.createNewTask.resourceId = Number(this.resourceId);
      this.createNewTask.tagOption.isMatter = true;
    } else {
      // user must select an item to tag to
      console.log("missing tag");
      return;
    }

    this.taskStore.dispatch(new TaskActions.UpdateTask(this.createNewTask));
  }

  createReminder(defaultValue): FormGroup {
    return this.fb.group({
      value: [defaultValue.value, Validators.required],
      interval: [defaultValue.interval, Validators.required]
    });
  }

  addReminder(
    defaultValue = {value: 1, interval: "Days before due"}
  ): void {
    this.reminders = this.editTaskForm.get("reminders") as FormArray;
    this.reminders.push(this.createReminder(defaultValue));
  }

  removeReminder(index): void {
    this.reminders.removeAt(index);
  }

  removeMember(removedUser) {
    // invert the original selection
    // this.matterTeamMembers.find(data => data.userId === userId).selected =
    // !this.matterTeamMembers.find(data => data.userId === userId).selected;
    this.taskTeamMembers.splice(
      this.taskTeamMembers.indexOf(
        this.taskTeamMembers.find((data) => data.id === removedUser.id)
      ),
      1
    );

    this.updateUser.emit(this.taskTeamMembers);
  }

  trackByFn(index, item) {
    this.currentDocument.indexOf(item);

    return index;
  }

  closeFileItem(index) {
    console.log(index);
  }
}
