import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { TagId } from "../../../core/models/parse-model/new-endorsement";

import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterService } from "../../../core/services/matter.service";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromStore from "../../../core/store/reducers/calendar-lander.reducer";
// import { ProceedingViewModel } from "../../../models/view-model/endorsement-list";
import * as fromMatters from "../../../core/store/reducers/matters.reducer";
import { AppConfigService } from "../helpers/app-config.service";

@Component({
  selector: "app-edit-endorsement-modal",
  templateUrl: "./edit-endorsement-modal.component.html",
  styleUrls: ["./edit-endorsement-modal.component.scss"]
})
export class EditEndorsementModalComponent implements OnInit, OnChanges {
  tagIdArr: TagId[] = this.config.getConfig().priorities;
  editEndorsement: FormGroup;
  currentMatterId: number;
  @Input() disableSelectOnEndorsementSelect: boolean;
  btnSpinner: boolean;
  successText: string;
  errorText: string;
  endorsement: any;
  @Input() endorsementId: number;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  @Output() getSavedEndorsement = new EventEmitter();

  @Output() updateEndorsement = new EventEmitter();
  @ViewChild("messageBox") messageBox: ElementRef;
  @ViewChild("closeEndorsementModal") closeEndorsementModal: ElementRef;
  firmId: number;
  userId: number;
  userData = new UserDetail();
  showResourceNotSelectedError: boolean;

  constructor(
    private calendarStore: Store<fromStore.CalendarState>,
    private fb: FormBuilder,
    private rootStore: Store<fromStore.State>,
    private config: AppConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private service: MatterService,
    private store: Store<fromMatters.State>
  ) {
    this.rootStore.dispatch(new LoginActions.LoadLocalUserDetail());

    this.rootStore.pipe(select("auth")).subscribe((data) => {
      // / save token and user detail to local storage
      this.userData = data.userProfile;
      this.firmId = this.userData.firmId;
    });

    console.log(this.route);
    console.log(this.route.snapshot, "matter id no");
    this.editEndorsement = fb.group({
      hearingDate: ["", Validators.required],
      proceedingId: [""],
      nextHearingDate: this.fb.array([], Validators.required),
      nextAction: ["", Validators.required],
      nextStage: ["", Validators.required],
      summary: ["", Validators.required],
      matterId: [
        {
          value: ""
          // ,disabled: true
        },
        [Validators.min(1)]
      ],
      note: [""],
      tagId: [""]
    });

    this.store
      .pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        console.log(matter);
        if (!isNullOrUndefined(matter)) {
          console.log(matter);
          this.currentMatterId = matter.id;

          console.log(this.currentMatterId);

          if (!isNullOrUndefined(this.currentMatterId)) {
            this.matterId.patchValue(this.currentMatterId);
          }
        }
      });
    console.log(this.tagId, "this is tag id");
  }

  get hearingDate() {
    return this.editEndorsement.get("hearingDate");
  }

  get nextHearingDate(): FormArray {
    return <FormArray>this.editEndorsement.get("nextHearingDate");
  }

  get nextAction() {
    return this.editEndorsement.get("nextAction");
  }

  get nextStage() {
    return this.editEndorsement.get("nextStage");
  }

  get summary() {
    return this.editEndorsement.get("summary");
  }

  get matterId() {
    return this.editEndorsement.get("matterId");
  }

  get tagId() {
    return this.editEndorsement.get("tagId");
  }

  get note() {
    return this.editEndorsement.get("note");
  }

  ngOnChanges(changes) {
    console.log(changes);

    if (this.endorsementId > 0) {
      // call serveice to get existing endorsemnt

      // after getting endorsement patch endorsement form

      // for the formarray push them into the formGroup

      this.service
        .getEndorsementDetails(this.endorsementId)
        .subscribe((res) => {
          this.endorsement = res;
          this.editEndorsement.patchValue({
            hearingDate: new Date(+res.hearingDate * 1000)
              .toISOString()
              .substring(0, 10),
            nextAction: res.nextAction,
            nextStage: res.nextStage,
            summary: res.summary,
            tagId: res.tagId,
            proceedingId: this.endorsementId
          });

          res.nextHearingDate.forEach((date) => {
            const extistingDate = new Date(+date)
              .toISOString()
              .substring(0, 10);

            console.log(date);

            console.log(extistingDate);
            this.addNextHearingDate(extistingDate);
          });
        });
    }
  }

  ngOnInit() {
    this.showResourceNotSelectedError = false;
    this.disableSelectOnEndorsementSelect = true;
  }

  addNextHearingDate(date?: any) {
    if (!isNullOrUndefined(date)) {
      this.nextHearingDate.push(this.createNextHearing(date));
    } else {
      this.nextHearingDate.push(this.createNextHearing());
    }
  }

  createNextHearing(date?: any) {
    if (isNullOrUndefined(date)) {
      return this.fb.group({
        date: [{value: ""}, Validators.required]
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      });
    } else {
      console.log(date);
      return this.fb.group({
        date: [date, Validators.required]
        // Validators.pattern('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g')
      });
    }
  }

  removenextHearingDate(index): void {
    this.nextHearingDate.removeAt(index);
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }

  closeError() {
    this.showErrorMessage = false;
  }

  getSelectedOption(event) {
    if (!isNullOrUndefined(this.currentMatterId)) {
      if (this.currentMatterId > 0) {
        this.matterId.patchValue(this.currentMatterId);
      }
    }

    if (!isNullOrUndefined(event)) {
      if (event > 0) {
        this.currentMatterId = event;
      }
    }
  }

  saveUpdate(newEndorse) {
    console.log(this.currentMatterId);

    if (isNullOrUndefined(this.currentMatterId)) {
      this.showResourceNotSelectedError = true;
      return;
    } else if (this.currentMatterId < 1) {
      this.showResourceNotSelectedError = true;
      return;
    }

    this.btnSpinner = true;

    newEndorse.matterId = Number(this.matterId.value);

    const dateArray = [];
    newEndorse.nextHearingDate.forEach((element) => {
      console.log(element);

      console.log(element.date);
      console.log(new Date(element.date));

      element = "" + new Date(element.date).getTime();

      dateArray.push(element);
    });

    newEndorse.nextHearingDate = dateArray;

    console.log(dateArray);

    console.log(newEndorse.nextHearingDate);

    newEndorse.hearingDate =
      "" + new Date(newEndorse.hearingDate).getTime();

    if (newEndorse.tagId === "") {
      newEndorse.tagId = 3;
    } else {
      newEndorse.tagId = Number(newEndorse.tagId);
    }

    this.service.editEndorsement(newEndorse).subscribe(
      (data) => {
        console.log("successfully added a new endorsement");
        this.successText = "endorsement was created successfully";

        this.btnSpinner = false;

        this.closeEndorsementModal.nativeElement.click();

        newEndorse.author = this.endorsement.author;

        this.updateEndorsement.emit(newEndorse);
      },
      (error) => {
        console.log(error);
        this.showErrorMessage = true;
        this.messageBox.nativeElement.focus();
        this.errorText = "creating endorsement failed";
        this.btnSpinner = false;
      }
    );

    console.log(newEndorse);
  }
}
