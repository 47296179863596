import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ModelFactory } from "../../views/lp-doc/helpers/parser";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { DraftEditModel } from "../models/edit-model/edit-draft";
import { CommonUserModel } from "../models/generic-model.ts/user-share";
import { DraftParseModel } from "../models/parse-model/add-draft";
import { DraftCommentParseModel } from "../models/parse-model/draft-comment";
import { SuggestionParseModel } from "../models/parse-model/draft-suggestion";
import { UserShareParseModel } from "../models/parse-model/user-share";
import { CaseAanlyticsViewModel } from "../models/view-model/case-analytics";
import { PrincipleViewModel } from "../models/view-model/case-ration";
import { DraftCommentViewModel } from "../models/view-model/draft-comment";
import { LPDraftDetailViewModel } from "../models/view-model/draft-detail";
import { DraftSuggestionViewModel } from "../models/view-model/draft-suggestion";
import { DraftThread } from "../models/view-model/draft-threads";

@Injectable({
  providedIn: "root"
})
export class LpDocService {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  updateDraftName(draft: DraftEditModel): Observable<DraftEditModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/DraftTitle";
    return this.httpClient.put<DraftEditModel>(endpoint, draft);
  }

  UpdateDraftContent(draft): Observable<DraftEditModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Draft";
    return this.httpClient.put<DraftEditModel>(endpoint, draft);
  }

  attachDraftToFolder(draft: DraftEditModel): Observable<DraftEditModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Draftfolder";
    return this.httpClient.put<DraftEditModel>(endpoint, draft);
  }

  // attach to matter
  attachDraftToEntity(draft: DraftEditModel): Observable<DraftEditModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Draftmatter";
    return this.httpClient.put<DraftEditModel>(endpoint, draft);
  }

  addDraft(data: DraftParseModel): Observable<number> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Draft";
    let draft: DraftParseModel = new DraftParseModel();
    draft = data;
    // let result = new Observable<number>();

    // let checkResult = new Observable<number>();
    // give draft a name
    draft.title = "Untitled Draft";

    return this.httpClient.post<number>(endpoint, draft);
  }

  public formTypes(): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/formtypes";
    return this.httpClient.get(endpoint);
  }

  public formList(query: string): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "ExternalService/formList";
    const url = `${endpoint}?type=${query}`;
    return this.httpClient.get(url);
  }

  public formContent(pk: string): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/formContent";
    const url = `${endpoint}?pk=${pk}`;
    return this.httpClient.get(url);
  }

  public lawJurisdiction(): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/Jurisdiction";
    return this.httpClient.get(endpoint);
  }

  public ruleDetail(query: string): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/RuleDetail";
    const url = `${endpoint}?jurisdiction=${query}`;
    return this.httpClient.get(url);
  }

  public rule(pk: string): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/CourtForm";
    const url = `${endpoint}?ruleId=${pk}`;
    return this.httpClient.get(url);
  }

  addDraftComment(
    data: DraftCommentParseModel
  ): Observable<DraftCommentViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Comment";

    return this.httpClient.post<DraftCommentViewModel>(endpoint, data);
  }

  updateDraftComment(
    data: DraftCommentParseModel
  ): Observable<DraftCommentViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Comment";

    return this.httpClient.put<DraftCommentViewModel>(endpoint, data);
  }

  DeleteDraftComment(
    data: DraftCommentParseModel
  ): Observable<DraftCommentViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/${data.draftId}/Comment/${data.commentId}`;

    return this.httpClient.delete<DraftCommentViewModel>(endpoint);
  }

  DeleteDraftThread(
    data: DraftCommentParseModel
  ): Observable<DraftCommentViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/${data.draftId}/Thread/${data.threadId}`;

    return this.httpClient.delete<DraftCommentViewModel>(endpoint);
  }

  getDraftComments(draftId: number): Observable<DraftCommentViewModel[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Comment";

    return this.httpClient.get<DraftCommentViewModel[]>(endpoint);
  }

  getDraftThreadComments(
    draftId: number,
    threadId: string
  ): Observable<DraftThread> {
    let _modelFactory = new ModelFactory();

    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/${draftId}/Thread/${threadId}/Comments`;

    // if (this._store === null) {
    //  const res   = await this.httpClient.get<DraftThread[]>(endpoint).toPromise();
    // }
    // return await res;
    // const res =  this.httpClient.get<DraftThread[]>(endpoint);
    // console.log(res);

    // return await this.httpClient.get<DraftThread[]>(endpoint).toPromise();

    //   return  this.httpClient.get<DraftThread[]>(endpoint).pipe(
    //     map(data => {
    //        const newList: DraftThread[] = [];
    //        data.forEach(item =>
    //             newList.push(Object.assign(new DraftThread(), item)));
    //        return newList;
    //     })
    //  );

    return this.httpClient.get<DraftThread[]>(endpoint).pipe(
      map((data) => {
        const model = new DraftThread();
        console.log(model);
        console.log(data);
        Object.assign(model, data);
        model.comments.forEach((c) => {
          c.createdAt = new Date(+c.createdAt);
        });
        console.log(model);
        return model;
      })
    );

    // return of([draftComment]);
  }

  getDraftThreads(draftId: number): Observable<DraftThread[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/${draftId}/Threads`;

    return this.httpClient.get<DraftThread[]>(endpoint);
  }

  addDraftSuggestion(
    data: SuggestionParseModel
  ): Observable<DraftSuggestionViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Suggestion";

    return this.httpClient.post<DraftSuggestionViewModel>(endpoint, data);
  }

  updateDraftSuggestion(
    data: SuggestionParseModel
  ): Observable<DraftSuggestionViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Suggestion";

    return this.httpClient.put<DraftSuggestionViewModel>(endpoint, data);
  }

  DeleteDraftSuggestion(
    id: string,
    draftId: number
  ): Observable<DraftCommentViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/${draftId}Suggestion/${id}`;

    return this.httpClient.delete<DraftCommentViewModel>(endpoint);
  }

  getDraftSuggestion(
    draftId: number,
    suggestionId: string
  ): Observable<DraftSuggestionViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/${draftId}/Suggestion/${suggestionId}`;

    return this.httpClient.get<DraftSuggestionViewModel>(endpoint);
  }

  getDraftDetails(draftId: number): Observable<LPDraftDetailViewModel> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/${draftId}`;

    return this.httpClient.get<LPDraftDetailViewModel>(endpoint);
  }

  getDraftUsers(draftId): Observable<CommonUserModel[]> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/${draftId}/Users`;

    return this.httpClient.get<CommonUserModel[]>(endpoint);
  }

  getDraftTitle(draftId): Observable<string> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/DraftTitle/${draftId}`;

    return this.httpClient.get<string>(endpoint);
  }

  addDraftUser(data: UserShareParseModel): Observable<number> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "Document/Draft/User";

    return this.httpClient.post<number>(endpoint, {
      FileAccessPermissionId: data.fileAccessPermissionId,
      LpdraftId: data.resourceId,
      userId: data.userId
    });
  }

  deleteDraftUser(draftId: number, userId: number): Observable<boolean> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `Document/Draft/${draftId}/User/${userId}`;

    return this.httpClient.delete<boolean>(endpoint);
  }

  updateDraftUser(data: UserShareParseModel): Observable<boolean> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "Document/Draftuser/permission";

    return this.httpClient.put<boolean>(endpoint, {
      FileAccessPermissionId: data.fileAccessPermissionId,
      LpdraftId: data.resourceId,
      userId: data.userId
    });
  }

  searchForCase(searchQuery, pageSize = 0, page = 0, usePageSize = true) {
    let searchParam: string = searchQuery.searchQuery;

    if (searchParam.includes("*")) {
      searchParam = searchParam.split("*")[1];
    }

    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/searchTitle";
    const url = `${endpoint}?searchPhase=${searchParam}&pageSize=${pageSize}&page=${page}&usePageSize=${usePageSize}`;
    console.log(url);

    return this.httpClient.get<any>(url);
  }

  loadCasePriciple(suitno, court) {
    // let searchParam: string = searchQuery.searchQuery;

    // if(searchParam.includes('*')) {
    //  searchParam = searchParam.split('*')[1];

    // }

    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/casePrinciple";

    const url = `${endpoint}?suitNo=${encodeURIComponent(
      suitno
    )}&court=${court}`;
    console.log(url);

    return this.httpClient.get<PrincipleViewModel>(url);
  }

  loadPricipleAnalytics(ratio, court) {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      "ExternalService/caseanalytics";
    const url = `${endpoint}?ratio=${encodeURIComponent(
      ratio
    )}&court=${court}`;
    console.log(url);

    return this.httpClient.get<CaseAanlyticsViewModel>(url);
  }

  loadCaseSummary(suitno, court) {
    const endpoint =
      this.config.getConfig().serverEndpoint + "ExternalService/summary";
    const url = `${endpoint}?suitNo=${encodeURIComponent(
      suitno
    )}&court=${court}`;
    console.log(url);

    return this.httpClient.get<any>(url);
  }
}
