import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { NotificationViewModel } from "../../../core/models/shared-model/notification";
import { NotificationService } from "../../../core/services/notification.service";
import * as headertActions from "../../../core/store/actions/user-header.actions";
import * as fromUserHeaderStore from "../../../core/store/reducers/user-header.reducer";

@Component({
  selector: "app-notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.scss"]
})
export class NotificationsListComponent implements OnInit {
  notificationList: NotificationViewModel[] = [];
  @Input("limit") limit: number | boolean = false;

  @Output() notoficationCount = new EventEmitter();

  constructor(
    private _notyService: NotificationService,
    protected headerStore: Store<fromUserHeaderStore.HeaderState>
  ) {
  }

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this._notyService.getNotifications().subscribe(
      (data) => {
        console.log(data);
        this.headerStore.dispatch(
          new headertActions.SetNotificationCount(data.body.length)
        );
        if (!this.limit) {
          this.notificationList = data.body;
        } else {
          this.notificationList = data.body.slice(
            0,
            <number>this.limit
          );
        }
      },
      (error) => {
      },
      () => {
      }
    );
  }
}
