export class Contact {
  contactName: string;
  companyName: string;
  title: string;
  relation: string;
  contactSource: string;
  phoneNumber: number;
  email: string;
  website: string;
  countryId: number;
  stateId: number;

  public id?: number;

  public firstName: string;
  public lastName: string;

  public jobDescription: string;

  public instantMessenger: string;

  public contactStatusId: number;

  public firmLabelId: number;

  public firmId: number;

  public contactSourceId: number;

  public titleId: number;
}
