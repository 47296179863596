import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

import * as fromStore from "../../../core/store";

import * as LoginActions from "../../../core/store/actions/login.actions";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent implements OnInit {
  authState: any;
  email: string;

  userData = new UserDetail();

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private store: Store<fromStore.State>,
    private activatedRoute: ActivatedRoute
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select("auth")).subscribe((data) => {
      console.log(data);

      if (!isNullOrUndefined(data)) {
        if (!isNullOrUndefined(data.userProfile.email)) {
          this.userData = data.userProfile;

          this.email = this.userData.email;
        }
      }
    });

    this.store.pipe(select("auth")).subscribe((data) => {
      if (!isNullOrUndefined(data)) {
        if (!isNullOrUndefined(data.isLoggedOut)) {
          console.log(data.isLoggedOut);
          if (data.isLoggedOut) {
            console.log("about to login");
          }
        }
      }
    });

    this.store.pipe(select("auth")).subscribe((data) => {
      if (!isNullOrUndefined(data.errorMsg) && data.isLoggingOut) {
        // if(!isNullOrUndefined(data.isLoggedOut)) {
        // if(data.errorMsg !==  '') {
        // this.router.navigate(['/login']);
        console.log(data.errorMsg);
        //  alert('An error occurred while logging you out')
        // }

        // }
      }
    });

    //  isLoggingOut errorMsg
  }

  ngOnInit() {
    if (this.email !== "") {
      console.log(this.email);
      this.store.dispatch(new LoginActions.Logout(this.email));
    } else {
      console.log(this.email);
      this.router.navigate(["/login"]);
    }
  }
}
