import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRoot from "./app.state";
import { ConnectionState } from "./reducers/internet-connection.reducer";
import { AuthState } from "./reducers/login.reducer";

// this can hold all reducer selectors even from lazy loaded modules

// if its not lazy loaded
// dont add reducer state here if its lazy loaded
export interface State extends fromRoot.State {
  auth: AuthState;
  user: any;
  returnUrl: string;
  connectionState: ConnectionState;
  // calendar: CalendarState;
  // header: HeaderState;
}

// const getUserHeaderFeatureState = createFeatureSelector<HeaderState>('user-header');

// export const getTimerState = createSelector(
//   getUserHeaderFeatureState,
//   state => state.isShowTimer
// );

// const getInternetConnectionState = createFeatureSelector<ConnectionState>('conntecton-state');

// export const getConnectionState = createSelector(
//   getInternetConnectionState,
// );

const getUserAuthDetailFeatureState = createFeatureSelector<AuthState>("auth");

export const getUserDetail = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.userProfile
);

export const getReturnUrl = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.returnUrl
);

export const getUserRole = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.role
);

export const getCurrentProfileImage = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.currentProfilePicture
);

export const getFirmDetail = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.firmDetail
);

export const getFirmLogo = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.currentFirmLogo
);

export const getFirmIsUpdated = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.isUpdated
);

export const getFirmUpdateError = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.error
);

export const getProfileImageUploadError = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.errorInUpload
);

export const getUserEmail = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.email
);

export const getUserToken = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.authToken
);

export const getLoggedIn = createSelector(
  getUserAuthDetailFeatureState,
  (state) => state.loggedIn
);
