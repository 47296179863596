import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { AddUserToGroup } from "../models/view-model/firm-user-group";
import { GroupDetail } from "../models/view-model/group-detail";
import { UserGroupModel } from "../models/view-model/user-group";

@Injectable({
  providedIn: "root"
})
export class UserGroupService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  /**
   *
   * @param userGroup this model Contains Id, Name and Description
   */
  public createGroup(userGroup: UserGroupModel): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.newUserGroup;
    console.log(url);
    return this.httpClient.post<any>(url, userGroup);
  }

  /** Reading the groups in list */
  public readGroupList(): Observable<UserGroupModel[]> {
    const api =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.newUserGroup;
    console.log(api);
    return this.httpClient.get<UserGroupModel[]>(api);
  }

  public readGroupDetails(): Observable<UserGroupModel> {
    const api =
      this.config.getConfig().serverEndpoint +
      this.Endpoints.newUserGroup;
    console.log(api);
    return this.httpClient.get<UserGroupModel>(api);
  }

  /**
   * Used in Matter Team
   */
  public getGroups(): Observable<GroupDetail[]> {
    const api = this.config.getConfig().serverEndpoint + "firm/firmgroup";
    console.log(api);
    return this.httpClient.get<GroupDetail[]>(api);
  }

  /**
   *
   * @param groupId the grou[ Id]
   */
  public getGroupDetails(groupId: number): Observable<GroupDetail> {
    const api =
      this.config.getConfig().serverEndpoint +
      `firm/firmgroup/${groupId}`;
    console.log(api);
    return this.httpClient.get<GroupDetail>(api);
  }

  /**
   *
   * @param groupId the group Id
   */
  public getGroupUsers(
    firmgroupId: number,
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint +
      `firm/${firmgroupId}/firmgroupusers`;
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  /**
   *
   * @param firmUserId the user Id
   * @param firmGroupId the group user Id
   */
  public addFirmUserToGroup(
    addUserToGroup: AddUserToGroup[]
  ): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint + "firm/firmusergroup";
    console.log(api);
    return this.httpClient.post<any>(api, addUserToGroup);
  }

  /**
   *
   * @param firmgroupid the group Id
   * @param firmuserid the group user Id
   */
  public removeUserFromGroup(
    firmgroupid: number,
    firmuserid: number
  ): Observable<any> {
    const api =
      this.config.getConfig().serverEndpoint +
      `firm/firmusergroup/${firmgroupid}/${firmuserid}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }

  /**
   *
   * @param Id groupId
   * @param searchValue group username
   */
  public searchGroupUsers(Id: number, searchValue: string): Observable<any> {
    const url =
      this.config.getConfig().serverEndpoint +
      `firm/group/${Id}/user_search/${searchValue}`;
    return this.httpClient.get<any>(url, {observe: "response"});
  }

  public getfirmGroupUsers(
    searchQuery = "",
    sort = "",
    order = "asc",
    page = 0,
    pageSize = 10,
    usePageSize = true
  ): Observable<any> {
    const endpoint =
      this.config.getConfig().serverEndpoint + "firm/firmgroupusers";
    const api = `${endpoint}?searchQuery=${searchQuery}&sort=${sort}&order=${order}&page=${
      page + 1
    }&pageSize=${pageSize}&usePageSize=${usePageSize}`;
    console.log(api);
    return this.httpClient.get<any>(api, {observe: "response"});
  }

  public editGroup(userGroup: UserGroupModel): Observable<UserGroupModel> {
    const api = this.config.getConfig().serverEndpoint + "firm/firmgroup";
    console.log(api);
    return this.httpClient.put<UserGroupModel>(api, userGroup);
  }

  public deleteGroup(id: number): Observable<any> {
    const endpoint = this.config.getConfig().serverEndpoint;
    const api = endpoint + `firm/firmgroup?id=${id}`;
    console.log(api);
    return this.httpClient.delete<any>(api);
  }
}
