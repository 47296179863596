import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { isNullOrUndefined } from "util";
import { MatterList } from "../../../core/models/view-model/matter-list";
import { UserDetail } from "../../../core/models/view-model/user-detail";
import { MatterService } from "../../../core/services/matter.service";

import * as fromStore from "../../../core/store";
import * as LoginActions from "../../../core/store/actions/login.actions";
import * as fromMatters from "../../../core/store/reducers/matters.reducer";

@Component({
  selector: "app-matter-search-box",
  templateUrl: "./matter-search-box.component.html",
  styleUrls: ["./matter-search-box.component.scss"]
})
export class MatterSearchBoxComponent implements OnInit {
  user = new UserDetail();
  matterList: MatterList[] = [];
  titleString: string;

  @Input() selectedMatterId: number;

  currentMatterId: number;

  @Input() searchBoxTagOption: number;

  @Input() disableChangeOfItem: boolean;

  @Input() selectedMatterName: string;

  @Output() postSelectedMatter = new EventEmitter();

  constructor(
    private matterService: MatterService,
    private store: Store<fromStore.State>,
    private matterStore: Store<fromMatters.State>
  ) {
    this.store.dispatch(new LoginActions.LoadLocalUserDetail());

    this.store.pipe(select(fromStore.getUserDetail)).subscribe((data) => {
      console.log(data);
      this.user = data;
    });

    this.matterStore
      .pipe(select(fromMatters.getCurrentMatter))
      .subscribe((matter) => {
        console.log(matter);
        if (!isNullOrUndefined(matter)) {
          console.log(matter);
          this.selectedMatterId = matter.id;
        }
      });
  }

  ngOnInit() {

    if (this.searchBoxTagOption === 3) {
      this.titleString = "application";

      this.matterService.getMatterDetailsForNewTask(this.searchBoxTagOption).subscribe((data) => {
          this.matterList = data;
        });
    } else if (this.searchBoxTagOption === 2) {
      this.titleString = "endorsement";

      this.matterService.getMatterDetailsForNewTask(this.searchBoxTagOption).subscribe((data) => {
          this.matterList = data;
        });
    } else if (this.searchBoxTagOption === 1) {
      this.titleString = "matter";

      console.log("here");

      this.matterService.getMatterDetailsForNewTask(this.searchBoxTagOption).subscribe(
        data => {
          this.matterList = data;

          if (!isNullOrUndefined(this.selectedMatterId)) {
            if (this.selectedMatterId > 0) {
              const matter = this.matterList.find(
                (c) => c.id === this.selectedMatterId
              );
              if (!isNullOrUndefined(matter)) {
                this.selectedMatterName = this.matterList.find(
                  (c) => c.id === this.selectedMatterId
                ).name;
              }

              this.postSelectedMatter.emit(
                this.selectedMatterId.toString()
              );
            }
          }

        },
        err => console.log('err>>>>>', err)
      )

    }

    if (this.selectedMatterId === 0) {
      this.selectedMatterId = 0;
      this.selectedMatterName =
        "search an item from this dropdown to find a " +
        this.titleString;
    }

    if (isNullOrUndefined(this.selectedMatterId)) {
      this.selectedMatterId = 0;
      this.selectedMatterName = "select " + this.titleString;
    }

    this.postSelectedMatter.emit(this.selectedMatterId.toString());
  }

  selectMatter(name: string, id: number) {
    console.log(name);
    this.selectedMatterName = name;
    this.selectedMatterId = id;

    this.postSelectedMatter.emit(this.selectedMatterId.toString());
  }

  searchMatter(value) {
    console.log(value)
    this.matterService.getMatterDetailsForNewTask(this.searchBoxTagOption, value).subscribe((data) => {
        this.matterList = data;
      });
  }
}
