import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"]
})
export class PaginatorComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
