export class FileUploadModel {
  fileName: string;
  fileType?: string;
  resourceId?: number;
  documentType?: string;
	description?: string;

  // / <summary>
  // / This is a Base64String value.
  // / </summary>
  fileBinary: string | ArrayBuffer;

  // / <summary>
  // / The file extension e.g .jpg, .jpeg, .mp4
  // / </summary>
  fileExtension?: string;
  fileSize?: number;
}

export class FileSaveUploadModel {
  resourceDirectoryId: number;
  resourceId: number;
  fileModel: FileUploadModel[];
}

export class FilterGenericModel {
  ongoing: boolean;
  archived: boolean;
  completed: boolean;
  categoryType: number [];
  categorySubType: number[];
  clientId: number;
  clearAllFilter: boolean;
  allStatusFilter: boolean;
  allCategoryTypeFilter: boolean;
}
