export class FilterGenericModel {
  ongoing: boolean;
  archived: boolean;
  completed: boolean;
  categoryType: number[];
  categorySubType: number[];
  clientId: number;
  clearAllFilter: boolean;
  allStatusFilter: boolean;
  allCategoryTypeFilter: boolean;
}
