import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { FaqsComponent } from "./faqs/faqs.component";
import { HomeRoutingModule } from "./home-routing.module";
import { HomepageComponent } from "./homepage/homepage.component";
import { LayoutComponent } from "./layout/layout.component";
import { MoneyTransferOptionComponent } from "./money-transfer-option/money-transfer-option.component";
import { PaymentSuccesfulComponent } from "./payment-succesful/payment-succesful.component";
import { PolicyComponent } from "./policy/policy.component";
import { PricingComponent } from "./pricing/pricing.component";
import { TermsComponent } from "./terms/terms.component";
import { UserTermsComponent } from "./user-terms/user-terms.component";

@NgModule({
  declarations: [
    HomepageComponent,
    FaqsComponent,
    LayoutComponent,
    PricingComponent,
    MoneyTransferOptionComponent,
    PaymentSuccesfulComponent,
    TermsComponent,
    UserTermsComponent,
    PolicyComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule, HomeRoutingModule],
  exports: [
    HomepageComponent,
    FaqsComponent,
    LayoutComponent,
    PricingComponent
  ]
})
export class HomeModule {
}
