import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-form-parent",
  templateUrl: "./form-parent.component.html",
  styleUrls: ["./form-parent.component.scss"]
})
export class FormParentComponent implements OnInit {
  @Input() successText: string;
  @Input() errorText: string;
  public showSuccessMessage: boolean;
  public showErrorMessage: boolean;
  public btnSpinner: boolean;
  @ViewChild("messageBox") messageBox: ElementRef;

  constructor() {
  }

  ngOnInit() {
    this.showErrorMessage = false;
    this.showSuccessMessage = false;
    this.btnSpinner = false;
  }

  closeSuccess() {
    this.showSuccessMessage = false;
  }

  closeError() {
    this.showErrorMessage = false;
  }
}
