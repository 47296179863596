import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from "@angular/router";
import { CurrentUserService } from "../../../core/services/factories/current-user.service";

@Injectable({providedIn: "root"})
export class MainGuard implements CanActivate, CanLoad {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;

    return this.checkLogin(url);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    console.log(this.currentUserService.isLoggedIn());
    if (this.currentUserService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(["/login"]);
  }
}
