import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterInvitedUser } from "../../../core/models/parse-model/register-invited-user";
import { InviteToken } from "../../../core/models/view-model/invite-token";
import { AuthService } from "../../../core/services/auth.service";
import { PasswordValidation } from "../../shared/helpers/password-matcher";

@Component({
  selector: "app-new-invited-user",
  templateUrl: "./new-invited-user.component.html",
  styleUrls: ["./new-invited-user.component.scss"]
})
export class NewInvitedUserComponent implements OnInit {
  // to be used if an error occurs
  error: boolean;
  firmReg;
  token;
  dataObject = new InviteToken();
  onLoadHide: boolean;
  isError: boolean;
  showError: boolean;
  btnSpinner: boolean;
  emailCheckSpinner: boolean;
  invalidEmail: boolean;
  @ViewChild("messageBox") messageBox: ElementRef;

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.token = this.route.snapshot.queryParams["token"];
    console.log(this.token);
    // / this.dataObject = mockTokenObj;
    const newUriDecodedTk = decodeURIComponent(this.token);
    const _dataObject = JSON.parse(newUriDecodedTk);
    console.log(_dataObject);

    this.dataObject.inviteeEmail = _dataObject.InviteeEmail;
    this.dataObject.firmName = _dataObject.FirmName;
    this.dataObject.token = _dataObject.Token;
    this.dataObject.isUserAlreadyExistOnPlatform =
      _dataObject.IsUserAlreadyExistOnPlatform;
    this.dataObject.firmRoleId = _dataObject.FirmRoleId;
    this.dataObject.hourlyRate = _dataObject.HourlyRate;

    this.firmReg = fb.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: [this.dataObject.inviteeEmail, Validators.required],
        userName: [this.dataObject.inviteeEmail],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );
  }

  // gain access to each form field for validation
  get firstName() {
    return this.firmReg.get("firstName");
  }

  get lastName() {
    return this.firmReg.get("lastName");
  }

  get email() {
    return this.firmReg.get("email");
  }

  get firmName() {
    return this.firmReg.get("firmName");
  }

  get firmPhoneNo() {
    return this.firmReg.get("firmPhoneNo");
  }

  get password() {
    return this.firmReg.get("password");
  }

  get confirmPassword() {
    return this.firmReg.get("confirmPassword");
  }

  ngOnInit() {
    this.error = false;
    this.isError = false;
    this.invalidEmail = false;
    this.onLoadHide = true;
    this.btnSpinner = false;
    this.showError = false;
    this.emailCheckSpinner = false;
    this.validateEmail();
  }

  validateEmail() {
    console.log("validating email");
    this.emailCheckSpinner = true;
    this.authService
      .validateEmail(this.email.value)
      .toPromise()
      .then(
        (data) => {
          console.log(data);
          if (data) {
            this.invalidEmail = false;
            this.emailCheckSpinner = false;
            console.log(data);
          } else {
            this.invalidEmail = true;
            this.messageBox.nativeElement.focus();
            // this.btnSpinner = false;
          }
        } // success path
      )
      .catch((error) => {
        console.log("threw error");
        this.error = error; // error path
        this.emailCheckSpinner = false;
        this.invalidEmail = true;
        this.messageBox.nativeElement.focus();
      });
  }

  regFirm(data) {
    this.btnSpinner = true;
    let invitedUser: RegisterInvitedUser;
    invitedUser = data.value as RegisterInvitedUser;
    invitedUser.token = this.dataObject.token;
    invitedUser.firmName = this.dataObject.firmName;
    invitedUser.isUserExisitng = this.dataObject.isUserAlreadyExistOnPlatform;
    invitedUser.hourlyRate = this.dataObject.hourlyRate;
    invitedUser.firmRoleId = this.dataObject.firmRoleId;
    console.log(invitedUser);
    this.authService
      .inviteeRegister(data.value)
      .toPromise()
      .then((res) => {
        this.btnSpinner = false;
        this.router.navigate(["/login"]);
      })
      .catch((err) => {
        this.isError = true;
        this.showError = true;
        this.btnSpinner = false;
        this.messageBox.nativeElement.focus();
      });
  }
}
